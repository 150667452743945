import React, { useEffect, useState } from "react";
import edit from "../../../assets/Images/image/edit.png";
import waste from "../../../assets/Images/image/Waste.png";
import { TableWrapper } from "../style/table";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "../../../assets/SharedComponents/Pagination";
import SideTranslate from "../components/SideTranslate";
import MainLayout from "../../../assets/Layout/MainLayout";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { getName } from "../../../core/utils/getName/getName"
import { FaFilterIcon } from "../../../assets/Images/icon";
import { Tooltip } from "@mui/material";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import SearchableObjDropdown from "../../../assets/SharedComponents/SearchableDropdown/SearchableObjDropdown";
import StaticFilter from "../../../assets/SharedComponents/Filters/StaticFilter";
import ProductTypeFilter from "../../../assets/SharedComponents/Filters/ProductTypeFilter";
import BrandFilter from "../../../assets/SharedComponents/Filters/BrandFilter";
import ModelFilter from "../../../assets/SharedComponents/Filters/ModelFilter";



const VersionCars = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();
  
  const [versionCars, setVersionCars] = useState([]);
  const [total, _total] = useState(0);
  const [dataDeleted, _dataDeleted] = useState(false);

  const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
  const [totalPage, _totalPage] = useState(0);
  
  const [trashed, _trashed] = useState(searchParams.get('trashed')!==null?(searchParams.get('trashed')==='false'?false:true):true); 
  const [productValue, _productValue] = useState(query.get('product_type_id')!==null?query.get('product_type_id'):"");  
  const [brandName, _brandName] = useState(query.get('brand_id')!==null?query.get('brand_id'):"");
  const [modelName, _modelName] = useState(query.get('model_id')!==null?query.get('model_id'):"");
  const [yearValues, _yearValues] = useState([]);
  const [year, _year] = useState(query.get('year')!==null?query.get('year'):"");
  
  const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");
  const [searchkeyword, _searchkeyword] = useState(query.get('version_name')!==null?query.get('version_name'):"");


  const [filters, _filters] = useState(false);
  const [loading, _loading] = useState(false);

  const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let vehicleManagementAccess = userAccess.find(o => o.slug === "vehicle_management")

  const getVersionCars = () => {
    _loading(true);
    API.get(APP_URLS.LIST_VERSION_CARS + `?page=${page}&per_page=${countValue}&filter[version_name]=${searchkeyword}&filter[product_type_id]=${productValue}&filter[brand_id]=${brandName.split('_')[0]}&filter[year]=${year}&filter[model_id]=${modelName.split('_')[0]}&with_trashed=${trashed===true?1:0}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setVersionCars(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total)
          _loading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  
  useEffect(() => {
    getVersionCars();
    _dataDeleted(false);
  }, [dataDeleted, page, searchkeyword, productValue, countValue, brandName, modelName, year,trashed]);
 
  useEffect(() => {
    const d = new Date();
    let year = d.getFullYear()
    let arr = []
    for (let i = year; i >= 1900; i--) {
      arr.push(i)
    }
    _yearValues(arr)
  }, [])

  const removeVersionCars = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_VERSION_CARS + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("Version Car has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword)){
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  const setChangeValue = (func, item) => {
    func(item);
    if((func===_brandName&&item!==brandName)||(func===_modelName&&item!==modelName)){
      if (item !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  }

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _productValue("");
    _countValue('');
    _brandName("")
    _modelName("")
    _year('')
    _trashed(true)
    if (productValue !== "" || searchkeyword !== ""||trashed!==true) {
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  return (
    <TableWrapper>
      <div className="row">
        <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
          <h4 className="translation text-center mb-3">VEHICLES <br /> MANAGEMENT</h4>
          <SideTranslate />
        </div>
        <div className="col-lg-10 mt-2">
          <div className="headerContainer mb-3">
            <h1>VERSIONS</h1>
          </div>
          <div className="d-flex justify-content-between flex-wrap">
            <div className="add-depertment d-flex justify-content-end mb-2">
              {vehicleManagementAccess.permissions.find((o) => o.slug === "add") ? (
                <div>
                  <NavLink to="/vehicles-management/create-versions">
                    <button className="btn">+ Add</button>
                  </NavLink>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex justify-content-end mb-2 flex-wrap align-items-center gap-2">
              <ProductTypeFilter handleChange={(e) => {
                    _productValue(e.target.value)
                    _totalPage(0)
                    setPage(1);  
                    _brandName('')
                    _modelName('')
                }} value={productValue}/> 
              <StaticFilter placeholder='Year' data={yearValues.map(item => ({ name:item , value : item}))}  handleChange={(e) =>  _year(e.target.value)} value={year}/>  
              <BrandFilter filters={filters} _filters={_filters}  brand={brandName} setBrand={(val)=>setChangeValue(_brandName, val)} productValue={productValue} />  
              <ModelFilter _filters={_filters} filters={filters} brandID={brandName} modelID={modelName} setChangeValue={(val)=>setChangeValue(_modelName,val)}/>   
              <SearchKey onSearchClick={(search) => handleSearchChange(_searchkeyword, search)}
                val={searchkeyword}
                filters={filters}
                _filters={_filters}
              />
              <RecordCount
                onSearchClick={(search) => handleSearchChange(_countValue, search)}
                id="pageCount"
                val={countValue}
                filters={filters}
                _filters={_filters} />
              <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
              </Tooltip>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
            <div className="mt-1">
              <input type="checkbox" checked={!trashed} onChange={()=>{
                _totalPage(0)
                setPage(1);
                _trashed(!trashed)
                }}/>
              <span className="ms-2">Hide Deleted Data</span>
            </div>
          </div>
          <div className="tableContainer">
            <table className="auctionTable versionsTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th className="tableHead-7">Brand Name</th>
                  <th className="tableHead-7">Model Name</th>
                  <th className="tableHead-6">Version Name</th>
                  <th className="tableHead-2">Year</th>
                  <th className="tableHead-4">Updated by</th>
                  <th className="tableHead-5">Created by</th>
                  {vehicleManagementAccess.permissions.find((o) => o.slug === "update") || vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? <th className="tableHead-9">Action</th> : ""}
                </tr>
              </thead>
              {
                loading ?
                  <div className='d-flex justify-content-center three-dot'>
                    <ThreeDots
                      height="150"
                      width="150"
                      radius="9"
                      color="#637df4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                  :
                  <tbody>
                    {versionCars.length > 0 ? (
                      versionCars.map((item, index) => {
                        let createdBy = getName(item.create_activity_log, item.update_activity_log);
                        let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                        return (
                          <tr className={(item.deleted_at ? "deletedRow" : "") + " table-row"} key={index}>
                            <td className="tableHead-7" title={"ID: "+item.model.brand_id}>{item.model.brand_car.name}</td>
                            <td className="tableHead-7" title={"ID: "+item.model_id}>{item.model.model_name}</td>
                            <td className="tableHead-6" title={"ID: "+item.id}>{item.version_name}</td>
                            <td className="tableHead-2">{item.year}</td>
                            <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                            <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                            {vehicleManagementAccess.permissions.find((o) => o.slug === "update") || vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? (
                              <td className="tableHead-9">
                                {(item.deleted_at === '' || item.deleted_at === null) &&
                                  <>
                                    {vehicleManagementAccess.permissions.find((o) => o.slug === "update") ? (
                                      <NavLink to={`/vehicles-management/edit-versions/${item.id}?`+(productValue!==""?"product_type_id="+productValue+"&":"")+(brandName!==""?"brand_id="+brandName+"&":"")+(modelName!==""?"model_id="+modelName+"&":"")+(searchkeyword!==""?"version_name="+searchkeyword+"&":"")+(year!==""?"year="+year+"&":"")+(trashed!==true? "trashed=" + trashed + "&": "")+(countValue!==""?"per_page="+countValue+"&":"")+(page!==1?"page="+page:"")}>
                                        <img src={edit} alt="" className="mx-2" role="button" />
                                      </NavLink>
                                    ) : (
                                      ""
                                    )}
                                    {vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? <img src={waste} alt="" role="button" onClick={() => removeVersionCars(item.id)} /> : ""}
                                  </>
                                }
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecord />
                    )}
                  </tbody>
              }
            </table>
          </div>
          {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
        </div>
      </div>
    </TableWrapper>
  );
};

export default VersionCars;
