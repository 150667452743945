import React, { useEffect, useState } from 'react'
import { APP_URLS } from '../../../api/url';
import API from '../../../api/axios';
import SearchableObjDropdownWithImage from '../SearchableDropdown/SearchableObjDropdownWithImage';
import { toast } from 'react-toastify';
import { DEBOUNCE_TIME } from '../../../config/constant';
 
function UserFilter({user , setUser, filters, _filters, searchByPseudoName=false, searchkeyword, _searchkeyword}) {
    const [userName,_userName] = useState('')
    const [users, _users] = useState([])
    useEffect(() => {
        const timer = setTimeout(() => { 
            if(userName !== undefined && userName !== null)
             getUsers();
        }, DEBOUNCE_TIME);
        return () => clearTimeout(timer); 
    }, [userName])
    const getUsers = () => {
            API.get(`${APP_URLS.LIST_USERS}${searchByPseudoName ? '/list/pseudo?pseudo': '/list/all?keyword'}=${userName}`)
            .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                    _users(resp.data.items)
                } else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error;
                } else {
                    error_message = resp.data.message;
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            });
    };

    return <SearchableObjDropdownWithImage  _filters={_filters} filters={filters} name="users" className="filterBox" setChangeValue={(val)=>setUser(val)} val={user} placeholder={searchByPseudoName ? "Search by Username" : "Select User"} dropdownData={users || []} func={_userName} searchByPseudoName={searchByPseudoName} searchkeyword={searchkeyword} _searchkeyword={_searchkeyword} />

}

export default UserFilter
