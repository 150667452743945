import React, { useEffect, useState } from "react";
import MainLayout from "../../../assets/Layout/MainLayout";
import { CompanyDetailsWrapper } from "./style/companyDetails";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import waste from "../../../assets/Images/image/Waste.png";
import alert from "../../../assets/Images/icons/alert-icon.svg";
import moment from "moment";
import * as Constant from "../../../config/constant";
import { confirmAlert } from "react-confirm-alert";
import faArrow from "../../../assets/Images/icons/faChevronDown2.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "../../../assets/Images/icons/faLink.svg";
import Popup from "./Popup";
import { FaLocation } from "../../../assets/Images/icon";
import { formatCurrency, generateQueryString } from "../../../core/utils";

const CompanyDetails = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [data, _data] = useState({});
  const [subscriptions, _subscriptions] = useState([]);
  const [censorData, _censorData] = useState([]);
  const [loading, _loading] = useState(false);

  const [show, _show] = useState(true);

  const [popup, _popup] = useState("");

  const [comments, _comments] = useState({});
  const [permission, _permission] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );

  let companyAccess = permission.find((o) => o.slug === "manage_subsidiary");

  const { companyId } = useParams();
  const navigate = useNavigate();

  const date = new Date().getTime();

  useEffect(() => {
    getCompany();
    getCompanyCensorship();
    getBehaviortext();
  }, []);

  const getCompany = () => {
    API.get(APP_URLS.LIST_SUBSIDIARY + `/${companyId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.subsidiary);
          _subscriptions(resp.data.subsidiary.subscriptions);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getCompanyCensorship = () => {
    _loading(true);
    API.get(APP_URLS.VIEW_SUBSIDIARY_CENSORSHIP + `/${companyId}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _loading(false);
          _censorData(resp.data.items);
          // _data(resp.data.subsidiary);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getBehaviortext = () => {
    API.get(APP_URLS.LIST_SUGGESTION_TEXT + `?limited_data=true&filter[type]=3`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          let data = {};
          resp.data.items.forEach((element) => {
            data[element.id] = element.name;
          });
          _comments(data);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            _loading(true);
            API.delete(APP_URLS.DELETE_SUBSIDIARY_CENSORSHIP + `/${index}`)
              .then((res) => {
                const resp = res.data;
                _loading(false);
                if (resp.success === true) {
                  toast.success("Censorship has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  getCompanyCensorship();
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                _loading(false);
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map(
                      (error, index) =>
                        (error_message = resp.data.data[error][0])
                    );
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const diffdates = (a, b) => {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  };

  //full address information
  const address = (addressObj) => {
    let addressString = '';
    addressString += addressObj?.route ? addressObj.route + ", " : '';
    addressString += addressObj?.street_number ? addressObj.street_number + ", " : '';
    addressString += addressObj?.sub_locality ? addressObj.sub_locality + ", " : '';
    addressString += addressObj?.locality ? addressObj.locality + ", " : '';
    addressString += addressObj?.country?.fullname?.en ? addressObj.country.fullname.en + ", " : '';
    addressString += addressObj?.postal_code ? addressObj.postal_code + ", " : ''; 
    addressString = addressString.substring(0, addressString.length - 2 ) + "."
  return  <span className="leftSpace text-sm d-flex align-center gap-1" title="Address">{addressString} </span> 
}

// params for current page
const createParamsObj = () => {
  return {
    status: query.get("status"),
    sort: query.get("sort"),
    country_id: query.get("country_id"),
    start_plan: query.get("start_plan"),
    end_plan: query.get("end_plan"),
    name: query.get("name"),
    vat: query.get("vat"),
    country_code: query.get("country_code"),
    trashed: query.get("trashed"),
    per_page: query.get("per_page"),
    page: query.get("page"),
  };
};

//creting url for companies route
const getCompaniesUrl = () => {
  const paramsObj = createParamsObj();
  const queryString = generateQueryString(paramsObj);
  return `/companies?${queryString}`;
};

//naviagting to returning url
const navigateToCompanies = () => {
  const url = getCompaniesUrl();
  navigate(url);
};

//creating url for create center page with company ID
const getCompanyCreatedCensorUrl = (itemId) => {
  const paramsObj = createParamsObj();
  const queryString = generateQueryString(paramsObj);
  return `/company/create-censored/${itemId}?${queryString}`;
};

  return (
    <>
      <CompanyDetailsWrapper>
        <Popup popup={popup} _popup={_popup} />
        <div className="auctionMainContainer">
          <div className="mt-2">
            <div
              className="backContainer ms-4"
              onClick={navigateToCompanies}
            >
              <span className="backButton">x</span>
              <span className="px-3 backButton">|</span>
              <span className="backButton">Cancel</span>
            </div>
          </div>
          <div className="headerContainer justify-content-center mb-3">
            <div className="addressContainer">
              <div>
                <div className="address-item">
                  <span>
                    <b>Email:</b> {data?.email_principal || "-"}
                  </span>
                </div>
                <div className="address-item">
                  <span>
                    <b>Country: </b>  
                    {data?.address_principal?.country?.fullname?.en || "-"}
                  </span>
                </div>
              </div>
              <div>
                <div className="address-item">
                  <span>
                    <b>Phone:</b> {data?.phone_principal || "-"}
                  </span>
                </div>
                <div className="address-item">
                  <span>
                    <b>VAT:</b>{" "}
                    {(data?.address_principal?.country?.country_name ? data.address_principal.country.country_name+ "  " : "")+
                      (data?.vat_number || "-")}
                  </span>
                </div>
              </div>
              <div className="fullAddress">
                <span className="d-flex">
                  <b>Full Address: </b>
                  {address(data?.address_principal)}
                </span>
              </div>
            </div>
            <h1>
              {Object.keys(data).length === 0
                ? Constant.userTypeName.DEALER_CAPITAL
                : data.name.charAt(0).toUpperCase() + data.name.slice(1)}
            </h1>
          </div>
          <div className="tableContainer mb-4">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  {/* <th id="company-name">Name</th> */}
                  <th>Status</th>
                  <th>Plan Name </th>
                  <th id="time-start">
                    Start Time
                    <br /> End Time
                  </th>
                  <th>Country</th>
                  <th>Number of Ads Active</th>
                  <th>Number of Ads From Start</th>
                  <th>Buy</th>
                  <th>Sold</th>
                  <th>Number of Ads To-Do in Auction</th>
                  <th>Number of Members </th>
                  <th>Rating</th>
                  <th>Sanctions</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(data).length === 0 ? (
                  <div className="d-flex justify-content-center three-dot">
                    <ThreeDots
                      height="150"
                      width="150"
                      radius="9"
                      color="#637df4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <tr className="table-row">
                    <td className="w100">
                      <span
                        className={
                          data.active === 1 ? "active-status" : "end-status"
                        }
                      >
                        {data.active === 1 ? "Active" : "Inactive"}
                      </span>
                    </td>
                    <td className="w120">
                      <span className="d-block">
                        {data.subscriptions !== null &&
                        data.subscriptions.length > 0
                          ? data.subscriptions[0]?.items[0].price !== null
                            ? data.subscriptions[0]?.items[0].price.plan.name
                            : "-"
                          : "-"}
                      </span>
                      <span className="block">
                        {data.subscriptions !== null &&
                        data.subscriptions.length > 0
                          ? data.subscriptions[0]?.items[0].price !== null
                            ? formatCurrency(data.subscriptions[0]?.items[0].price.unit_amount) 
                              + " " +
                              data.subscriptions[0]?.items[0].price
                                .recurring_interval +
                              "ly"
                            : "-"
                          : "-"}
                      </span>
                    </td>
                    <td className="w120">
                      <span className="d-block">
                        {data.subscriptions !== null &&
                        data.subscriptions.length > 0
                          ? data.subscriptions[0].start_date !== null
                            ? moment(data.subscriptions[0].start_date)
                                .tz("Europe/Amsterdam")
                                .format("DD-MM-YYYY HH:mm")
                            : "-"
                          : "-"}
                      </span>
                      <span className="block">
                        {data.subscriptions !== null &&
                        data.subscriptions.length > 0
                          ? data.subscriptions[0].ends_at !== null
                            ? moment(data.subscriptions[0].ends_at)
                                .tz("Europe/Amsterdam")
                                .format("DD-MM-YYYY HH:mm")
                            : "-"
                          : "-"}
                      </span>
                    </td>
                    <td className="w100">
                      {data.limited_address !== null &&
                      data.limited_address !== undefined ? (
                        <img
                          className="countryflag"
                          src={data.limited_address.flag_thumbnail}
                          title={data.limited_address.country}
                        />
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="w100">{data.ads_active}</td>
                    <td className="w100">{data.total_ads}</td>
                    <td className="w100">{data.vehicle_bought}</td>
                    <td className="w100">{data.vehicle_sold}</td>
                    <td className="w100">{data.ads_in_auction}</td>
                    <td className="w100">{data.members}</td>
                    <td className="w100">-</td>
                    <td className="w100">{data.sanctions}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="row mt-4">
            <div className="d-flex justify-content-between add-info mt-5">
              <div className="add-depertment d-flex justify-content-end mb-2">
                <h3>Subscriptions</h3>
              </div>
              <div className="d-flex justify-content-between mb-2 gap-2 select-info align-items-center">
                <img
                  src={faArrow}
                  className={(show ? "openMenu" : "closeMenu") + " dropmenu"}
                  onClick={() => _show(!show)}
                />
              </div>
            </div>
          </div>
          <div className={"tableContainer mb-4"}>
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row subsTable">
                  <th>Plan</th>
                  <th>Subscription Status</th>
                  <th className="clipboard">Stripe ID</th>
                  <th>
                    <span className="d-block">Start Date</span>
                    <span>End Date</span>
                  </th>
                  <th className="me-3">
                    <span className="d-block">Current Period Start</span>
                    <span>Current Period End</span>
                  </th>
                </tr>
              </thead>
              <tbody className={show ? "showTable" : "hideTable"}>
                {subscriptions.length > 0 ? (
                  subscriptions.map((item, index) => {
                    let diffDays = 0;
                    diffDays = diffdates(
                      new Date(item?.latest_invoice_data?.invoice_date),
                      new Date()
                    );
                    return (
                      <tr
                        className={
                          (item.deleted_at ? "deletedRow" : "") +
                          " table-row subsTable"
                        }
                        key={index}
                      >
                        <td>
                          <span className="d-block">
                            {item.items !== null && item.items.length > 0
                              ? item.items[0].price !== null
                                ? item.items[0].price.plan.name
                                : "-"
                              : "-"}
                          </span>
                          <span className="block">
                            {item.items !== null && item.items.length > 0
                              ? item.items[0].price !== null
                                ? formatCurrency(item.items[0].price.unit_amount) +
                                  " " +
                                  item.items[0].price.recurring_interval +
                                  "ly"
                                : "-"
                              : "-"}
                          </span>
                        </td>
                        <td>
                          {item.ends_at !== null &&
                          item.stripe_status === "active" ? (
                            <p className="registration-end-status d-block">
                              Cancelling
                            </p>
                          ) : (
                            <p
                              className={
                                Constant.SUBSIDIARY[item.stripe_status] +
                                "  d-block"
                              }
                            >
                              {item.stripe_status
                                ? item.stripe_status
                                    .replace("_", " ")
                                    .charAt(0)
                                    .toUpperCase() +
                                  item.stripe_status.replace("_", " ").slice(1)
                                : "-"}
                              {item.stripe_status === "past_due" &&
                              diffDays > 0 ? (
                                <span> ({diffDays} days)</span>
                              ) : null}
                            </p>
                          )}
                        </td>
                        <td className="clipboard">
                          <div className="my-2 d-flex justify-content-center">
                            <span className="copyText">{item.stripe_id}</span>
                            <CopyToClipboard
                              text={item.stripe_id}
                              onCopy={() => toast("Text Copied")}
                            >
                              <img className="ms-2" src={copy} alt="" />
                            </CopyToClipboard>
                          </div>
                        </td>
                        <td>
                          <span className="d-block">
                            {item.start_date
                              ? moment(item.start_date)
                                  .tz("Europe/Amsterdam")
                                  .format("DD-MM-YYYY HH:mm")
                              : "-"}
                          </span>
                          <span className="d-block">
                            {item.ends_at
                              ? moment(item.ends_at)
                                  .tz("Europe/Amsterdam")
                                  .format("DD-MM-YYYY HH:mm")
                              : "-"}
                          </span>
                        </td>
                        <td className="d-flex align-items-center">
                          <div>
                            <span className="d-block">
                              {item.current_period_start
                                ? moment(item.current_period_start)
                                    .tz("Europe/Amsterdam")
                                    .format("DD-MM-YYYY HH:mm")
                                : "-"}
                            </span>
                            <span className="d-block">
                              {item.current_period_end
                                ? moment(item.current_period_end)
                                    .tz("Europe/Amsterdam")
                                    .format("DD-MM-YYYY HH:mm")
                                : "-"}
                            </span>
                          </div>
                          <div>
                            <img
                              src={alert}
                              className="ms-3 moreIcon"
                              onClick={() => _popup(item.items)}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
            </table>
          </div>
          <div className="row mt-4">
            <div className="d-flex justify-content-between add-info mt-5">
              <div className="add-depertment d-flex justify-content-end mb-2">
                <h3>Sanctions</h3>
              </div>
              <div className="d-flex justify-content-between mb-2 gap-2 select-info align-items-center">
                {companyAccess.permissions.find((o) => o.slug === "update") ? (
                  <div>
                    <NavLink to={getCompanyCreatedCensorUrl(companyId)}>
                      <button className="btn">+ Add Censorship</button>
                    </NavLink>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th>Date</th>
                  <th className="w20">Reasons</th>
                  <th>Sanction Type App</th>
                  <th>Modules Disabled</th>
                  <th>Duration</th>
                  <th>Created By</th>
                  {companyAccess.permissions.find(
                    (o) => o.slug === "delete"
                  ) ? (
                    <th>ACTION</th>
                  ) : (
                    ""
                  )}
                </tr>
              </thead>
              <tbody>
                {censorData.length > 0 ? (
                  censorData.map((item, index) => {
                    let createdBy = "";
                    let days = "";
                    let createdDate = new Date(item.created_at).getTime();
                    let diff = parseInt(
                      (date - createdDate) / (1000 * 3600 * 24)
                    );
                    if (item.duration === 0) {
                      days = 7;
                    } else if (item.duration === 1) {
                      days = 14;
                    } else if (item.duration === 2) {
                      days = 30;
                    } else if (item.duration === 3) {
                      days = 90;
                    }
                    if (
                      item.create_activity_log !== null &&
                      item.create_activity_log !== "" &&
                      item.create_activity_log !== undefined
                    ) {
                      if (
                        item.create_activity_log.first_name !== null ||
                        item.create_activity_log.last_name !== null
                      ) {
                        createdBy = `${
                          item.create_activity_log.first_name !== null
                            ? item.create_activity_log.first_name
                            : ""
                        } ${
                          item.create_activity_log.last_name !== null
                            ? item.create_activity_log.last_name
                            : ""
                        }`;
                      } else {
                        createdBy = item.create_activity_log.pseudo;
                      }
                    } else {
                      if (
                        item.update_activity_log !== null &&
                        item.update_activity_log !== "" &&
                        item.update_activity_log !== undefined
                      ) {
                        if (
                          item.update_activity_log.first_name !== null ||
                          item.update_activity_log.last_name !== null
                        ) {
                          createdBy = `${
                            item.update_activity_log.first_name !== null
                              ? item.update_activity_log.first_name
                              : ""
                          } ${
                            item.update_activity_log.last_name !== null
                              ? item.update_activity_log.last_name
                              : ""
                          }`;
                        } else {
                          createdBy = item.update_activity_log.pseudo;
                        }
                      }
                    }
                    return (
                      <tr
                        className={
                          (item.deleted_at ? "deletedRow" : "") + " table-row"
                        }
                        key={index}
                      >
                        <td>
                          {moment(item.created_at)
                            .tz("Europe/Amsterdam")
                            .format("DD-MM-YYYY HH:mm")}
                        </td>
                        <td className="w20">
                          {item.reason_ids.map((item, index) => {
                            return (
                              <span
                                className="d-flex justify-content-center align-items-center suggestion"
                                key={index}
                              >
                                {comments[item]}
                              </span>
                            );
                          })}
                        </td>
                        <td>
                          <div className="sanctions">
                            {days - diff > 0 ? (
                              <span className="status status-end"></span>
                            ) : (
                              <span className="status status-pending"></span>
                            )}
                            <span>
                              {Constant.BANNED[item.modules_disabled[0]]}
                            </span>
                          </div>
                        </td>
                        <td>
                          {item.modules_disabled.map((item, index) => {
                            return (
                              <span className="d-block">
                                {Constant.MODULES_DISABLED[item]}
                              </span>
                            );
                          })}
                        </td>
                        <td>{Constant.CENSORED_DURATION[item.duration]}</td>
                        <td>
                          {createdBy.charAt(0).toUpperCase() +
                            createdBy.slice(1)}
                        </td>
                        {companyAccess.permissions.find(
                          (o) => o.slug === "delete"
                        ) ? (
                          <td>
                            {item.deleted_at === null ? (
                              <button
                                className={
                                  (loading ? "disabled" : "") + " deleteBtn"
                                }
                                role="button"
                                disabled={loading}
                                onClick={() => removeType(item.id)}
                              >
                                <img src={waste} alt="" />
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
            </table>
          </div>
        </div>
      </CompanyDetailsWrapper>
    </>
  );
};

export default CompanyDetails;
