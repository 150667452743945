import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./styles.css";
import logo from "../../Images/icons/logo.png";
import NotificationDrawer from "../Drawer/NotificationDrawer";
import profilePic from "../../Images/icons/profile-pic.png";
import flagIcon from "../../Images/image/flagIcon.png";
import dataProvider from "../../Images/image/dataProvider.png";
import settingIcon from "../../Images/icons/settings.png";
import {
  FaCompany,
  FaGavel,
  FaLogout,
  FaPlan,
  FaPlus,
  FaTranslate,
  FaUser,
  FaRole,
  FaSlides,
  FaCommission,
  FaVerificationPending,
  FaEditVehicle,
  FaAds,
  FaInvoice,
  FaImport,
  FaLegal,
  FaFaq,
  FaAdsIcon,
  FaBidsIcon,
  FaPartnersIcon,
  FaContact,
  FaMessanger,
  FaFireIcon,
  FaCreditHistoryIcon,
  FaCancelReservationIcon,
  FaForumTagsIcon,
  FaForumFlagsIcon,
  FaVehicleReservationHistory,
  FaGeneralSettingIcon,
} from "../../Images/icon";
import styled from "styled-components";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { Tooltip } from "@mui/material";
import { userTypeName } from "../../../config/constant";

const permission = JSON.parse(localStorage.getItem("permissions")) || [];

//Module Access
const auctionAccess = permission.find((o) => o.slug === "auction_event");
const classicAdsAccess = permission.find((o) => o.slug === "classic_ads");
const bidsAccess = permission.find((o) => o.slug === "bid_list");
const featuresAccess = permission.find((o) => o.slug === "features_management");
const roleAccess = permission.find((o) => o.slug === "role_management");
const usersAccess = permission.find((o) => o.slug === "users");
const verifyUser = permission.find((o) => o.slug === "verify_user");
const validateUser = permission.find((o) => o.slug === "verify_nomad_user");
const tanslationAccess = permission.find((o) => o.slug === "translation");
const planAccess = permission.find((o) => o.slug === "plans");
const couponAccess = permission.find((o) => o.slug === "coupons");
const taxRateAccess = permission.find((o) => o.slug === "tax_rates");
const commissionAccess = permission.find(
  (o) => o.slug === "commission_management"
);
const slideAccess = permission.find((o) => o.slug === "slide_management");
const companyAccess = permission.find((o) => o.slug === "manage_subsidiary");
const vehicleManagementAccess = permission.find(
  (o) => o.slug === "vehicle_management"
);
const invoiceAccess = permission.find((o) => o.slug === "invoices");
const logAccess = permission.find((o) => o.slug === "logs");
const legalDocumentsAccess = permission.find(
  (o) => o.slug === "legal_documents"
);
const faqAccess = permission.find((o) => o.slug === "faq");
const partnerAccess = permission.find((o) => o.slug === "partner");
const importedDealer = permission.find((o) => o.slug === "imported_dealer");
const contactUs = permission.find((o) => o.slug === "contact_us");
const chats = permission.find((o) => o.slug === "chats");
const hotdealAccess = permission.find((o) => o.slug === "hotdeal_event");
const creditAccess = permission.find((o) => o.slug === "credits");
const ForumAccess = permission.find((o) => o.slug === "forum");

const Menu = (props) => {
  const [user, _user] = useState("");
  const [userDP, _userDP] = useState("");

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("user"));
    if (!!data) {
      if (data.first_name || data.last_name) {
        _user(
          `${data.first_name !== null ? data.first_name : ""} ${data.last_name !== null ? data.last_name : ""
          }`
        );
      } else {
        _user(data.pseudo);
      }
      _userDP(data.profile_picture_url);
    }
  }, []);

  useEffect(() => {
    if (props.priceMenu) {
      let elemHeight = document
        .getElementById("pricingBtn")
        .getBoundingClientRect().top;
      let element = document.getElementById("pricingMenu");
      element.style.top = elemHeight - 42 + "px";
    }
  }, [props.priceMenu]);

  useEffect(() => {
    if (props.termsMenu) {
      let elemHeight = document
        .getElementById("termsBtn")
        .getBoundingClientRect().top;
      let element = document.getElementById("termsMenu");
      element.style.top = elemHeight - 100 + "px";
    }
  }, [props.termsMenu]);

  const closeMenu = () => {
    if (props.profileMenu) {
      props._profileMenu(false);
    }
    if (props.priceMenu) {
      props._priceMenu(false);
    }
    if (props.auctionMenu) {
      props._auctionMenu(false);
    }
    if (props.hotdealsMenu) {
      props._hotdealsMenu(false);
    }
    if (props.termsMenu) {
      props._termsMenu(false);
    }
    if (props.forumMenu) {
      props._forumMenu(false);
    }
    if (props.settingMenu) {
      props._settingMenu(false);
    }
  };

  const logout = () => {
    const device_id = JSON.parse(localStorage.getItem("deviceToken"));
    API.get(APP_URLS.LOGOUT + `?device_id=${device_id}&app_type=1`)
      .then((res) => {
        const resp = res.data;
        if (resp) {
          localStorage.clear();
          window.location.href = "/login";
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(function (error) {
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div className="menuContainer">
      <div>
        <div className="logo">
          <NavLink to="/">
            <img src={logo} />
          </NavLink>
        </div>
        <div className="navigationContainer">
          {/* Auctions */}
          {auctionAccess &&
            auctionAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Auctions" placement="right">
              <DivWrapper className="footerLinks">
                <FaGavel
                  onClick={() => {
                    props._auctionMenu(!props.auctionMenu);
                    props._termsMenu(false);
                    props._profileMenu(false);
                    props._priceMenu(false);
                    props._hotdealsMenu(false);
                    props._forumMenu(false);
                    props._settingMenu(false);
                  }}
                />
              </DivWrapper>
            </Tooltip>
          ) : (
            ""
          )}
          {props.auctionMenu ? (
            <div className="auctionMenu">
              <NavLink to="/auction-events" className="auctionLinks">
                <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                  Auction Events
                </p>
              </NavLink>
              <NavLink to="/auctions" className="auctionLinks">
                <p className="py-2 ps-2" onClick={closeMenu}>
                  Auctions
                </p>
              </NavLink>
            </div>
          ) : (
            ""
          )}

          {/* Hotdeals */}
          {hotdealAccess &&
            hotdealAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Hotdeals" placement="right">
              <DivWrapper className="footerLinks">
                <FaFireIcon
                  onClick={() => {
                    props._hotdealsMenu(!props.hotdealsMenu);
                    props._auctionMenu(false);
                    props._termsMenu(false);
                    props._profileMenu(false);
                    props._priceMenu(false);
                    props._forumMenu(false);
                    props._settingMenu(false);
                  }}
                />
              </DivWrapper>
            </Tooltip>
          ) : (
            ""
          )}
          {props.hotdealsMenu ? (
            <div className="hotdealsMenu">
              <NavLink to="/hotdeal-events" className="auctionLinks">
                <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                  Hotdeal Events
                </p>
              </NavLink>
              <NavLink to="/hotdeals" className="auctionLinks">
                <p className="py-2 ps-2" onClick={closeMenu}>
                  Hotdeals
                </p>
              </NavLink>
            </div>
          ) : (
            ""
          )}

          {/* Classic Ads */}
          {classicAdsAccess &&
            classicAdsAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Classic Ads" placement="right">
              <LinkWrapper
                to="/classic-ads"
                className="links"
                onClick={closeMenu}
              >
                <FaAdsIcon />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Bids */}
          {bidsAccess &&
            bidsAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Bids" placement="right">
              <LinkWrapper to="/bid-list" className="links" onClick={closeMenu}>
                <FaBidsIcon />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Users */}
          {usersAccess &&
            usersAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Users" placement="right">
              <LinkWrapper to="/users" className="links" onClick={closeMenu}>
                <FaUser />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* companies */}
          {companyAccess &&
            companyAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title={userTypeName.DEALER} placement="right">
              <LinkWrapper
                to="/companies"
                className="links"
                onClick={closeMenu}
              >
                <FaCompany />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Pending Verification */}
          {(verifyUser &&
            verifyUser.permissions.find((o) => o.slug === "read")) ||
            (validateUser &&
              validateUser.permissions.find((o) => o.slug === "read")) ? (
            <Tooltip title="Pending Verification " placement="right">
              <LinkWrapper
                to="/pending-verifications"
                className="links"
                onClick={closeMenu}
              >
                <FaVerificationPending />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Vehicle Reservation History */}
          {(verifyUser &&
            verifyUser.permissions.find((o) => o.slug === "read")) ||
            (validateUser &&
              validateUser.permissions.find((o) => o.slug === "read")) ? (
            <Tooltip title="Vehicle Reservation History " placement="right">
              <LinkWrapper
                to="/vehicle-reservation-history"
                className="links"
                onClick={closeMenu}
              >
                <FaVehicleReservationHistory />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Cancel Reservation */}
          {hotdealAccess &&
            hotdealAccess.permissions.find((o) => o.slug === "read") && (
              <Tooltip title="Cancel Reservation" placement="right">
                <LinkWrapper
                  to="/cancel-reservation-request"
                  className="links"
                  onClick={closeMenu}
                >
                  <FaCancelReservationIcon />
                </LinkWrapper>
              </Tooltip>
            )}

          {/* Plans, Coupons, Taxes and Credits */}
          {couponAccess || planAccess || taxRateAccess || creditAccess ? (
            <>
              <DivWrapper className="footerLinks" id="pricingBtn">
                <Tooltip
                  title="Plans, Coupons, Taxes and Credits"
                  placement="right"
                >
                  <FaPlan
                    onClick={() => {
                      props._priceMenu(!props.priceMenu);
                      props._termsMenu(false);
                      props._auctionMenu(false);
                      props._profileMenu(false);
                      props._forumMenu(false);
                      props._settingMenu(false);
                    }}
                  />
                </Tooltip>
                {props.priceMenu ? (
                  <div className="priceMenu" id="pricingMenu">
                    {planAccess &&
                      planAccess.permissions.find((o) => o.slug === "read") ? (
                      <NavLink to="/plans" className="auctionLinks">
                        <p
                          className="py-2 ps-2 auctionEvent"
                          onClick={closeMenu}
                        >
                          Plans
                        </p>
                      </NavLink>
                    ) : (
                      ""
                    )}
                    {taxRateAccess &&
                      taxRateAccess.permissions.find((o) => o.slug === "read") ? (
                      <NavLink to="/tax-rates" className="auctionLinks">
                        <p
                          className="py-2 ps-2 auctionEvent"
                          onClick={closeMenu}
                        >
                          Tax Rates
                        </p>
                      </NavLink>
                    ) : (
                      ""
                    )}
                    {couponAccess &&
                      couponAccess.permissions.find((o) => o.slug === "read") ? (
                      <NavLink to="/coupons" className="auctionLinks">
                        <p
                          className="py-2 ps-2 auctionEvent"
                          onClick={closeMenu}
                        >
                          Coupons
                        </p>
                      </NavLink>
                    ) : (
                      ""
                    )}
                    {creditAccess &&
                      creditAccess.permissions.find((o) => o.slug === "read") ? (
                      <NavLink to="/credits" className="auctionLinks">
                        <p className="py-2 ps-2" onClick={closeMenu}>
                          Credits
                        </p>
                      </NavLink>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </DivWrapper>
            </>
          ) : (
            ""
          )}

          {/* Commission */}
          {commissionAccess &&
            commissionAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Commission" placement="right">
              <LinkWrapper
                to="/commission"
                className="links"
                onClick={closeMenu}
              >
                <FaCommission />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Invoices */}
          {invoiceAccess &&
            invoiceAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Invoices" placement="right">
              <LinkWrapper to="/invoices" className="links" onClick={closeMenu}>
                <FaInvoice />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Credit History */}
          {creditAccess &&
            creditAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Credit History" placement="right">
              <LinkWrapper
                to="/credit-history"
                className="links"
                onClick={closeMenu}
              >
                <FaCreditHistoryIcon />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Vehicles Management */}
          {vehicleManagementAccess &&
            vehicleManagementAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Vehicles Management" placement="right">
              <LinkWrapper
                to="/vehicles-management"
                className="links"
                onClick={closeMenu}
              >
                <FaEditVehicle />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Translation */}
          {tanslationAccess &&
            tanslationAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Translation" placement="right">
              <LinkWrapper
                to="/translation"
                className="links"
                onClick={() => {
                  localStorage.setItem("Translation", "Languages");
                  closeMenu();
                }}
              >
                <FaTranslate />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* Partners */}
          {partnerAccess &&
            partnerAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Partners" placement="right">
              <LinkWrapper to="/partners" className="links" onClick={closeMenu}>
                <FaPartnersIcon />
              </LinkWrapper>
            </Tooltip>
          ) : (
            ""
          )}

          {/* <Tooltip title="Import" placement="right">
            <LinkWrapper to="/import-files" className="links" onClick={closeMenu}>
              <FaImport />
            </LinkWrapper>
          </Tooltip> */}

          {/* Legal Documents */}
          {legalDocumentsAccess &&
            legalDocumentsAccess.permissions.find((o) => o.slug === "read") ? (
            <DivWrapper className="footerLinks" id="termsBtn">
              <Tooltip title="Legal Documents" placement="right">
                <FaLegal
                  onClick={() => {
                    props._termsMenu(!props.termsMenu);
                    props._priceMenu(false);
                    props._auctionMenu(false);
                    props._profileMenu(false);
                    props._forumMenu(false);
                    props._settingMenu(false);
                  }}
                />
              </Tooltip>
              {props.termsMenu ? (
                <div className="termsMenu" id="termsMenu">
                  <NavLink
                    to="/legal-documents/nomad-terms"
                    className="auctionLinks"
                  >
                    <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                      Nomad Terms
                    </p>
                  </NavLink>
                  <NavLink to="/legal-documents/terms" className="auctionLinks">
                    <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                      Terms
                    </p>
                  </NavLink>
                  <NavLink
                    to="/legal-documents/privacy-policy"
                    className="auctionLinks"
                  >
                    <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                      Privacy Policy
                    </p>
                  </NavLink>
                  <NavLink
                    to="/legal-documents/data-protection"
                    className="auctionLinks"
                  >
                    <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                      Data Protection
                    </p>
                  </NavLink>
                  <NavLink
                    to="/legal-documents/legal-notice"
                    className="auctionLinks"
                  >
                    <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                      Legal Notice
                    </p>
                  </NavLink>
                  <NavLink
                    to="/legal-documents/legal-documents"
                    className="auctionLinks"
                  >
                    <p className="py-2 ps-2" onClick={closeMenu}>
                      Legal Documents
                    </p>
                  </NavLink>
                </div>
              ) : (
                ""
              )}
            </DivWrapper>
          ) : (
            ""
          )}

          {/* Flagged Review */}
          <Tooltip title="Flagged Review" placement="right">
            <LinkWrapper
              to="/flagged-review"
              className="links"
              onClick={closeMenu}
            >
              <img src={flagIcon} alt="" role="button" />
            </LinkWrapper>
          </Tooltip>

          {/* Data Provider */}
          {importedDealer &&
            importedDealer.permissions.find((o) => o.slug === "read") && (
              <Tooltip title="Data Provider" placement="right">
                <LinkWrapper
                  to="/data-provider"
                  className="links"
                  onClick={closeMenu}
                >
                  <img src={dataProvider} alt="" role="button" />
                </LinkWrapper>
              </Tooltip>
            )}

          {/* Contact us */}
          {contactUs &&
            contactUs.permissions.find((o) => o.slug === "read") && (
              <Tooltip title="Contact Us" placement="right">
                <LinkWrapper
                  to="/contacts"
                  className="links"
                  onClick={closeMenu}
                >
                  <FaContact />
                </LinkWrapper>
              </Tooltip>
            )}

          {/* Messenger */}
          {chats && chats.permissions.find((o) => o.slug === "read") && (
            <Tooltip title="Messenger" placement="right">
              <LinkWrapper
                to="/messenger"
                className="links"
                onClick={closeMenu}
              >
                <FaMessanger />
              </LinkWrapper>
            </Tooltip>
          )}

          {/* Settings */}
          <Tooltip title="Settings" placement="right">
            <DivWrapper className="footerLinks">
              <FaGeneralSettingIcon
                onClick={() => {
                  props._settingMenu(!props.settingMenu);
                  props._forumMenu(false);
                  props._hotdealsMenu(false);
                  props._auctionMenu(false);
                  props._termsMenu(false);
                  props._profileMenu(false);
                  props._priceMenu(false);
                }}
              />
            </DivWrapper>
          </Tooltip>
          {props.settingMenu ? (
            <div className="settingMenu">
              <NavLink to="/general-settings" className="auctionLinks">
                <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                  General settings
                </p>
              </NavLink>
              {slideAccess &&
                slideAccess.permissions.find((o) => o.slug === "read") ? (
                <NavLink to="/slides/home-slider" className="auctionLinks">
                  <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                    Home Slider
                  </p>
                </NavLink>) : null}
              {faqAccess && faqAccess.permissions.find((o) => o.slug === "read") ? (<NavLink to="/faqs" className="auctionLinks">
                <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                  FAQs
                </p>
              </NavLink>) : null}
              {featuresAccess &&
                featuresAccess.permissions.find((o) => o.slug === "read") ? (
                <NavLink to="/features" className="auctionLinks">
                  <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                    Features
                  </p>
                </NavLink>
              ) : null}
              {roleAccess &&
                roleAccess.permissions.find((o) => o.slug === "read") ? (
                <NavLink to="/admin-roles-management" className="auctionLinks">
                  <p className="py-2 ps-2" onClick={closeMenu}>
                    Roles management
                  </p>
                </NavLink>
              ) : null}
            </div>
          ) : (
            ""
          )}

          {/* Forum  */}
          {ForumAccess && ForumAccess.permissions.find((o) => o.slug === "read") ? (
            <Tooltip title="Forums" placement="right">
              <DivWrapper className="footerLinks">
                <FaForumTagsIcon
                  onClick={() => {
                    props._forumMenu(!props.forumMenu);
                    props._settingMenu(false);
                    props._hotdealsMenu(false);
                    props._auctionMenu(false);
                    props._termsMenu(false);
                    props._profileMenu(false);
                    props._priceMenu(false);
                  }}
                />
              </DivWrapper>
            </Tooltip>
          ) : (
            ""
          )}
          {props.forumMenu ? (
            <div className="forumMenu">
              <NavLink to="/tags" className="auctionLinks">
                <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
                  Tags
                </p>
              </NavLink>
              <NavLink to="/forum-flags" className="auctionLinks">
                <p className="py-2 ps-2" onClick={closeMenu}>
                  Flagged comments
                </p>
              </NavLink>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="footerContainer">
        {/* Notifications */}
        <NotificationDrawer />

        {/* profileMenu */}
        <DivWrapper className="footerLinks profileImage">
          <img
            src={userDP !== null && userDP !== "" ? userDP : profilePic}
            onClick={() => {
              props._profileMenu(!props.profileMenu);
              props._termsMenu(false);
              props._priceMenu(false);
              props._auctionMenu(false);
            }}
          />
        </DivWrapper>
        {props.profileMenu ? (
          <div
            className="profileMenu"
            style={
              logAccess && logAccess.permissions.find((o) => o.slug === "read")
                ? { bottom: "30px" }
                : { bottom: "45px" }
            }
          >
            <p className="py-2 ps-2 auctionEvent" onClick={closeMenu}>
              {user.charAt(0).toUpperCase() + user.slice(1)}
            </p>
            <NavLink to="/change-password" className="auctionLinks">
              <p
                className={
                  (logAccess &&
                    logAccess.permissions.find((o) => o.slug === "read")
                    ? "auctionEvent"
                    : "") + " py-2 ps-2"
                }
                onClick={closeMenu}
              >
                Reset Password
              </p>
            </NavLink>
            {logAccess &&
              logAccess.permissions.find((o) => o.slug === "read") ? (
              <NavLink to="/server-logs" className="auctionLinks">
                <p className="py-2 ps-2" onClick={closeMenu}>
                  Server Logs
                </p>
              </NavLink>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}

        {/* Logout */}
        <Tooltip title="Logout" placement="right">
          <DivWrapper className="footerLinks" onClick={logout}>
            <FaLogout />
          </DivWrapper>
        </Tooltip>
      </div>
    </div>
  );
};

export default Menu;

const LinkWrapper = styled(NavLink)`
  svg {
    width: 25px;
    height: 25px;
    fill: #ffffff;
    path {
      // stroke: #FFFFFF;
    }
  }
`;

const DivWrapper = styled.div`
  svg {
    width: 25px;
    height: 25px;
    fill: #ffffff;
    path {
      stroke: #ffffff;
    }
    cursor: pointer;
  }
`;
