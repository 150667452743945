import React, { useEffect, useState } from "react";
import { CommissionWrapper } from "./style/commissionTable";
import waste from "../../../assets/Images/image/Waste.png";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import Pagination from "../../../assets/SharedComponents/Pagination";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { Box, Button, Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import moment from "moment";
import * as Constant from "../../../config/constant";
import { confirmAlert } from "react-confirm-alert";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import StaticFilter from "../../../assets/SharedComponents/Filters/StaticFilter";
import { formatCurrency } from "../../../core/utils";

const Commission = () => {

  const [data, _data] = useState([]);
  const [loaded, _loaded] = useState(false);
  const [searchkeyword, _searchkeyword] = useState("");
  const [countValue, _countValue] = useState('');
  const [filters, _filters] = useState(false);
  const [trashed, _trashed] = useState(true);
  const [page, setPage] = useState(1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);
  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
  const [buttonPermission, _buttonPermission] = useState({nomad_commission:true,vehicle_sale_commission:true});
  const [commisionType, _commisionType] = useState("");
  const commissionAccess = userAccess.find(o=>o.slug ==="commission_management")
  const commisionTypeList = Object.keys(Constant.commissionTypeOptions).map((key) => ({ name: key, value: Constant.commissionTypeOptions[key] }))

  useEffect(() => {
    getCommissions()
  }, [page,searchkeyword,countValue,trashed, commisionType])
  
  const getCommissions = () => {
    _loaded(true);
    API.get(APP_URLS.LIST_COMMISSION + `?page=${page}&per_page=${countValue}&keyword=${searchkeyword}&with_trashed=${trashed===true?1:0}&filter[type]=${commisionType}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data.data);
          _totalPage(resp.data.data.last_page);
          _total(resp.data.data.total);
          _buttonPermission({
            nomad_commission:resp.data.nomad_commission,
            vehicle_sale_commission:resp.data.vehicle_sale_commission
          })
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_COMMISSION + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success("Commission has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  getCommissions()
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (searchValue !== "") {
      _totalPage(0)
      setPage(1);
    }
  };

  //getting commission type value
  const getCommissionType = (item) => {
    _commisionType(item);
    _totalPage(0)
    setPage(1);
  }

  // removing all filter
  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _trashed(true)
    _commisionType("")
    if (searchkeyword !== ""||countValue !== ''||trashed!==true) {
      _totalPage(0)
      setPage(1);
    }
  };
  
  // Function to reverse the mapping
  const getCommissionTypeByNumber = (number) => {
    const reversedOptions = Object.fromEntries(
      Object.entries(Constant.commissionTypeOptions).map(([key, value]) => [value, key])
    );
    return reversedOptions[number] || 'Unknown type';
  };


  return (
    <>
      <CommissionWrapper>
        <div className="auctionMainContainer">
          <div className="row mt-4">
            <div className="col-md-4 offset-md-4">
              <h1>COMMISSIONS</h1>
            </div>
          </div>
          <div className="row">
            <div className="d-flex justify-content-between add-info flex-wrap">
              <div className="add-depertment d-flex justify-content-end mb-2">
              {commissionAccess.permissions.find((o) => o.slug === "add")? 
                  <Box sx={{display:'flex', gap:'8px'}}>
                    <ConditionalNavLink
                      to="/create-commission"
                      state={{ type: Constant.commissionTypeOptions["Nomad commission"] }}
                      disabled={buttonPermission.nomad_commission}
                    >
                      + Add Nomad commission
                    </ConditionalNavLink>
                    <ConditionalNavLink
                      to="/create-commission"
                      state={{ type: Constant.commissionTypeOptions["Vehicle sale commission"] }}
                      disabled={buttonPermission.vehicle_sale_commission}
                    >
                      + Add Vehicle sale commission
                    </ConditionalNavLink>
                  </Box>
                  :""
                }
              </div>
              <div className="d-flex justify-content-end mb-2 gap-2 flex-wrap align-items-center gap-2">
              <StaticFilter placeholder='Type' data={commisionTypeList} handleChange={(e) => { getCommissionType(e.target.value) }} value={commisionType} />
              <SearchKey
                onSearchClick={(search) => handleSearchChange(_searchkeyword,search)}
                id="searchData"
                filters={filters}
                _filters={_filters}
                placeholder="Search by Title"
              />
              <RecordCount
                onSearchClick={(search) => handleSearchChange(_countValue, search)}
                id="pageCount"
                filters={filters}
                _filters={_filters} />
              <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
              </Tooltip>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
            <div className="mt-1">
              <input type="checkbox" checked={!trashed} onChange={()=>{
                _totalPage(0)
                setPage(1);
                _trashed(!trashed)
                }}/>
              <span className="ms-2">Hide Deleted Data</span>
            </div>
        </div>
          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th>ID</th>
                  <th>Commision Name</th>
                  <th>Amount %</th>
                  <th>Amount €</th>
                  <th>Max. amount</th>
                  <th>Recurring</th>
                  <th>Type</th>
                  <th>Required Min. Sales Per Period</th>
                  <th>Deleted At</th>
                  {commissionAccess.permissions.find((o) => o.slug === "delete") ? <th className="tableHead-9">Action</th> : ""}
                </tr>
              </thead>
              {
              loaded ? 
                <div className='d-flex justify-content-center three-dot'>              
                    <ThreeDots 
                      height="150" 
                      width="150" 
                      radius="9"
                      color="#637df4" 
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                </div>
              : 
              <tbody>
                {data.length > 0 ?
                  data.map((item, index) => {
                  return <tr className={(item.deleted_at ? "deletedRow" : "") + " table-row"} key={index}>
                    <td>{item.id}</td>
                    <td>{item.name?item.name.charAt(0).toUpperCase() + item.name.slice(1):'-'}</td>
                    <td>{item.percent!==null?item.percent +" %":"-"}</td>
                    <td>{item.amount!==null?formatCurrency(item.amount):"-"}</td>
                    <td>{item.max_amount!==null?formatCurrency(item.max_amount):"-"}</td>
                    <td>{Constant.COMMISSION_RECURRING[item.recurring] || '-'}</td>
                    <td>{getCommissionTypeByNumber(item.type)}</td>
                    <td>{item.minimum_sales_amount!==null?formatCurrency(item.minimum_sales_amount):"-"}</td>
                    <td>{item.deleted_at!==null?moment(item.deleted_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm"):"-"}</td>
                    {commissionAccess.permissions.find((o) => o.slug === "delete") ?
                    <td>
                        {item.deleted_at===null?<img src={waste} alt="" role="button"  onClick={() => removeType(item.id)}/>:""}
                    </td>
                    :''}
                  </tr>
                  })
                  :<NoRecord />}
              </tbody>
              }
            </table>
          </div>
            {totalPage > 1 ? <Pagination totalPages={totalPage} page={page} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
        </div>
      </CommissionWrapper>
    </>
  );
};

export default Commission;


const ConditionalNavLink = ({ to, state, disabled, children }) => {
  return disabled ? (
    <Button sx={{ textTransform: 'none', color: '#fff !important' }} className="btn disabled" disabled={true}>
      {children}
    </Button>
  ) : (
    <NavLink className="btn" to={to} state={state}>
      {children}
    </NavLink>
  );
};
