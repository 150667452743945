import React, { useEffect, useState } from 'react'
import MainLayout from '../../../assets/Layout/MainLayout'
import { VerifyIdListWrapper } from './style/verifyIdList'
import Verification from './Components/Verification'
import Validation from './Components/Validation'
import { useLocation, useSearchParams } from 'react-router-dom'

const VerifyId = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();
  const [permission,_permission] = useState(JSON.parse(localStorage.getItem('permissions')))

  let verifyUser = permission.find(o=>o.slug ==="verify_user")
  let validateUser = permission.find(o=>o.slug ==="verify_nomad_user")

  const [tabView,setTabView]=useState(
          verifyUser&&verifyUser.permissions.find((o)=>o.slug==='read')?
          "verification":
          validateUser&&validateUser.permissions.find((o)=>o.slug==='read')?
          "validation":""
          );
          
  useEffect(()=>{
    if(Array.from(query).length>0){
      setTabView(query.get('pendingTab')==="validation"?"validation":"verification")
    }
  },[query])

  const tabChange = (tab) =>{
    setTabView(tab)
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  }

    return (
      <VerifyIdListWrapper>
          <div className="auctionMainContainer">
              <div className="row mt-4 mb-sm-4">
                  <div className="col-md-4 offset-md-4">
                      <h1>PENDING VERIFICATIONS</h1>
                  </div>
              </div>
              
              <div className="row mb-3">
                <div className="col-md-6 offset-md-3 d-flex justify-content-center">
                  <div className="tabBtn d-flex">
                  {verifyUser &&
                    verifyUser.permissions.find((o) => o.slug === "read") ?
                    <button className={tabView === "verification" ? "activeTab" : ""} onClick={() => tabChange("verification")}>
                      Front Office
                    </button>
                    :""}
                  {validateUser &&
                    validateUser.permissions.find((o) => o.slug === "read") ?
                    <button className={tabView === "validation" ? "activeTab" : " "} onClick={() => tabChange("validation")}>
                      Nomad Users
                    </button>
                    :""}
                  </div>
                </div>
              </div>

              {tabView === "verification" ? <Verification /> : tabView === "validation" ? <Validation />: ''}

          </div>
      </VerifyIdListWrapper>
    )
}

export default VerifyId