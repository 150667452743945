const APP_API_URL = process.env.REACT_APP_API_URL
  ? process.env.REACT_APP_API_URL
  : "";

export const APP_URLS = {
  LOGIN: APP_API_URL + "/login",
  FORGOT_PASSWORD: APP_API_URL + "/password/email",
  RESET_PASSWORD: APP_API_URL + "/password/reset",
  UPDATE_PASSWORD: APP_API_URL + "/user/password/change",
  LOGOUT: APP_API_URL + "/logout",

  // USER ACCESS
  USER_PERMISSION: APP_API_URL + "/user",

  LANGUAGE_LIST: APP_API_URL + "/languages",
  PRODUCT_TYPES: APP_API_URL + "/admin/product_types",

  //Countries
  COUNTRY_LIST: APP_API_URL + "/countries",
  LIST_COUNTRIES: APP_API_URL + "/admin/countries",
  SHOW_COUNTRY: APP_API_URL + "/admin/countries",
  UPDATE_COUNTRY: APP_API_URL + "/admin/countries",

  //Continent
  LIST_CONTINENTS: APP_API_URL + "/admin/continents",
  SHOW_CONTINENT: APP_API_URL + "/admin/continents",
  UPDATE_CONTINENT: APP_API_URL + "/admin/continents",
  UPDATE_CONTINENT_ORDER: APP_API_URL + "/admin/continents",

  // Auctions
  CREATE_AUCTION: APP_API_URL + "/admin/auctions",
  ACTIVE_SUBSIDIARY: APP_API_URL + "/admin/subsidiary/list",
  LIST_AUCTIONS_EVENTS: APP_API_URL + "/admin/auction-events",
  DISABLE_AUCTION_EVENT: APP_API_URL + "/admin/auction-events",
  VIEW_AUCTION_EVENT: APP_API_URL + "/admin/auction-events",
  UPDATE_AUCTION_EVENT: APP_API_URL + "/admin/auction-events",
  LIST_AUCTIONS: APP_API_URL + "/admin/auctions",
  AUCTION_REPORT: APP_API_URL + "/admin/auctions",
  DISABLE_AUCTION: APP_API_URL + "/admin/auctions",
  LIST_AUCTION_ADS: APP_API_URL + "/admin/auctions/ads",
  DELETE_AUCTION_ADS: APP_API_URL + "/admin/ad_vehicles",
  AUCTION_ADS_DETAIL: APP_API_URL + "/admin/auctions/view",
  LIST_AUCTION_BIDLIST: APP_API_URL + "/admin/auctions/bids",
  AUCTION_BIDLIST_DETAIL: APP_API_URL + "/admin/ad_vehicles",
  DELETE_AUCTION_BID: APP_API_URL + "/admin/auctions/delete_bids",

  // Users
  LIST_USERS: APP_API_URL + "/admin/users",
  VIEW_USER_DETAIL: APP_API_URL + "/admin/users",
  LIST_NON_MEMBER_USERS: APP_API_URL + "/admin/users/non_members",
  VIEW_USER_VERIFICATION_STATUS: APP_API_URL + "/admin/users",
  VIEW_USER_VALIDATION_STATUS: APP_API_URL + "/admin/users",
  UPDATE_USER_VERIFICATION_STATUS: APP_API_URL + "/admin/users",
  UPDATE_USER_VALIDATION_STATUS: APP_API_URL + "/admin/users",
  INVITE_ADMIN: APP_API_URL + "/admin/users/invite",
  INVITE_ACTION: APP_API_URL + "/admin/users/admin_verify_invitation",
  UNVERIFIED_USERS: APP_API_URL + "/admin/users/unverified",
  VERIFY_COUNT: APP_API_URL + "/admin/users/verificationCount",
  UNVALIDATED_USERS: APP_API_URL + "/admin/users/unverified/nomad",
  PENDING_VERIFICATION_USERS: APP_API_URL + "/admin/users/pending_verification",
  VALIDATION_COUNT: APP_API_URL + "/admin/users/validationCount/nomad",
  ACCESS_HISTORY: APP_API_URL + "/admin/users/",
  UPDATE_USER_DETAILS: APP_API_URL + "/admin/users/update_user_details",
  UPDATE_USER_ADDRESS: APP_API_URL + "/admin/users/update_user_address",

  // User Censorship
  CENSOR_USER: APP_API_URL + "/admin/censorships/user/store",
  VIEW_USER_CENSORSHIP: APP_API_URL + "/admin/censorships/user",
  DELETE_USER_CENSORSHIP: APP_API_URL + "/admin/censorships/user",

  // Subsidiary Censorship
  CENSOR_SUBSIDIARY: APP_API_URL + "/admin/censorships/subsidiary/store",
  VIEW_SUBSIDIARY_CENSORSHIP: APP_API_URL + "/admin/censorships/subsidiary",
  DELETE_SUBSIDIARY_CENSORSHIP: APP_API_URL + "/admin/censorships/subsidiary",

  //Subsidiary
  LIST_SUBSIDIARY: APP_API_URL + "/admin/subsidiaries",
  VIEW_SUBSIDIARY: APP_API_URL + "/admin/subsidiaries",
  LIST_SUBSIDIARIES: APP_API_URL + "/admin/subsidiary",

  //Commissions
  LIST_COMMISSION: APP_API_URL + "/admin/commissions",
  ADD_COMMISSION: APP_API_URL + "/admin/commissions",
  DELETE_COMMISSION: APP_API_URL + "/admin/commissions",

  //Invoices
  LIST_NOMAD_INVOICE: APP_API_URL + "/admin/nomad_invoices",
  LIST_SUBSCRIPTION_INVOICE: APP_API_URL + "/admin/subscription_invoices",
  LIST_NOMADS: APP_API_URL + "/admin/nomad_names",
  LIST_NOMADS_TAXRATES: APP_API_URL + "/admin/invoices/tax_rates",
  LIST_VEHICLE_COMMISSION: APP_API_URL + "/admin/vehicle_sales_invoices",
  CREDIT_EXTRACT_REPORT: APP_API_URL + "/admin/credit_invoices",

  // Languages
  ADMIN_LANGUAGES: APP_API_URL + "/admin/languages",
  ADD_LANGUAGE: APP_API_URL + "/admin/languages",
  GET_LANGUAGE: APP_API_URL + "/admin/languages",
  UPDATE_LANGUAGE: APP_API_URL + "/admin/languages",
  DELETE_LANGUAGE: APP_API_URL + "/admin/languages",

  //Plans
  LIST_PLANS: APP_API_URL + "/admin/plans",
  ADD_PLAN: APP_API_URL + "/admin/plans",
  ADD_FEATURES_TO_PLAN: APP_API_URL + "/admin/plans",
  VIEW_PLAN: APP_API_URL + "/admin/plans",
  UPDATE_PLAN: APP_API_URL + "/admin/plans",
  DELETE_PLAN: APP_API_URL + "/admin/plans",

  // Coupons
  GET_PLANS: APP_API_URL + "/plans",
  ADD_COUPON: APP_API_URL + "/admin/coupons",
  LIST_COUPONS: APP_API_URL + "/admin/coupons",
  DELETE_COUPON: APP_API_URL + "/admin/coupons",
  VIEW_COUPON: APP_API_URL + "/admin/coupons",
  ADD_PROMO: APP_API_URL + "/admin/promo_codes",
  UPDATE_PROMO: APP_API_URL + "/admin/promo_codes",
  SYNC_COUPONS: APP_API_URL + "/admin/coupons/sync",

  // Images
  ADD_ICON: APP_API_URL + "/admin/media",

  //Features
  TOGGLE_PERMISSION: APP_API_URL + "/admin/permissions",

  //Roles
  LIST_ADMIN_ROLES: APP_API_URL + "/admin/roles",
  CREATE_ADMIN_ROLE: APP_API_URL + "/admin/roles",
  VIEW_ADMIN_ROLE: APP_API_URL + "/admin/roles",
  UPDATE_ADMIN_ROLE: APP_API_URL + "/admin/roles",
  DELETE_ADMIN_ROLE: APP_API_URL + "/admin/roles",
  CHANGE_ADMIN_ROLE: APP_API_URL + "/admin/roles",
  REMOVE_ADMIN_ACCESS: APP_API_URL + "/admin/roles",

  // Tax
  LIST_TAX_RATES: APP_API_URL + "/admin/tax_rates",
  CREATE_TAX_RATE: APP_API_URL + "/admin/tax_rates",
  VIEW_TAX_RATE: APP_API_URL + "/admin/tax_rates",
  UPDATE_TAX_RATE: APP_API_URL + "/admin/tax_rates",

  // Translation
  //Departments
  LIST_DEPARTMENTS: APP_API_URL + "/admin/departments",
  DELETE_DEPARTMENT: APP_API_URL + "/admin/departments",
  SHOW_DEPARTMENT: APP_API_URL + "/admin/departments",
  ADD_DEPARTMENT: APP_API_URL + "/admin/departments",
  UPDATE_DEPARTMENT: APP_API_URL + "/admin/departments",

  //Battery Types
  LIST_BATTERY_TYPES: APP_API_URL + "/admin/battery_types",
  DELETE_BATTERY_TYPES: APP_API_URL + "/admin/battery_types",
  SHOW_BATTERY_TYPES: APP_API_URL + "/admin/battery_types",
  ADD_BATTERY_TYPES: APP_API_URL + "/admin/battery_types",
  UPDATE_BATTERY_TYPES: APP_API_URL + "/admin/battery_types",

  // CONSTANST
  LIST_CONSTANTS: APP_API_URL + "/admin/constants",
  ADD_CONSTANTS: APP_API_URL + "/admin/constants",
  UPDATE_CONSTANTS: APP_API_URL + "/admin/constants",
  SHOW_CONSTANTS: APP_API_URL + "/admin/constants",
  DELETE_CONSTANTS: APP_API_URL + "/admin/constants",

  //Body
  LIST_BODY: APP_API_URL + "/admin/body_vehicles",
  ADD_BODY: APP_API_URL + "/admin/body_vehicles",
  UPDATE_BODY: APP_API_URL + "/admin/body_vehicles",
  SHOW_BODY: APP_API_URL + "/admin/body_vehicles",
  DELETE_BODY: APP_API_URL + "/admin/body_vehicles",

  //Fuel
  LIST_FUEL: APP_API_URL + "/admin/fuels",
  ADD_FUEL: APP_API_URL + "/admin/fuels",
  UPDATE_FUEL: APP_API_URL + "/admin/fuels",
  SHOW_FUEL: APP_API_URL + "/admin/fuels",
  DELETE_FUEL: APP_API_URL + "/admin/fuels",

  //Color
  LIST_COLOR: APP_API_URL + "/admin/colors",
  ADD_COLOR: APP_API_URL + "/admin/colors",
  UPDATE_COLOR: APP_API_URL + "/admin/colors",
  SHOW_COLOR: APP_API_URL + "/admin/colors",
  DELETE_COLOR: APP_API_URL + "/admin/colors",

  // Features
  LIST_FEATURES: APP_API_URL + "/admin/features",
  SHOW_FEATURES: APP_API_URL + "/admin/features",
  UPDATE_FEATURES: APP_API_URL + "/admin/features",
  FEATURE_PERMISSIONS: APP_API_URL + "/admin/features",
  SHOW_FEATURE_PERMISSION: APP_API_URL + "/admin/permissions",
  UPDATE_FEATURE_PERMISSION: APP_API_URL + "/admin/features/update_permissions",

  // Conditions
  LIST_CONDITIONS: APP_API_URL + "/admin/vehicle_conditions",
  ADD_CONDITIONS: APP_API_URL + "/admin/vehicle_conditions",
  UPDATE_CONDITIONS: APP_API_URL + "/admin/vehicle_conditions",
  SHOW_CONDITIONS: APP_API_URL + "/admin/vehicle_conditions",
  DELETE_CONDITIONS: APP_API_URL + "/admin/vehicle_conditions",

  //Interior
  LIST_INTERIOR: APP_API_URL + "/admin/interiors",
  ADD_INTERIOR: APP_API_URL + "/admin/interiors",
  UPDATE_INTERIOR: APP_API_URL + "/admin/interiors",
  SHOW_INTERIOR: APP_API_URL + "/admin/interiors",
  DELETE_INTERIOR: APP_API_URL + "/admin/interiors",

  // Driving Wheel
  LIST_DRIVING_WHEEL: APP_API_URL + "/admin/driving_wheels",
  ADD_DRIVING_WHEEL: APP_API_URL + "/admin/driving_wheels",
  UPDATE_DRIVING_WHEEL: APP_API_URL + "/admin/driving_wheels",
  SHOW_DRIVING_WHEEL: APP_API_URL + "/admin/driving_wheels",
  DELETE_DRIVING_WHEEL: APP_API_URL + "/admin/driving_wheels",

  // Frame Sizes
  LIST_FRAME_SIZES: APP_API_URL + "/admin/frame_sizes",
  ADD_FRAME_SIZES: APP_API_URL + "/admin/frame_sizes",
  UPDATE_FRAME_SIZES: APP_API_URL + "/admin/frame_sizes",
  SHOW_FRAME_SIZES: APP_API_URL + "/admin/frame_sizes",
  DELETE_FRAME_SIZES: APP_API_URL + "/admin/frame_sizes",

  //Gearbox
  LIST_GEARBOX: APP_API_URL + "/admin/gearboxes",
  ADD_GEARBOX: APP_API_URL + "/admin/gearboxes",
  UPDATE_GEARBOX: APP_API_URL + "/admin/gearboxes",
  SHOW_GEARBOX: APP_API_URL + "/admin/gearboxes",
  DELETE_GEARBOX: APP_API_URL + "/admin/gearboxes",

  //Tire Size
  LIST_TIRE_SIZE: APP_API_URL + "/admin/tire_sizes",
  ADD_TIRE_SIZE: APP_API_URL + "/admin/tire_sizes",
  UPDATE_TIRE_SIZE: APP_API_URL + "/admin/tire_sizes",
  SHOW_TIRE_SIZE: APP_API_URL + "/admin/tire_sizes",
  DELETE_TIRE_SIZE: APP_API_URL + "/admin/tire_sizes",

  //Tire Type
  LIST_TIRE_TYPE: APP_API_URL + "/admin/tire_types",
  ADD_TIRE_TYPE: APP_API_URL + "/admin/tire_types",
  UPDATE_TIRE_TYPE: APP_API_URL + "/admin/tire_types",
  SHOW_TIRE_TYPE: APP_API_URL + "/admin/tire_types",
  DELETE_TIRE_TYPE: APP_API_URL + "/admin/tire_types",

  //Category Equipment
  LIST_CATEGORY_EQUIPMENT: APP_API_URL + "/admin/category_equipments",
  ADD_CATEGORY_EQUIPMENT: APP_API_URL + "/admin/category_equipments",
  UPDATE_CATEGORY_EQUIPMENT: APP_API_URL + "/admin/category_equipments",
  SHOW_CATEGORY_EQUIPMENT: APP_API_URL + "/admin/category_equipments",
  DELETE_CATEGORY_EQUIPMENT: APP_API_URL + "/admin/category_equipments",

  //Expertise
  LIST_EXPERTISE: APP_API_URL + "/admin/expertise_sections",
  UPDATE_EXPERTISE: APP_API_URL + "/admin/expertise_sections",
  SHOW_EXPERTISE: APP_API_URL + "/admin/expertise_sections",

  //Status
  LIST_STATUS: APP_API_URL + "/admin/statuses",
  UPDATE_STATUS: APP_API_URL + "/admin/statuses",
  SHOW_STATUS: APP_API_URL + "/admin/statuses",

  //Equipment
  LIST_EQUIPMENT: APP_API_URL + "/admin/equipment_vehicles",
  ADD_EQUIPMENT: APP_API_URL + "/admin/equipment_vehicles",
  UPDATE_EQUIPMENT: APP_API_URL + "/admin/equipment_vehicles",
  SHOW_EQUIPMENT: APP_API_URL + "/admin/equipment_vehicles",
  DELETE_EQUIPMENT: APP_API_URL + "/admin/equipment_vehicles",
  CATEGORY_EQUIPMENT_ID: APP_API_URL + "/admin/category_equipments",
  EQUIPMENT_COLOR_ICON: APP_API_URL + "/admin/equipment_vehicles",
  UPDATE_EQUIPMENT_COLOR_ICON: APP_API_URL + "/admin/equipment_vehicles",
  EQUIPMENT_GREY_ICON: APP_API_URL + "/admin/equipment_vehicles",
  UPDATE_EQUIPMENT_GREY_ICON: APP_API_URL + "/admin/equipment_vehicles",

  //Brands
  LIST_BRANDS: APP_API_URL + "/admin/brands",
  ADD_BRANDS: APP_API_URL + "/admin/brands",
  UPDATE_BRANDS: APP_API_URL + "/admin/brands",
  SHOW_BRANDS: APP_API_URL + "/admin/brands",
  DELETE_BRANDS: APP_API_URL + "/admin/brands",

  //Model
  LIST_MODELS: APP_API_URL + "/admin/model_cars",
  ADD_MODELS: APP_API_URL + "/admin/model_cars",
  UPDATE_MODELS: APP_API_URL + "/admin/model_cars",
  SHOW_MODELS: APP_API_URL + "/admin/model_cars",
  DELETE_MODELS: APP_API_URL + "/admin/model_cars",

  //Model Items
  LIST_MODEL_ITEMS: APP_API_URL + "/admin/model_car_items",
  ADD_MODEL_ITEMS: APP_API_URL + "/admin/model_car_items",
  UPDATE_MODEL_ITEMS: APP_API_URL + "/admin/model_car_items",
  SHOW_MODEL_ITEMS: APP_API_URL + "/admin/model_car_items",
  DELETE_MODEL_ITEMS: APP_API_URL + "/admin/model_car_items",

  //Version cars
  LIST_VERSION_CARS: APP_API_URL + "/admin/version_cars",
  ADD_VERSION_CARS: APP_API_URL + "/admin/version_cars",
  UPDATE_VERSION_CARS: APP_API_URL + "/admin/version_cars",
  SHOW_VERSION_CARS: APP_API_URL + "/admin/version_cars",
  DELETE_VERSION_CARS: APP_API_URL + "/admin/version_cars",
  MODEL_CAR_LIST_ID: APP_API_URL + "/admin/model_cars",
  FUEL_ID: APP_API_URL + "/admin/fuels",
  DRIVING_WHEEL_ID: APP_API_URL + "/admin/driving_wheels",
  BODY_WHEEL_ID: APP_API_URL + "/admin/body_vehicles",
  MODEL_CAR_ITEM_ID: APP_API_URL + "/admin/model_cars",
  GEAR_BOX_ITEM_ID: APP_API_URL + "/admin/gearboxes",
  FRONT_TIRE_ITEM_ID: APP_API_URL + "/vehicles/tires",
  BATTERY_TYPE_VALUE: APP_API_URL + "/admin/battery_types",
  EURO_STANDARD: APP_API_URL + "/admin/euro_standards",

  //Product Types
  LIST_PRODUCT_TYPES: APP_API_URL + "/admin/product_types",
  ADD_PRODUCT_TYPES: APP_API_URL + "/admin/product_types",
  UPDATE_PRODUCT_TYPES: APP_API_URL + "/admin/product_types",
  SHOW_PRODUCT_TYPES: APP_API_URL + "/admin/product_types",

  //Product Category
  LIST_PRODUCT_CATEGORY: APP_API_URL + "/admin/product_category",
  ADD_PRODUCT_CATEGORY: APP_API_URL + "/admin/product_category",
  UPDATE_PRODUCT_CATEGORY: APP_API_URL + "/admin/product_category",
  DELETE_PRODUCT_CATEGORY: APP_API_URL + "/admin/product_category",

  //Suggestion Texts
  LIST_SUGGESTION_TEXT: APP_API_URL + "/admin/suggestion_texts",
  ADD_SUGGESTION_TEXT: APP_API_URL + "/admin/suggestion_texts",
  SHOW_SUGGESTION_TEXT: APP_API_URL + "/admin/suggestion_texts",
  UPDATE_SUGGESTION_TEXT: APP_API_URL + "/admin/suggestion_texts",
  DELETE_SUGGESTION_TEXT: APP_API_URL + "/admin/suggestion_texts",

  //Slides
  ADD_SLIDES: APP_API_URL + "/admin/sliders",
  GET_SLIDES: APP_API_URL + "/admin/sliders",
  DELETE_SLIDES: APP_API_URL + "/admin/sliders",
  TOGGLE_SLIDES: APP_API_URL + "/admin/sliders",
  SHOW_SLIDES: APP_API_URL + "/admin/sliders",
  UPDATE_SLIDES: APP_API_URL + "/admin/sliders",
  SHOW_HOME_SLIDES: APP_API_URL + "/sliders_new",

  //Notification
  GET_ALL_NOTIFICATION: APP_API_URL + "/notifications",
  DELETE_NOTIFICATION: APP_API_URL + "/notifications",
  MARK_AS_SINGLE_READ: APP_API_URL + "/notifications",
  MARK_AS_ALL_READ: APP_API_URL + "/notifications",
  NOTIFICATION_COUNT: APP_API_URL + "/notifications/count",
  LAST_OPENED_NOTIFICATION: APP_API_URL + "/notifications",

  //Classic Ads
  LIST_CLASSIC_ADS: APP_API_URL + "/admin/auctions/classic_ads",
  VALIDATE_CLASSIC_AD: APP_API_URL + "/admin/auctions/classic_ads",

  //Bids List
  LIST_BIDS: APP_API_URL + "/admin/auctions/bids",
  DELETE_BIDS: APP_API_URL + "/admin/auctions/delete_bids",
  VALIDATE_BID: APP_API_URL + "/admin/auctions/bids",

  //Services
  LIST_SERVICES: APP_API_URL + "/admin/services",
  ADD_SERVICES: APP_API_URL + "/admin/services",
  SHOW_SERVICES: APP_API_URL + "/admin/services",
  DELETE_SERVICES: APP_API_URL + "/admin/services",
  UPDATE_SERVICES: APP_API_URL + "/admin/services",

  //Euro Standards
  LIST_EURO_STANDARDS: APP_API_URL + "/admin/euro_standards",
  ADD_EURO_STANDARDS: APP_API_URL + "/admin/euro_standards",
  SHOW_EURO_STANDARDS: APP_API_URL + "/admin/euro_standards",
  DELETE_EURO_STANDARDS: APP_API_URL + "/admin/euro_standards",
  UPDATE_EURO_STANDARDS: APP_API_URL + "/admin/euro_standards",

  // Logs
  VIEW_ADMIN_LOGS: APP_API_URL + "/admin/logs",
  DOWNLOAD_ADMIN_LOGS: APP_API_URL + "/admin/logs",

  //Legal Documents
  LIST_LEGAL_DOCUMENTS: APP_API_URL + "/admin/legal_documents",
  ADD_LEGAL_DOCUMENTS: APP_API_URL + "/admin/legal_documents",
  SHOW_LEGAL_DOCUMENTS: APP_API_URL + "/admin/legal_documents",
  DELETE_LEGAL_DOCUMENTS: APP_API_URL + "/admin/legal_documents",
  UPDATE_LEGAL_DOCUMENTS: APP_API_URL + "/admin/legal_documents",
  PUBLISH_LEGAL_DOCUMENTS: APP_API_URL + "/admin/legal_documents/publish",
  DUPLICATE_LEGAL_DOCUMENTS: APP_API_URL + "/admin/legal_documents",

  //FAQs
  LIST_FAQS: APP_API_URL + "/admin/faqs",
  ADD_FAQS: APP_API_URL + "/admin/faqs",
  SHOW_FAQS: APP_API_URL + "/admin/faqs",
  DELETE_FAQS: APP_API_URL + "/admin/faqs",
  UPDATE_FAQS: APP_API_URL + "/admin/faqs",

  // Partners
  LIST_PARTNERS: APP_API_URL + "/admin/partners",
  SHOW_PARTNERS: APP_API_URL + "/admin/partners",

  // Contacts
  LIST_CONTACT: APP_API_URL + "/admin/contact_us",
  LIST_SUBJECT: APP_API_URL + "/admin/subjects",
  //Partners CRUD
  LIST_PARTNER_OPTIONS: APP_API_URL + "/admin/partner_options",
  ADD_PARTNER_OPTIONS: APP_API_URL + "/admin/partner_options",
  SHOW_PARTNER_OPTIONS: APP_API_URL + "/admin/partner_options",
  DELETE_PARTNER_OPTIONS: APP_API_URL + "/admin/partner_options",
  UPDATE_PARTNER_OPTIONS: APP_API_URL + "/admin/partner_options",

  // Contact options
  CONTACT_OPTIONS: APP_API_URL + "/admin/subjects",

  //Flagged Review
  LIST_FLAGGED_REVIEW: APP_API_URL + "/admin/flagged_ratings",
  Delete_Flagged_REVIEW: APP_API_URL + "/admin/flagged_ratings",
  NO_ACTION_FLAGGED_REVIEW: APP_API_URL + "/admin/flagged_ratings",
  LIST_OF_REPORTED_BY: APP_API_URL + "/admin/reportedBy",
  LIST_OF_REVIEWED_BY: APP_API_URL + "/admin/reviewedBy",

  //Data Providers
  DATA_PROVIDERS: APP_API_URL + "/admin/imported_dealers",
  ASSIGN_DATA: APP_API_URL + "/chats/users",
  ASSIGN: APP_API_URL + "/admin/imported_dealers",

  // Data Providers Errors
  PROVIDERS_ERRORS: APP_API_URL + "/admin/imported_vehicles",

  // Advertisers
  ADVERTISERS_DATA: APP_API_URL + "/admin/advertisers",

  // volkswagen
  AUCTION_LIST: APP_API_URL + "/admin/auctions_list",
  UPLOAD_FILE: APP_API_URL + "/admin/upload_file",

  REMOVE_TOKEN: APP_API_URL + "/users/remove_device_token",
  ADD_TOKEN: APP_API_URL + "/users/add_device_token",

  //Messanger
  MESSANGER: APP_API_URL + "/admin/chats",

  //forum tags
  FORUM_TAGS: APP_API_URL + "/admin/forum_tags",

  //General settings
  GENERAL_SETTINGS: APP_API_URL + "/admin/site_settings",

  //Hotdeals
  HOTDEALS: APP_API_URL + "/admin/hotdeals",
  HOTDEAL_EVENTS: APP_API_URL + "/admin/hotdeal-events",
  CREATE_HOTDEAL: APP_API_URL + "/admin/hotdeals",
  HOTDEAL_ADS_DETAIL: APP_API_URL + "/admin/hotdeals/view",
  HOTDEAL_ADS: APP_API_URL + "/admin/hotdeals/ads",
  HOTDEAL_CANCEL_RESERVATION:
    APP_API_URL + "/admin/hotdeals/cancel_reservation",

  //credit history
  ALL_CUSTOMER: APP_API_URL + "/customers",
  LIST_CREDIT_HISTORY: APP_API_URL + "/admin/credits",
  ADD_CREDIT_HISTORY: APP_API_URL + "/admin/credits",
  CREDIT_HISTORY_DETAILS: APP_API_URL + "/admin/credits/history",

  //forum flag comments
  FORUM_FLAGS: APP_API_URL + "/admin/forum_flags",
  FORUM_FLAGS_QUESTIONS: APP_API_URL + "/admin/forum_questions",
  FORUM_FLAGS_ANSWERS: APP_API_URL + "/admin/forum_answers",

  //deposit history
  DEPOSIT_HISTORY: APP_API_URL + "/admin/deposits_history",
  DEPOSIT_HISTORY_EXTRACT:
    APP_API_URL + "/admin/deposits_history/download_excel_file",
  CONFIRM_HOTDEAL_PAYMENT: APP_API_URL + "/admin/hotdeal_sold_confirm",

  // Ad visitors

  AD_VISITORS: APP_API_URL + "/admin/ad_vehicles/visitors",
};
