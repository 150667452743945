import React, { useEffect, useState } from "react";
import error from "../../../assets/Images/icons/alert-tri.svg";
import edit from "../../../assets/Images/image/edit.png";
import waste from "../../../assets/Images/image/Waste.png";
import { TableWrapper } from "../style/table";
import { Link, NavLink, useLocation, useSearchParams } from "react-router-dom";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "../../../assets/SharedComponents/Pagination";
import SideTranslate from "../components/SideTranslate";
import searchIcon from "../../../assets/Images/icons/faSearchLite.svg";
import MainLayout from "../../../assets/Layout/MainLayout";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { getName } from "../../../core/utils/getName/getName";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount"; 
import ProductTypeFilter from "../../../assets/SharedComponents/Filters/ProductTypeFilter";

const TyreSize = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [tyreSize, setTyreSize] = useState([]); 
  const [dataDeleted, _dataDeleted] = useState(false);

  const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
  const [totalPage, _totalPage] = useState(0);
  const [total, _total] = useState(0);  

  const [productValue, _productValue] = useState(query.get('product_type_id')!==null?query.get('product_type_id'):"");
  const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");
  const [widthValue, _widthValue] = useState(query.get('width')!==null?query.get('width'):"");
  const [heightValue, _heightValue] = useState(query.get('height')!==null?query.get('height'):"");
  const [diameterValue, _diameterValue] = useState(query.get('diameter')!==null?query.get('diameter'):"");

  const [filters, _filters] = useState(false);
  const [loading, _loading] = useState(false);

  const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let vehicleManagementAccess = userAccess.find(o => o.slug === "vehicle_management")

  const getTyreSize = (width,height,diameter) => {
    _loading(true);
    API.get(APP_URLS.LIST_TIRE_SIZE + `?page=${page}&per_page=${countValue}&filter[product_type_id]=${productValue}&filter[width]=${width?width:""}&filter[height]=${height?height:""}&filter[diameter]=${diameter?diameter:""}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          setTyreSize(resp?.data?.data);
          _total(resp.data.total);
          _totalPage(resp?.data?.last_page);
          _loading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined && resp.data.data !== null) {
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    getTyreSize(widthValue,heightValue,diameterValue)
    _dataDeleted(false);
  }, [dataDeleted, page, productValue, countValue]);

  const removeTyreSize = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_TIRE_SIZE + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("Tire Size has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const clearFilter = () => {
    _filters(true);
    _productValue("");
    _widthValue("");
    _heightValue("");
    _diameterValue("");
    _countValue('');
    if (productValue !== "" || widthValue !== "" || heightValue !== "" || diameterValue !== "") {
      _totalPage(0)
      setPage(1);
    }
    if(productValue === "" &&( widthValue !== "" || heightValue !== "" || diameterValue !== "")){
      getTyreSize()
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if (searchValue !== ""&&searchValue!==countValue) {
      _totalPage(0)
      setPage(1);
    }
  };

  return (
      <TableWrapper>
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
            <h4 className="translation text-center mb-3">VEHICLES <br /> MANAGEMENT</h4>
            <SideTranslate />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-8 mt-2">
            <div className="headerContainer mb-3">
              <h1>TIRE SIZE</h1>
            </div>
            <div className="d-flex justify-content-between add-info">
              <div className="add-depertment d-flex justify-content-end mb-2">
                {vehicleManagementAccess.permissions.find((o) => o.slug === "add") ? (
                  <div>
                    <NavLink to="/vehicles-management/create-tire-size">
                      <button className="btn">+ Add</button>
                    </NavLink>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-end mb-2 selct-info align-items-center flex-wrap gap-2">
               <ProductTypeFilter handleChange={(e) => {
                    _productValue(e.target.value)
                    _totalPage(0)
                    setPage(1); 
                }} value={productValue}/>   
                <div className="d-flex searchBox tireSearchBox">
                    <input
                      type="number"
                      placeholder="Width"
                      value={widthValue}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          getTyreSize(widthValue,heightValue,diameterValue)
                        }
                      }}
                      onChange={(e)=>_widthValue(e.target.value)}
                    />
                    <span>/</span>
                    <input
                      type="number"
                      placeholder="Height"
                      value={heightValue}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          getTyreSize(widthValue,heightValue,diameterValue)
                        }
                      }}
                      onChange={(e)=>_heightValue(e.target.value)}
                    />
                    <span>/</span>
                    <input
                      type="number"
                      placeholder="Diameter"
                      value={diameterValue}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          getTyreSize(widthValue,heightValue,diameterValue)
                        }
                      }}
                      onChange={(e)=>_diameterValue(e.target.value)}
                    />
                  <img src={searchIcon} alt="search" onClick={()=>getTyreSize(widthValue,heightValue,diameterValue)}/>
                </div>
                <RecordCount
                  onSearchClick={(search) => handleSearchChange(_countValue, search)}
                  id="pageCount"
                  val={countValue}
                  filters={filters}
                  _filters={_filters} />
                <Tooltip title="Clear Filter" placement="bottom">
                  <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                </Tooltip>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
                <div className="justify-content-end mt-1 me-2">
                    <span className='text-start'>Total Records : <b>{total}</b></span>
                </div>
            </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th className="tableHead-1">ID</th>
                    <th className="tableHead-2">Width</th>
                    <th className="tableHead-3" style={{ minWidth: "90px" }}>
                      Height
                    </th>
                    <th className="tableHead-6" style={{ minWidth: "90px" }}>
                      Diameter
                    </th>
                    <th className="tableHead-4">Updated by</th>
                    <th className="tableHead-5">Created by</th>
                    <th className="tableHead-7">Product Type</th>
                    {vehicleManagementAccess.permissions.find((o) => o.slug === "update") || vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? <th className="tableHead-9">Action</th> : ""}
                  </tr>
                </thead>
                {
                  loading ?
                    <div className='d-flex justify-content-center three-dot'>
                      <ThreeDots
                        height="150"
                        width="150"
                        radius="9"
                        color="#637df4"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                    :
                    <tbody>
                      {tyreSize.length > 0 ? (
                        tyreSize?.map((item, index) => {
                          let createdBy = getName(item.create_activity_log, item.update_activity_log);
                          let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                          return (
                            <tr className="table-row" key={index}>
                              <td className="tableHead-1">{item.id}</td>
                              <td className="tableHead-2">{item.width?item.width:"-"}</td>
                              <td className="tableHead-3" style={{ minWidth: "90px" }}>
                                {item.height?item.height:'-'}
                              </td>
                              <th className="tableHead-6" style={{ minWidth: "90px" }}>
                                {item.diameter?item.diameter:"-"}
                              </th>
                              <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                              <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                              <td className="tableHead-7">{item?.product_category_type?.product_type?.item_name.en}</td>
                              {vehicleManagementAccess.permissions.find((o) => o.slug === "update") || vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? (
                                <td className="tableHead-9">
                                  {vehicleManagementAccess.permissions.find((o) => o.slug === "update") ? (
                                    <NavLink to={`/vehicles-management/edit-tire-size/${item.id}?`+(productValue!==""?"product_type_id="+productValue+"&":"")+(widthValue!==""?"width="+widthValue+"&":"")+(heightValue!==""?"height="+heightValue+"&":"")+(diameterValue!==""?"diameter="+diameterValue+"&":"")+(countValue!==""?"per_page="+countValue+"&":"")+(page!==1?"page="+page:"")}>
                                      <img src={edit} alt="" className="mx-2" role="button" />
                                    </NavLink>
                                  ) : (
                                    ""
                                  )}
                                  {vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? <img src={waste} alt="" role="button" onClick={() => removeTyreSize(item.id)} /> : ""}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <NoRecord />
                      )}
                    </tbody>
                }
              </table>
            </div>
            {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
          </div>
        </div>
      </TableWrapper>
  );
};

export default TyreSize;
