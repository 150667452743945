import React, { useEffect, useState } from "react";
import MainLayout from "../../../../assets/Layout/MainLayout";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import edit from "../../../../assets/Images/image/edit.png";
import waste from "../../../../assets/Images/image/Waste.png";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import { toast } from "react-toastify";
import Pagination from "../../../../assets/SharedComponents/Pagination";
import search from "../../../../assets/Images/icons/faSearchLite.svg";
import SideTranslate from "../../components/SideTranslate";
import NoRecord from "../../../../assets/SharedComponents/Table/NoRecord";
import { TableWrapper } from "../../style/table";
import SearchKey from "../../../../assets/SharedComponents/Search/SearchKey";
import { getName } from "../../../../core/utils/getName/getName";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../../assets/SharedComponents/RecordCount/RecordCount";  
import ProductTypeFilter from "../../../../assets/SharedComponents/Filters/ProductTypeFilter";
import BrandFilter from "../../../../assets/SharedComponents/Filters/BrandFilter";
import ModelFilter from "../../../../assets/SharedComponents/Filters/ModelFilter";

const Modals = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([]);
  const [countries, setcountries] = useState(JSON.parse(localStorage.getItem("countries")));
  const [dataDeleted, _dataDeleted] = useState(false);

  const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0); 
  const [productValue, _productValue] = useState(query.get('product_type')!==null?query.get('product_type'):""); 
  const [brandName, _brandName] = useState(query.get('brand_id')!==null?query.get('brand_id'):""); 
  const [modelName, _modelName] = useState(query.get('model_id')!==null?query.get('model_id'):"");
  
  const [searchkeyword, _searchkeyword] = useState(query.get('model_name')!==null?query.get('model_name'):"");
  const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");

  const [filters, _filters] = useState(false);
  const [loading, _loading] = useState(false);

  const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let vehicleManagementAccess = userAccess.find(o => o.slug === "vehicle_management")


  let navigate = useNavigate();

  useEffect(() => {
    getModels();
    _dataDeleted(false);
  }, [dataDeleted, page, searchkeyword, productValue,countValue,brandName,modelName]);
 

  const getModels = () => {
    _loading(true);
    API.get(APP_URLS.LIST_MODELS + `?page=${page}&per_page=${countValue}&filter[model_name]=${searchkeyword}&filter[product_type]=${productValue}&filter[brand_id]=${brandName}&filter[model_id]=${modelName}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map((error, index) => (error_message = resp.data.errors[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

 

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_MODELS + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("Model has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword)){
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue,'');
    handleSearchChange(_searchkeyword,'');
    _productValue("");
    _brandName("")
    _modelName("")
    if (productValue !== "" || searchkeyword !== "") {
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  const setChangeValue = (func, item) => {
    func(item.split("_")[0]);
    if((func===_brandName&&item!==brandName)||(func===_modelName&&item!==modelName)){
      if (item !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  }

  return (
    <>
      <TableWrapper>
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
            <h4 className="translation text-center mb-3">VEHICLES <br/> MANAGEMENT</h4>
            <SideTranslate />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-8 mt-2">
            <div className="headerContainer mb-3">
              <h1>MODELS</h1>
            </div>
            <div className="d-flex justify-content-between add-info">
              <div className="add-depertment d-flex justify-content-end mb-2">
                {vehicleManagementAccess.permissions.find((o) => o.slug === "add") ? (
                  <div>
                    <NavLink to="/vehicles-management/create-vehicle-model">
                      <button className="btn">+ Add</button>
                    </NavLink>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex justify-content-end mb-2 flex-wrap align-items-center gap-2">
              <ProductTypeFilter handleChange={(e) => {
                    _productValue(e.target.value)
                    _totalPage(0)
                    setPage(1);
                    _brandName('') 
                }} value={productValue}/>  
              <BrandFilter _filters={_filters} filters={filters} brand={brandName} setBrand={(val)=>setChangeValue(_brandName, val)} productValue={productValue} />  
              <ModelFilter _filters={_filters} filters={filters} brandID={brandName} modelID={modelName} setChangeValue={(val)=>setChangeValue(_modelName,val)} productValue={productValue} />    
              <SearchKey onSearchClick={(search) => handleSearchChange(_searchkeyword,search)}
                filters={filters}
                _filters={_filters}
                val={searchkeyword}
                placeholder="Search by name"
              />
              <RecordCount
                onSearchClick={(search) => handleSearchChange(_countValue, search)}
                id="pageCount"
                val={countValue}
                filters={filters}
                _filters={_filters} />
              <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
              </Tooltip>
            </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
                <div className="justify-content-end mt-1 me-2">
                    <span className='text-start'>Total Records : <b>{total}</b></span>
                </div>
            </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th>Brand ID</th>
                    <th>Brand Name</th>
                    <th>Model ID</th>
                    <th>Model Name</th>
                    <th>Product Type</th>
                    <th>Updated by</th>
                    <th>Created by</th>
                    {vehicleManagementAccess.permissions.find((o) => o.slug === "update") || vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? <th>Action</th> : ""}
                  </tr>
                </thead>
                {loading ?
                  <div className='d-flex justify-content-center three-dot'>
                    <ThreeDots
                      height="150"
                      width="150"
                      radius="9"
                      color="#637df4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                  :
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => {
                        let createdBy = getName(item.create_activity_log, item.update_activity_log);
                        let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                        return (
                          <tr className="table-row" key={index}>
                            <td>{item.brand_id}</td>
                            <td>{item.brand_car!==null&&item.brand_car!==undefined?item.brand_car.name:'-'}</td>
                            <td>{item.id}</td>
                            <td>{item.model_name}</td>
                            <td>{item.brand_car!==null&&item.brand_car!==undefined?item.brand_car.product_type.item_name.en:'-'}</td>
                            <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                            <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                            {vehicleManagementAccess.permissions.find((o) => o.slug === "update") || vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? (
                              <td className="action">
                                {vehicleManagementAccess.permissions.find((o) => o.slug === "update") ? (
                                  <button className=" me-2" onClick={() => navigate(`/vehicles-management/edit-vehicle-model/${item.id}?`+(productValue!==""?"product_type="+productValue+"&":"")+(brandName!==""?"brand_id="+brandName+"&":"")+(modelName!==""?"model_id="+modelName+"&":"")+(searchkeyword!==""?"model_name="+searchkeyword+"&":"")+(countValue!==""?"per_page="+countValue+"&":"")+(page!==1?"page="+page:""))}>
                                    <img src={edit} alt="f" />
                                  </button>
                                ) : (
                                  ""
                                )}
                                {vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? <img src={waste} alt="" role="button" onClick={() => removeType(item.id)} /> : ""}
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecord />
                    )}
                  </tbody>
                }
              </table>
            </div>
            {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
          </div>
        </div>
      </TableWrapper>
    </>
  );
};

export default Modals;
