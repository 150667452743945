import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Input, Typography } from '@mui/material';

const countries = localStorage.getItem("groupedCountries")
    ? JSON.parse(localStorage.getItem("groupedCountries"))
    : [];

export const CountryDropDown = (props) => {
    const [defSet, setDefSet] = useState(true);
    const [countriesShow, setCountriesShow] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState({});
    const [searchedText, setSearchedText] = useState('');
    const [filteredCountries, setFilteredCountries] = useState(countries || {});

    // Select Country Handler
    const selectCountry = (data) => {
        props.countryhandler && props.countryhandler(data);
        setSelectedCountry(data);
        setCountriesShow(false);

        // Reset address field in GoogleMapApi
        props.resetAddress && props.resetAddress();
    };

    // Function to set default country
    const defaultSet = (id) => {
        if (defSet && Object.keys(countries).length) {
            let def;

            // Loop through the continents and find the country by id
            Object.keys(countries).forEach(continent => {
                const foundCountry = countries[continent].find(x => x.id === id);
                if (foundCountry) {
                    def = foundCountry;
                }
            });

            // If a matching country is found, set it as the selected country
            if (def) {
                let data = {
                    id: def.id,
                    name: def.country_name,
                    flag: def.flag_thumbnail,
                    code: def.prefix_call,
                    fullname: def.fullname
                };
                setSelectedCountry(data);
                setDefSet(false); // Ensure this runs only once
            }
        } else {
            setSelectedCountry(selectedCountry); // If no country is found, keep the current selection
        }
    };

    // useEffect to run the defaultSet function when the component mounts or countries chnage
    useEffect(() => {
        if (props.default) {
            defSet && defaultSet(props.default);
        }
    }, [countries, props.default]);

    // Search Filter Functionality
    useEffect(() => {
        if (searchedText.trim() !== "") {
            const newFilteredCountries = {};

            Object.keys(countries).forEach(continent => {
                const filteredByContinent = countries[continent].filter(country =>
                    country.fullname.toLowerCase().includes(searchedText.toLowerCase())
                );
                if (filteredByContinent.length > 0) {
                    newFilteredCountries[continent] = filteredByContinent;
                }
            });
            setFilteredCountries(newFilteredCountries);
        } else {
            setFilteredCountries(countries); // Reset if no search text
        }
    }, [searchedText, countries]);

    //handle search for search country
    const handleSearch = (e) => {
        setSearchedText(e.target.value);
    };

    // Handle showing/hiding the popup
    const toggleCountryList = () => {
        setCountriesShow(prev => !prev); // Toggle the visibility
        if (!countriesShow) {
            setSearchedText(''); // Clear the search text when the popup is closed
        }
    };

    return (
        <Wrapper className={`${props.classes}`}>
            <Box>
                {selectedCountry && (
                    <Box sx={{ display: "flex", flexDirection: "row" }} onClick={() => !props.disable && toggleCountryList()}>
                        <Box className={"add_country_flag"}
                            style={props.border}
                            sx={{
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "8px",
                                height: '55px',
                                '._code': { marginLeft: 2 },
                                '.thumbnailImage': { width: "50px", height: "40px", marginRight: 1 }
                            }}
                        >
                            <img src={selectedCountry.flag} alt={'flag'} style={props.thumbnailImage} className={"thumbnailImage"} />
                            <span style={props.style}><i class="fa-duotone fa-solid fa-chevron-down"></i></span>
                            <span style={props.style1} className='countrycode'>{selectedCountry.code}</span>
                        </Box>
                    </Box>
                )}
                {countriesShow && (
                    <Box sx={{ padding: 0 }} className='countryModal'>
                        <Input
                            type='text'
                            placeholder='Search'
                            value={searchedText}
                            onChange={handleSearch}
                            sx={{ height: 50, width: '100%', maxWidth: '100%' }}
                        />
                        <ul>
                            {Object.keys(filteredCountries).length > 0 ? (
                                Object.keys(filteredCountries).map((continent, index) => (
                                    <React.Fragment key={continent}>
                                        <Typography
                                            sx={{ fontSize: "20px", fontWeight: '600', mb: 2 }}
                                        >
                                            {filteredCountries[continent].length ? continent : null}
                                        </Typography>
                                        {filteredCountries[continent].map(country => (
                                            <li
                                                key={country.id}
                                                onClick={() => selectCountry({
                                                    id: country.id,
                                                    name: country.country_name,
                                                    flag: country.flag_thumbnail,
                                                    code: country.prefix_call,
                                                    fullname: country.fullname
                                                })}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        minWidth: "235px"
                                                    }}
                                                >
                                                    <Box className="flag1">
                                                        <img src={country.flag_thumbnail} alt={country.country_name} />
                                                        <span className="_fullname">{country.fullname}</span>
                                                    </Box>
                                                    <Box className='_code'>
                                                        <span>{country.prefix_call}</span>
                                                    </Box>
                                                </Box>
                                            </li>
                                        ))}
                                    </React.Fragment>
                                ))
                            ) : (
                                <ul>
                                    <li>
                                        <Box
                                            sx={{ display: "flex", justifyContent: "space-between", minWidth: "235px" }}
                                        >
                                            Country not found
                                        </Box>
                                    </li>
                                </ul>
                            )}
                        </ul>
                    </Box>
                )}
            </Box>
        </Wrapper>
    );
};

const Wrapper = styled(Box)`
    position: relative;
    display: flex;
    .countryModal {
        position: absolute;
        top: 40px;
        left: 0;
        padding: 10px 10px;
        background: #fff;
        box-shadow: 0 0 5px #ccc;
        max-height: 220px;
        z-index: 3;
        min-width: 450px;
        min-height: 285px;
        ul{
        padding-left: 0px;
        overflow:auto;
        max-height:220px;
        }
        li {
            align-items: center;
            border-bottom: 1px solid #ccc;
            padding: 10px 10px;
            list-style: none;
            .flag1 {
                display: flex;
                overflow: hidden;
                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                }
                ._fullname {
                    white-space: nowrap;
                }
            }
            ._code {
                color: #808080;
                font-size: 12px;
            }
        }
    }
`;
