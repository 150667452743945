import { Box, Button, TextField, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { CountryDropDown } from '../../../../core/utils/countryDropdown/CountryDropdown'
import GoogleMapApi from '../../../../core/utils/googleMapApi'
import MapView from '../../../../core/utils/googleMapApi/MapView'

const MapAddress = (props) => {
  const [formatted_address, _formatted_address] = useState()
  const [country, _country] = useState()
  const [streetNumber, _streetNumber] = useState('')
  const [route, _route] = useState('')
  const [mapAddressError, _mapAddressError] = useState({});
  const [lat, _lat] = useState()
  const [long, _long] = useState()
  const [zip, _zip] = useState('')
  const [locality, _locality] = useState('')
  const [place_id, _place_id] = useState('')
  const [administrative_area_level_1, _administrative_area_level_1] = useState()
  const [administrative_area_level_2, _administrative_area_level_2] = useState()
  const [showConfirmField, _showConfirmField] = useState(false);
  const [resetAddressTrigger, setResetAddressTrigger] = useState(false);
  const [flag, _flag] = useState(false);
  const addClass = useRef(null)
  const addClass2 = useRef(null)


  const googleMapDataFetch = data => {
    if (data) {
      _flag(true)
      _formatted_address(data.formatted_address)
      _streetNumber(data.stNo);
      _route(data.road ? data.road : '');
      _lat(data.lat);
      _long(data.lng);
      _zip(data.postalCode);
      _locality(data.locality);
      _place_id(data.place_id);
      _country(data.country)
      _administrative_area_level_1(data.administrative_area_level_1);
      _administrative_area_level_2(data.administrative_area_level_2);
      if (data.formatted_address && flag) {
        setTimeout(() => {
          addClass.current.classList.add('animate__fadeOutDown')
        }, 1000)
        setTimeout(() => {
          _showConfirmField(true)
          _flag(false);
        }, 1500)
      }
      if (!route || !zip) {
        // _addressError(true)
      }
    }
  }

  useEffect(() => {
    if (props.formattedAddress) {
      _formatted_address(props.formattedAddress.trim())
    }
  }, [props])

  const hideMap = async () => {
    _mapAddressError({
      routeError: route.trim() === "" ? 'This field is required' : '',
      zipError: zip.trim() === "" ? 'This field is required' : '',
      streetNumberError: streetNumber.trim() === "" ? 'This field is required' : '',
      localityError: locality.trim() === "" ? 'This field is required' : '',
    })
    if (zip.trim() === '' || route.trim() === '' || streetNumber.trim() === '' || locality.trim() === '') {
      return false;
    }
    const hasErrors = Object.values(mapAddressError).some(error => error !== "");
    props.getAddress({
      formatted_address: formatted_address,
      streetNumber: streetNumber,
      route: route,
      lat: lat,
      long: long,
      zip: zip,
      country: country,
      countryId: props.default,
      locality: locality,
      place_id: place_id,
      administrative_area_level_1: administrative_area_level_1,
      administrative_area_level_2: administrative_area_level_2
    })
    if (!hasErrors) {
      await props.handleSubmit();
    }
  }

  const getTargetedAdress = (address) => {
    // By using destructuring with defaults, you avoid repetitive ternary expressions like data.field ? _field(address.field) : _field(field).
    // Destructure the address object to extract fields
    const {
      formatted_address = formatted_address,
      streetNumber = streetNumber,
      route = route,
      lat = lat,
      long = long,
      zip = zip,
      country = country,
      locality = locality,
      administrative_area_level_1 = administrative_area_level_1,
      administrative_area_level_2 = administrative_area_level_2,
    } = address;

    // Update the state using the destructured values
    _formatted_address(formatted_address);
    _streetNumber(streetNumber);
    _route(route);
    _lat(lat);
    _long(long);
    _zip(zip);
    _country(country);
    _locality(locality);
    _administrative_area_level_1(administrative_area_level_1);
    _administrative_area_level_2(administrative_area_level_2);
  };

  //Reset all the address related fields
  const handleResetAddress = () => {
    setResetAddressTrigger(prev => !prev); // Toggle trigger to reset address
  };

  return (
    <div>
      <MapWrapper>
        <div className="mapWrapper">
          <CountryAddressWrapper className="map-address-bar animate__animated pointer" ref={addClass}>
            <div className="parentWrapper">
              <InputWrapper className="phoneInnerWrapper" style={{
                border: "none !important"
              }}>
                <CountryDropDown
                  border={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                    borderLeft: "1px solid #ccc",
                  }}
                  thumbnailImage={{ margin: 0 }}
                  // style={{ display: 'none' }}
                  style={{ marginLeft: '3px' }}
                  style1={{ display: 'none' }}
                  classes={'s-hide-code s-hide-fullname'}
                  default={props.default}
                  countryhandler={props.countryhandler}
                  disable={false}
                  resetAddress={handleResetAddress}
                />
                <GoogleMapApi
                  googleMapData={googleMapDataFetch}
                  setFormattedAddress={formatted_address}
                  countryId={props.default}
                  resetAddress={resetAddressTrigger}
                />
              </InputWrapper>
            </div>
          </CountryAddressWrapper>
          {showConfirmField && <div className='animate__animated' ref={addClass2}>
            <div className=''>
              <Box
                component="form"
                sx={{
                  '& > :not(style)': {
                    my: 1,
                    width: '100%'
                  },
                }}
                noValidate
                autoComplete="off"
              >   <Box sx={{ width: '100%' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={streetNumber}
                    onChange={(e) => {
                      _streetNumber(e.target.value)
                      _mapAddressError({ ...mapAddressError, streetNumberError: '' });
                    }}
                    id="outlined-basic"
                    label={`Street Number`}
                    variant="outlined" />
                  {mapAddressError.streetNumberError &&
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontStyle: 'Roboto',
                        fontSize: '12px',
                        color: 'red'
                      }}
                    >{mapAddressError.streetNumberError}</Typography>
                  }
                </Box>
                <Box sx={{ width: '100%' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={route}
                    onChange={(e) => {
                      _route(e.target.value)
                      _mapAddressError({ ...mapAddressError, routeError: '' });
                    }}
                    id="outlined-basic"
                    label={`Street`}
                    variant="outlined" />
                  {mapAddressError.routeError &&
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontStyle: 'Roboto',
                        fontSize: '12px',
                        color: 'red'
                      }}
                    >{mapAddressError.routeError}</Typography>
                  }
                </Box>
                <Box sx={{ width: '100%' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={zip}
                    onChange={(e) => {
                      _zip(e.target.value)
                      _mapAddressError({ ...mapAddressError, zipError: '' })
                    }}
                    id="outlined-basic"
                    label={`Postal Code`}
                    variant="outlined" />
                  {mapAddressError.zipError &&
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontStyle: 'Roboto',
                        fontSize: '12px',
                        color: 'red'
                      }}
                    >{mapAddressError.zipError}</Typography>
                  }
                </Box>
                <Box sx={{ width: '100%' }}>
                  <TextField
                    sx={{ width: '100%' }}
                    value={locality}
                    onChange={(e) => {
                      _locality(e.target.value);
                      _mapAddressError({ ...mapAddressError, localityError: '' })
                    }}
                    id="outlined-basic"
                    label={`Locality`}
                    variant="outlined" />
                  {mapAddressError.localityError &&
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontStyle: 'Roboto',
                        fontSize: '12px',
                        color: 'red'
                      }}
                    >{mapAddressError.localityError}</Typography>
                  }
                </Box>
              </Box>
              <div className='continue-btn'>
                <Button className='text-secondary border border-secondary rounded-3'
                  onClick={() => { props.handleClose() }}>{`Cancel`}
                </Button>
                <button className='btn text-align-center'
                  onClick={() => { hideMap() }}>{`Save Changes`}
                </button>
              </div>
            </div>
            <MapView lat={lat} long={long} getTargetedAddress={getTargetedAdress} />
          </div>}
        </div>
      </MapWrapper>
    </div>
  )
}

export default MapAddress

const SectionHeaderWrapper = styled.div`
 min-height: 60px;
  text-align: center;
  position: relative;
  a {
    color: #000;
  }
  .backButton,  .toggleTrigger{
    left: 10px;
    position: absolute;
    top: -2px;
    background: transparent;
    padding: 0px;
    border: 0;
    svg {
      font-size: 18px;
    }
  }
  .toggleTrigger{
    svg{
      width: 22px;
    }
  }
  .right-Bar {
    position: absolute;
    right: 6px;
    top: 20px;
    svg {
      font-size: 20px;
    }
  }
  .left-bar{
    left: 0;
    top: 32px;
    position: absolute;
    svg {
      font-size: 20px;
    }
  }
  .SectionHeaderContainer{
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

const MapWrapper = styled.div`
.cancel-btn{
    background: lightgrey;
    border: none;
    padding: 6px;
    min-width: 100px;
    border-radius: 5px;
}
 .continue-btn{
    display: flex;
    align-items: center;
    justify-content: end;
    gap:10px;
    margin-bottom: 10px;
  }
  background: #fff;
  /* position: absolute; */
  height: 100%;
  width:100%;
  /* top:0; */
  /* left: 0; */
  /* z-index: 2; */
  padding: 0 0px 15px;
  /* overflow-y:auto; */
 
  .address-box-error{
      padding:15px;
      background:#FFEBEB;
      color:#FF6372;
      font-size:13px;
      border-radius: 15px;
      margin-bottom:20px;
      p:last-child{
          margin-bottom:0;
      }
  }
  .map-container{
      height: 450px;
      position:relative;
      >div{
        position:relative;
        min-height:450px;
      }
  }
  .map-address-bar{
      .input-map{
        min-height: 38px;
          input{
            padding-left: 10px;
            min-height: 38px;
          }
      }
  }
  .mapWrapper{
      max-width:768px;
      margin:0 auto;
      padding:10px;
  }
  .address-form{
      .info-note{
        color:#FF6372;
        font-size:12px;
      }
      label{
          margin-left:12px;
          display:block;
          color:#FF6372;
          font-size:12px;
      }
      .parent{
        border-radius:18px;
      }
      input{
          min-height:38px;
          border:1px solid #ccc;
      }
      .single-feild{
        margin-bottom:20px;
        
        .input-value{
            width:100%;
            input{
                padding:0 15px;
                width:100%;
                border-radius:18px;
            }
        }
      }
      .two-feild{
        margin-bottom:20px;
          .input-value{
              &:first-of-type{
                label{
                    margin-left:12px;
                }
                max-width:150px;
                width:100%;
                input{
                    padding:0 15px;
                    width:100%;
                    border-radius: 18px 0px 0px 18px;

                  }
              }
              &:nth-of-type(2){
                  max-width:calc(100% - 130px);
                  width:100%;
                  input{
                    padding:0 15px;
                    width:100%;
                    border-radius: 0px 18px 18px 0px;
                  }
              }
          }
      }

  }
`;
export const CountryAddressWrapper = styled.div`
  position: relative;
  margin-bottom: 1.2rem;
  &.disable{
    cursor: not-allowed;
    .selectCountry{
      pointer-events: none;
    }
  }
  .error-box{
    bottom: 47px;
    max-width: 230px;
    right: 0;
    border-radius: 12px;
    font-size: 12px;
    padding: 18px 5px 5px;
    box-shadow: 0 0px 15px #f3f3f3;
    background: #fff;
    span{
      position: absolute;
      right: 9px;
      top: 3px;
      svg{
        width:9px
      }
    }
    &.bottom-position{
      bottom: unset;
      top: 50px;
    }
  }
  &.border-radius{
    .phoneInnerWrapper {
      border-radius: 4px;
    }
  }
  .phoneInnerWrapper {
    // border: 1px solid grey;
    height: 56px;
    display: flex;
    border-radius: 4px;
    .selectCountry{
      .flag{
        height: 100%;
        width: auto;
        border-radius: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:  103px;
        align-items: center;
        // border-right: 1px solid grey;
        img{
          width: 50px;
          height: 30px;
          /* border-radius: 100%; */
        }
      }
      .name,.fullname{
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 8px;
        white-space:nowrap;
      }
      .code{
        min-width: 50px;
        padding: 0 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      
    }
    input { 
      border: 0;
      background:transparent;
      width:100%;
      max-width:calc(100% - 125px);
    }
    button{
      position: absolute;
      right: 0;
      max-height: 56px;
      height: 100%;
      background: transparent;
      // border: 1px solid grey;
      border-top:0;
      border-bottom:0;
      border-right:0;
    }
  }
  ul{
    .country-name{
      display: none;
    }
  }
  .required{
    color: red;
    font-size: 11px;
    line-height: 14px;
    display: inline-block;
    text-align: justify;
  }

`;
export const InputWrapper = styled.div`
.input-map{
  input{
    max-width: 100%;
  }
}
`;