import React, { useEffect, useState } from "react";
import waste from "../../../assets/Images/image/Waste.png";
import close from "../../../assets/Images/icons/Cancel.svg";
import save from "../../../assets/Images/icons/Save.svg";
import edit from "../../../assets/Images/image/edit.png";
import { TableWrapper } from "../style/table";
import SideTranslate from "../components/SideTranslate";
import MainLayout from "../../../assets/Layout/MainLayout";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { confirmAlert } from "react-confirm-alert";
import Pagination from "../../../assets/SharedComponents/Pagination";
import { getName } from "../../../core/utils/getName/getName";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";

const ProductCategory = () => {

  const [data, _data] = useState([]);

  const [dataDeleted, _dataDeleted] = useState(false);
  const [countValue, _countValue] = useState('');

  const [filters, _filters] = useState(false);

  const [loading,_loading] = useState(false)

  const [page, setPage] = useState(1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [addCategory,_addCategory] = useState(false)

  const [editable, _editable] = useState('');
  const [loaded, _loaded] = useState(false);
  
  const [categoryName,_categoryName] = useState('')

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
  
  let translationAccess = userAccess.find(o=>o.slug ==="translation")

  useEffect(() => {
    getProductCategories();
    _dataDeleted(false);
  }, [dataDeleted,page,countValue]);


  const getProductCategories = () => {
    _loaded(true)
    API.get(APP_URLS.LIST_PRODUCT_CATEGORY+`?page=${page}&per_page=${countValue}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _total(resp.data.total);
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false)
        }
      })
      .catch(function (error) {
        _loaded(false)
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map((error, index) => (error_message = resp.data.errors[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const editRecord = () =>{
    let data ={}
    data["name"] = categoryName;
    if(categoryName){
      if(!loading){
        _loading(true)
        API.post(APP_URLS.UPDATE_PRODUCT_CATEGORY+ `/${editable}?_method=patch`,data)
        .then(res => {
          _loading(false)
          const resp = res.data;
          if(resp.success===true){
            toast.success("Product Category Updated Successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            getProductCategories()
            _categoryName('')
            _editable('')
          }
          else{
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {  
          _loading(false)
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) =>(error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
              error_message =  resp.data.data.error;
          } else if(resp.data?.error!== undefined){
              error_message =  resp.data.error
          } else{
              error_message =  resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
        });
      }
    }
    else{
      toast.error("Product Category Name can't be blank", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const saveRecord = () =>{
    let data ={}
    data["name"] = categoryName;
    if(categoryName){
      if(!loading){
        _loading(true)
        API.post(APP_URLS.ADD_PRODUCT_CATEGORY,data)
        .then(res => {
          _loading(false)
          const resp = res.data;
          if(resp.success===true){
            toast.success("Product Category Added Successfully.", {
              position: toast.POSITION.TOP_RIGHT
            })
            _addCategory(false)
            _categoryName('')
            getProductCategories()
          }
          else{
            toast.error(resp.message, {
              position: toast.POSITION.TOP_RIGHT
            })
          }
        })
        .catch(function (error) {  
          _loading(false)
          const resp = error.response;
          let error_message = '';
          if (resp.data.data !== undefined && resp.data.data !== null) {
            {
              Object.keys(resp.data.data).map(
                (error, index) =>(error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
              error_message =  resp.data.data.error;
          } else if(resp.data?.error!== undefined){
              error_message =  resp.data.error
          } else{
              error_message =  resp.data.message
          }
          toast.error(error_message, {
            position: toast.POSITION.TOP_RIGHT
          })
        });
      }
    }
    else{
      toast.error("Product Category Name can't be blank", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_PRODUCT_CATEGORY + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("Product category has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (searchValue) => {
    _countValue(searchValue);
    if (searchValue !== "") {
      _totalPage(0)
      setPage(1);
    }
  };

  const clearFilter = () => {
    _filters(true)
    handleSearchChange('');
    if (countValue !== '') {
      _totalPage(0)
      setPage(1);
    }
  };


  return (
    <>
      <TableWrapper>
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-4 mt-3">
            <h4 className="translation text-center mb-3">TRANSLATION</h4>
            <SideTranslate />
          </div>
          <div className="col-lg-10 col-md-9 col-sm-8 mt-2">
            <div className="headerContainer">
              <h1>PRODUCT CATEGORY</h1>
            </div>
            <div className="row">
              <div className="d-flex justify-content-between add-info flex-wrap">
                <div className="add-depertment d-flex justify-content-end mb-2">
                  {translationAccess.permissions.find((o) => o.slug === "add") ? (
                    <div>
                      <button onClick={() => {
                        _addCategory(true)
                        _editable('')
                        }} className="btn">
                        + Add
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex justify-content-end mb-2 gap-2 flex-wrap align-items-center gap-2">
                  <RecordCount
                    onSearchClick={(search) => handleSearchChange(search)}
                    id="pageCount"
                    filters={filters}
                    _filters={_filters} />
                  <Tooltip title="Clear Filter" placement="bottom">
                    <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
                <div className="justify-content-end mt-1 me-2">
                    <span className='text-start'>Total Records : <b>{total}</b></span>
                </div>
            </div>
            <div className={(addCategory ? "modal fade show" : "modal fade") + " custom-modal modal-backdrop"}>
              <div className="modal">
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title">Enter Product Category</h5>
                      <img src={close} alt="" className="mx-2" role="button" onClick={() => _addCategory(false)} />
                    </div>
                    <div className="modal-body">
                      <input type="text" className="form-control" placeholder="Name" onChange={(e) => _categoryName(e.target.value)} />
                    </div>
                    <div className="modal-footer">
                      <button type="button" className={(loading?"disabled":"")+" btn btn-primary"} onClick={saveRecord}>
                        Save
                      </button>
                      <button type="button" className={(loading?"disabled":"")+" btn btn-secondary"} onClick={() => _addCategory(false)}>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              
                  <div className="tableContainer">
                  <table className="auctionTable">
                    <thead className="tableHead">
                      <tr className="table-row">
                        <th className="tableHead-1">ID</th>
                        <th className="tableHead-3">Name</th>
                        <th className="tableHead-4">Updated by</th>
                        <th className="tableHead-5">Created by</th>
                        {translationAccess.permissions.find((o) => o.slug === "update") || translationAccess.permissions.find((o) => o.slug === "delete") ? <th className="tableHead-9">Action</th> : ""}
                      </tr>
                    </thead>
          {
            loaded ? 
              <div className='d-flex justify-content-center three-dot'>              
                  <ThreeDots 
                    height="150" 
                    width="150" 
                    radius="9"
                    color="#637df4" 
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
              </div>
              :
                <tbody>
                      {data.length > 0 ? (
                        data.map((item, index) => {
                          let createdBy = getName(item.create_activity_log, item.update_activity_log);
                          let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                          return (
                            <tr className="table-row" key={index}>
                              <td className="tableHead-1">{item.id}</td>
                              <td className="tableHead-3">{editable === item.id ? <input type="text" value={categoryName} className="form-control" onChange={(e) => _categoryName(e.target.value)} /> : item.name.replace("_", " ").charAt(0).toUpperCase() + item.name.replace("_", " ").slice(1)}</td>
                              <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                              <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                              {translationAccess.permissions.find((o) => o.slug === "update") || translationAccess.permissions.find((o) => o.slug === "delete") ? (
                                <td className="tableHead-9">
                                  {editable === item.id ? (
                                    <>
                                      {translationAccess.permissions.find((o) => o.slug === "update") ? <img src={save} alt="" className="mx-2" role="button" onClick={editRecord} /> : ""}
                                      {translationAccess.permissions.find((o) => o.slug === "update") ? <img src={close} alt="" className="mx-2" role="button" onClick={() => _editable("")} /> : ""}
                                    </>
                                  ) : (
                                    <>
                                      {translationAccess.permissions.find((o) => o.slug === "update") ? (
                                        <img
                                          src={edit}
                                          alt=""
                                          className="mx-2"
                                          role="button"
                                          onClick={() => {
                                            _editable(item.id);
                                            _categoryName(item.name);
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {translationAccess.permissions.find((o) => o.slug === "delete") ? <img src={waste} alt="" className="mx-2" role="button" onClick={() => removeType(item.id)} /> : ""}
                                    </>
                                  )}
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          );
                        })
                      ) : (
                        <NoRecord />
                      )}
                </tbody>
          }
                  </table>
                </div>

              <div>
                {totalPage > 1 ? <Pagination totalPages={totalPage} page={page} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
              </div>
          </div>
        </div>
      </TableWrapper>
    </>
  );
};

export default ProductCategory;
