import React, { useEffect, useRef, useState } from 'react'
import { ThreeDots } from 'react-loader-spinner';
import { NavLink, useLocation, useSearchParams } from 'react-router-dom';
import { APP_URLS } from '../../api/url';
import API from '../../api/axios';
import { auctionUserTypes, userTypeName } from '../../config/constant';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import RecordCount from '../../assets/SharedComponents/RecordCount/RecordCount';
import { Button, Tooltip } from '@mui/material';
import Pagination from '../../assets/SharedComponents/Pagination';
import NoRecord from '../../assets/SharedComponents/Table/NoRecord';
import { FaFilterIcon } from '../../assets/Images/icon';
import StaticFilter from '../../assets/SharedComponents/Filters/StaticFilter';
import CountryFilter from '../../assets/SharedComponents/Filters/CountryFilter';
import { CreditHistoryWrapper } from './style';
import UserFilter from '../../assets/SharedComponents/Filters/UserFilter';
import AddCreditPopup from './CreditHistoryDetails/AddCreditPopup';
import noImage from "../../assets/Images/image/noImage.svg"
import download from 'downloadjs';
import moment from 'moment';
import SubsidiaryFilter from '../../assets/SharedComponents/Filters/SubsidiaryFilter';
import { generateQueryString } from '../../core/utils';
import Sorting from '../../assets/SharedComponents/Sorting/Sorting';

const CreditHistory = () => {
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, _data] = useState([]);
    const [dataDeleted, _dataDeleted] = useState(false);
    const [trashed, _trashed] = useState(searchParams.get('trashed') !== null ? (searchParams.get('trashed') === 'false' ? false : true) : true);
    const [searchkeyword, _searchkeyword] = useState(query.get('keyword') !== null ? query.get('keyword') : "");
    const [searchSubKeyword, _searchSubKeyword] = useState(query.get('sub_keyword') !== null ? query.get('sub_keyword') : "");
    const [countValue, _countValue] = useState(query.get('per_page') !== null ? query.get('per_page') : "");
    const [userID, _userID] = useState(query.get('user_id') !== null ? query.get('user_id') : "");
    const [subID, _subID] = useState(query.get('subsidiary_id') !== null ? query.get('subsidiary_id') : "");
    const [country, setCountry] = useState(query.get('country_id') !== null ? query.get('country_id') : "");
    const [profileType, _profileType] = useState(query.get('profile_type') !== null ? query.get('profile_type') : "");
    const [page, setPage] = useState(query.get('page') !== null ? parseInt(query.get('page')) : 1);
    const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))
    const [total, _total] = useState(0);
    const [totalPage, _totalPage] = useState(0);
    const [filters, _filters] = useState(false);
    const [loaded, _loaded] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const [creditTotals, setCreditTotals] = useState({});
    const popupRef = useRef();
    const planAccess = userAccess.find(o => o.slug === "credits")
    const userTypesData = Object.keys(auctionUserTypes).map((key) => ({ name: key, value: auctionUserTypes[key] }))
    const [creditAddedSuccess, _creditAddedSuccess] = useState(false);
    const [sort, _sort] = useState(searchParams.get('a_sort') !== null ? searchParams.get('a_sort') : "");

    useEffect(() => {
        const getCreditHistory = async () => {
            _loaded(true);
            const apiUrl = getRestUrl();
            try {
                const res = await API.get(`${APP_URLS.LIST_CREDIT_HISTORY}?${apiUrl}`);
                const resp = res.data;
                if (resp.success === true) {
                    _data(resp.data.credits.data);
                    setCreditTotals(resp.data.totals);
                    _totalPage(resp.data.credits.last_page);
                    _total(resp.data.credits.total);
                } else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            } catch (error) {
                const resp = error.response;
                let errorMessage = "";

                if (resp?.data?.data) {
                    if (resp.data.data.message) {
                        errorMessage = resp.data.data.message;
                    } else {
                        Object.keys(resp.data.data).forEach((key) => {
                            errorMessage = resp.data.data[key][0];
                        });
                    }
                } else if (resp?.data?.error) {
                    errorMessage = resp.data.error;
                } else if (resp?.error) {
                    errorMessage = resp.error;
                } else {
                    errorMessage = resp?.message || 'An unknown error occurred';
                }

                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } finally {
                _loaded(false);
            }
        };
        getCreditHistory()
        _dataDeleted(false)
    }, [page, sort, searchkeyword, searchSubKeyword, dataDeleted, countValue, trashed, country, profileType, userID, subID, creditAddedSuccess])

    const removeType = (index) => {
        confirmAlert({
            title: "Confirm to remove",
            message: `Are you sure you want to remove?`,
            closeOnEscape: true,
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        API.delete(APP_URLS.DELETE_PLAN + `/${index}`)
                            .then((res) => {
                                const resp = res.data;
                                if (resp.success === true) {
                                    _dataDeleted(true);
                                    toast.success("Plan has been deleted successfully.", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                } else {
                                    toast.error(resp.message, {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                }
                            })
                            .catch(function (error) {
                                const resp = error.response;
                                let error_message = "";
                                if (resp.data.data !== undefined && resp.data.data !== null) {
                                    if (resp.data.data.message) {
                                        error_message = resp.data.data.message
                                    } else {
                                        {
                                            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                                        }
                                    }
                                } else if (resp.data.data?.error !== undefined) {
                                    error_message = resp.data.data.error;
                                } else if (resp.data?.error !== undefined) {
                                    error_message = resp.data.error;
                                } else {
                                    error_message = resp.data.message;
                                }
                                toast.error(error_message, {
                                    position: toast.POSITION.TOP_RIGHT,
                                });
                            });
                    },
                },
                {
                    label: "No",
                },
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            overlayClassName: "overlay-custom-class-name",
        });
    };

    const handleSearchChange = (updateState, searchValue) => {
        updateState(searchValue);
        if ((updateState === _countValue && searchValue !== countValue) || (updateState === _searchkeyword && searchValue !== searchkeyword) || (updateState === _searchSubKeyword && searchValue !== searchSubKeyword)) {
            if (searchValue !== "") {
                _totalPage(0)
                setPage(1);
            }
        }
    };

    const clearFilter = () => {
        _filters(true)
        _userID('')
        _subID('')
        setCountry("");
        _profileType('')
        _sort('')
        handleSearchChange(_countValue, '');
        handleSearchChange(_searchkeyword, '');
        handleSearchChange(_searchSubKeyword, '');
        _trashed(true);
        if (searchkeyword !== "" || sort !== "" || searchSubKeyword !== "" || countValue !== '' || trashed !== true || userID !== "" || subID !== "") {
            _totalPage(0)
            setPage(1);
        }
        for (var value of query.keys()) {
            const param = searchParams.get(value);
            if (param) {
                searchParams.delete(value);
                setSearchParams(searchParams);
            }
        }
    };

    const setChangeValue = (updateState, item) => {
        updateState(item);
        _totalPage(0);
        setPage(1);
    };

    //sorting function
    const setsorting = (val) => {
        _sort(val);
        if (sort !== val) {
            setPage(1);
            _totalPage(0)
        }
    }

    //filter params
    function getRestUrl() {
        let urlParams = {};
        if (country) urlParams['filter[country_id]'] = country
        if (userID) urlParams['filter[user_id]'] = userID
        if (subID) urlParams['filter[subsidiary_id]'] = subID
        if (countValue) urlParams['per_page'] = countValue
        if (profileType) urlParams['filter[profile_type]'] = profileType;
        if (sort) urlParams['sort'] = sort;
        if (page > 1) urlParams['page'] = page;
        const restUrl = new URLSearchParams(urlParams);
        return restUrl
    }
    const handleExtractReport = () => {
        const apiurl = getRestUrl()
        API.get(`${APP_URLS.LIST_CREDIT_HISTORY}/download_report?${apiurl}`, { responseType: 'blob' })
            .then(res => {
                const resp = res.data;
                if (resp !== null) {
                    toast.success("Downloading. Please wait", {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    const content = res.headers['content-type'];
                    const currentDate = moment().format('YYYY-MM-DD HH-mm-ss');
                    const fileName = `${currentDate}_credit_history_report.xlsx`
                    download(resp, fileName, content)
                }
                else {
                    toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
            .catch(function (error) {
                const resp = error.response;
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {
                        Object.keys(resp.data.errors).map(
                            (error, index) => (error_message = resp.data.errors[error][0])
                        );
                    }
                } else if (resp.data.data?.error !== undefined) {
                    error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                    error_message = resp.data.error
                } else {
                    error_message = resp.data.message
                }
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            });
    }

    //set user id state and empties the sub id
    const setUserValue = (updateState, item) => {
        updateState(item);
        _subID('')
        _totalPage(0)
        setPage(1);
        handleSearchChange(_searchSubKeyword, '');
    }

    //set sub id and empties the user id
    const setSubValue = (updateState, item) => {
        updateState(item);
        _userID('')
        _totalPage(0)
        setPage(1);
        handleSearchChange(_searchkeyword, '');
    }

    //handle popup for add credit
    const handlePopup = (show) => {
        setPopupOpen(show);
    };

    //handles credit details page route with query params
    const CreditHistoryDetailLink = (userId, subsIs) => {

        const queryString = generateQueryString({
            country_id: country,
            keyword: searchkeyword,
            sub_keyword: searchSubKeyword,
            profile_type: profileType,
            user_id: userID,
            subsidiary_id: subID,
            sort: sort,
            per_page: countValue,
            page: page !== 1 ? page : undefined
        });
        const url = `/credit-history-details/${userId || subsIs}?${queryString}`;
        return url
    }

    return (
        <>
            <CreditHistoryWrapper>
                <div className="auctionMainContainer">
                    <div className="row mt-4">
                        <div className="col-md-4 offset-md-4">
                            <h1>CREDITS HISTORY</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mb-2">
                            {planAccess.permissions.find((o) => o.slug === "add") ?
                                <div className="actionContainer">
                                    <button className="btn" onClick={() => handlePopup(true)}>Add Credit</button>
                                </div>
                                : ''}
                        </div>
                        <div className="justify-content-end mb-2 mt-4 d-flex gap-2 mb-2 flex-wrap align-items-center">
                            <UserFilter _filters={_filters} filters={filters} setUser={(val) => setUserValue(_userID, val)} user={userID} searchkeyword={searchkeyword} _searchkeyword={_searchkeyword} />
                            <SubsidiaryFilter _filters={_filters} filters={filters} setSub={(val) => setSubValue(_subID, val)} sub={subID} searchSubKeyword={searchSubKeyword} _searchSubKeyword={_searchSubKeyword} />
                            <StaticFilter placeholder='Profile Type' data={userTypesData} handleChange={(e) => {
                                _profileType(e.target.value)
                                _totalPage(0)
                                setPage(1);
                            }} value={profileType} />
                            <CountryFilter value={country} handleChange={(val) => setChangeValue(setCountry, val)} />
                            <RecordCount
                                onSearchClick={(search) => handleSearchChange(_countValue, search)}
                                val={countValue}
                                id="pageCount"
                                filters={filters}
                                _filters={_filters} />
                            <Button onClick={handleExtractReport} className='extract-report ml-2' variant="outlined" >Extract report</Button>
                            <Tooltip title="Clear Filter" placement="bottom">
                                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                            </Tooltip>
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between info-wrap">
                        <div className="justify-content-end mt-1 me-2">
                            <span className='text-start'>Total Records : <b>{total}</b></span>
                        </div>
                    </div>
                    <div className="tableContainer">
                        <table className="auctionTable">
                            <thead className="tableHead">
                                <tr className="table-row">
                                    <th className="clipboard">ID</th>
                                    <th className="clipboard">
                                        Full name<br />
                                        Dealer name<br />
                                    </th>
                                    <th className="w130">Type of profile</th>
                                    <th>
                                        Country flag<br />
                                        Country code
                                    </th>
                                    <th>Phone number</th>
                                    <th className='time-start'>
                                        Total credit acquired <br />
                                        <Sorting sortType='totalAcquired' sort={sort} handleSort={setsorting} />
                                    </th>
                                    <th className='time-start'>
                                        Total credit available <br />
                                        <Sorting sortType='totalAvailableBalance' sort={sort} handleSort={setsorting} />
                                    </th>
                                    <th className='time-start'>
                                        Total credit spent <br />
                                        <Sorting sortType='totalSpent' sort={sort} handleSort={setsorting} />
                                    </th>
                                    <th className='time-start'>
                                        Total credit expired <br />
                                        <Sorting sortType='totalExpired' sort={sort} handleSort={setsorting} />
                                    </th>
                                    <th className="w80">Action</th>
                                </tr>
                            </thead>
                            {loaded ?
                                <div className='d-flex justify-content-center three-dot'>
                                    <ThreeDots
                                        height="150"
                                        width="150"
                                        radius="9"
                                        color="#637df4"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    />
                                </div>
                                :
                                <tbody>
                                    {data.length > 0 ?
                                        data.map((item, index) => {
                                            return <tr className={(item.deleted_at ? "deletedRow" : "") + " table-row"} key={index}>
                                                <td className="clipboard">
                                                    <span className='name-text' >{item.user_id || item.subsidiary_id}</span>
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-column">

                                                        {!!item.user_id ? <div>
                                                            <NavLink target="_blank" to={`/detail-member/${item.user_id}`} className="link" title={item.user.name !== null ? item.user.name.replace("_", " ").charAt(0).toUpperCase() + item.user.name.replace("_", " ").slice(1) : ""}>
                                                                <span className='name-text' title={item.user.name !== null ? item.user.name.replace("_", " ").charAt(0).toUpperCase() + item.user.name.replace("_", " ").slice(1) : "-"}>{item.user.name !== null ? item.user.name.replace("_", " ").charAt(0).toUpperCase() + item.user.name.replace("_", " ").slice(1) : "-"}</span>
                                                            </NavLink>
                                                        </div> : '-'}
                                                        {!!item.subsidiary_id ? (
                                                            <NavLink target="_blank" to={`/company-details/${item.subsidiary_id}?`} className="link" title={item.subsidiary.name !== null ? item.subsidiary.name.replace("_", " ").charAt(0).toUpperCase() + item.subsidiary.name.replace("_", " ").slice(1) : ""}>
                                                                <span className='name-text' title={item.subsidiary.name !== null ? item.subsidiary.name.replace("_", " ").charAt(0).toUpperCase() + item.subsidiary.name.replace("_", " ").slice(1) : "-"}>{item.subsidiary.name !== null ? item.subsidiary.name.replace("_", " ").charAt(0).toUpperCase() + item.subsidiary.name.replace("_", " ").slice(1) : "-"}</span>
                                                            </NavLink>
                                                        ) : '-'}
                                                    </div>
                                                </td>
                                                <td>{!!item.user_id ? userTypeName.PRIVATE
                                                    : userTypeName.DEALER}</td>
                                                <td>
                                                    {<img src={!!item.user_id ? item.user.limited_address.flag_thumbnail : !!item.subsidiary_id ? item.subsidiary.limited_address.flag_thumbnail : noImage} alt="car" height={25} width={25} className="auctionEventImage rounded-circle" />}<br />
                                                    {!!item.user_id ? item.user.limited_address.prefix_call : item.subsidiary.limited_address.prefix_call}
                                                </td>
                                                <td>{!!item.user_id ? item.user.phone : item.subsidiary.phone_principal}</td>
                                                <td>{item.totalAcquired || '-'}</td>
                                                <td>{item.totalAvailableBalance || '-'}</td>
                                                <td>{item.totalSpent || '-'}</td>
                                                <td>{item.totalExpired || '-'}</td>
                                                <td className="w80">
                                                    <NavLink
                                                        to={CreditHistoryDetailLink(item.user_id, item.subsidiary_id)}
                                                        state={{ profileType: item.profile_type, user_id: item.user_id, subsidiary_id: item.subsidiary_id }}
                                                        className="link name-text"
                                                    >
                                                        View
                                                    </NavLink>
                                                </td>
                                            </tr>
                                        })
                                        :
                                        <NoRecord />
                                    }
                                </tbody>
                            }
                        </table>
                        {/* total credit records */}
                        {!loaded ?
                            <table className="auctionTable reviewTable">
                                <thead className="tableHead">
                                    <tr className="table-row table-total">
                                        <th className="time-start"> Total: </th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th className='time-start'></th>
                                        <th title="Total credit acquired" className='time-start'>{creditTotals.totalAcquired || '-'}</th>
                                        <th title="Total credit available" className='time-start'>{creditTotals.totalAvailableBalance}</th>
                                        <th title="Total credit spent" className='time-start'>{creditTotals.totalSpent || '-'}</th>
                                        <th title="Total credit expired" className='time-start'>{creditTotals.totalExpired || '-'}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                            </table>
                            : null
                        }
                    </div>
                    <div>
                        {totalPage > 1 ? <Pagination totalPages={totalPage} page={page} key={totalPage} onPageClick={(page) => setPage(page + 1)} /> : ""}
                    </div>
                </div>
                <AddCreditPopup
                    ref={popupRef}
                    popupName="add"
                    open={popupOpen}
                    _showPopup={handlePopup}
                    _creditAddedSuccess={_creditAddedSuccess}
                />
            </CreditHistoryWrapper>
        </>
    )
}

export default CreditHistory