import React, { useEffect, useState } from "react";
import MainLayout from "../../../../assets/Layout/MainLayout";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import edit from "../../../../assets/Images/image/edit.png";
import waste from "../../../../assets/Images/image/Waste.png";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import { toast } from "react-toastify";
import Pagination from "../../../../assets/SharedComponents/Pagination";
import SideTranslate from "../../components/SideTranslate";
import NoRecord from "../../../../assets/SharedComponents/Table/NoRecord";
import { TableWrapper } from "../../style/table";
import SearchKey from "../../../../assets/SharedComponents/Search/SearchKey";
import { getName } from "../../../../core/utils/getName/getName";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../../assets/SharedComponents/RecordCount/RecordCount" 
import ProductTypeFilter from "../../../../assets/SharedComponents/Filters/ProductTypeFilter";

const Brand = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([]);
  const [dataDeleted, _dataDeleted] = useState(false);

  const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [searchkeyword, _searchkeyword] = useState(query.get('keyword')!==null?query.get('keyword'):"");
  const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");
 
  const [productValue, _productValue] = useState(query.get('product_type_id')!==null?query.get('product_type_id'):"");

  const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let vehicleManagementAccess = userAccess.find(o => o.slug === "vehicle_management")

  const [filters, _filters] = useState(false);
  const [loading, _loading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    getBrands();
    _dataDeleted(false);
  }, [dataDeleted, page, productValue, searchkeyword,countValue]);

  const getBrands = () => {
    _loading(true);
    API.get(APP_URLS.LIST_BRANDS + `?page=${page}&per_page=${countValue}&filter[product_type_id]=${productValue}&filter[name]=${searchkeyword}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loading(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loading(false);
        }
      })
      .catch(function (error) {
        _loading(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map((error, index) => (error_message = resp.data.errors[error][0]));
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeAddress = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_BRANDS + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("Brand has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword)){
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _productValue("");
    if (productValue !== "" ||countValue !== '' || searchkeyword !== "") {
      setPage(1)
      _totalPage(0)
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  return (
    <>
      <TableWrapper>
        <div className="row">
          <div className="col-lg-2 mt-3">
            <h4 className="translation text-center mb-3">VEHICLES <br /> MANAGEMENT</h4>
            <SideTranslate />
          </div>
          <div className="col-lg-10 mt-2">
            <div className="headerContainer mb-3">
              <h1>BRANDS</h1>
            </div>
            <div className="d-flex justify-content-between add-info">
              <div className="add-depertment mb-2">
                {vehicleManagementAccess.permissions.find((o) => o.slug === "add") ? (
                  <div>
                    <NavLink to="/vehicles-management/create-vehicle-brands">
                      <button className="btn">+ Add</button>
                    </NavLink>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="d-flex gap-2 mb-2 flex-wrap align-items-center">
                <ProductTypeFilter handleChange={(e) => {
                    _productValue(e.target.value)
                    _totalPage(0)
                    setPage(1);
                }} value={productValue}/>      
                <SearchKey
                  onSearchClick={(search) => handleSearchChange(_searchkeyword,search)}
                  val={searchkeyword}
                  filters={filters}
                  _filters={_filters}
                />
                <RecordCount
                  onSearchClick={(search) => handleSearchChange(_countValue, search)}
                  id="pageCount"
                  val={countValue}
                  filters={filters}
                  _filters={_filters} />
                <Tooltip title="Clear Filter" placement="bottom">
                  <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
                </Tooltip>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between info-wrap">
              <div className="justify-content-end mt-1 me-2">
                  <span className='text-start'>Total Records : <b>{total}</b></span>
              </div>
          </div>
            <div className="tableContainer">
              <table className="auctionTable">
                <thead className="tableHead">
                  <tr className="table-row">
                    <th>ID</th>
                    <th>Name</th>
                    <th>Popular</th>
                    <th>Product Type</th>
                    <th>Updated by</th>
                    <th>Created by</th>
                    <th>Web icon</th>
                    <th>Mobile icon</th>
                    {vehicleManagementAccess.permissions.find((o) => o.slug === "update") || vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? <th>Action</th> : ""}
                  </tr>
                </thead>
                {loading ?
                  <div className='d-flex justify-content-center three-dot'>
                    <ThreeDots
                      height="150"
                      width="150"
                      radius="9"
                      color="#637df4"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </div>
                  :
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => {
                        let createdBy = getName(item.create_activity_log, item.update_activity_log);
                        let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                        let webIcon = null
                        let mobileIcon = null
                        if(item?.media?.length){ 
                          const img1 = item.media[0].original_url;
                          const img2 = item.media[1]?.original_url || ''
                          if(item.media[0].custom_properties.type == 'mobile') {
                            mobileIcon = img1
                            webIcon = img2
                          } else{
                            webIcon = img1
                            mobileIcon = img2 
                          }   
                        }   
                        return (
                          <tr className="table-row" key={index}>
                            <td>{item.id}</td>
                            <td>{item.name}</td>
                            <td>{item.is_popular === 1 ? <span className="status status-active" title="Popular"></span> : <span className="status status-end" title="Not Popular"></span>}</td>
                            <td>{item.product_type?.item_name.en}</td>
                            <td>{upadtedBy !== "" && upadtedBy !== undefined ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                            <td>{createdBy !== "" && createdBy !== undefined ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                            <td> {webIcon ? <img src={webIcon} alt="" /> : "-"} </td>
                            <td> {mobileIcon ? <img src={mobileIcon} alt="" /> : "-"} </td>
                            {vehicleManagementAccess.permissions.find((o) => o.slug === "update") || vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? (
                              <td className="action">
                                {vehicleManagementAccess.permissions.find((o) => o.slug === "update") ? (
                                  <button className=" me-2" onClick={() => navigate(`/vehicles-management/edit-vehicle-brands/${item.id}?`+(productValue!==""?"product_type_id="+productValue+"&":"")+(searchkeyword!==""?"keyword="+searchkeyword+"&":"")+(countValue!==""?"per_page="+countValue+"&":"")+(page!==1?"page="+page:""))}>
                                    <img src={edit} alt="f" />
                                  </button>
                                ) : (
                                  ""
                                )}
                                {vehicleManagementAccess.permissions.find((o) => o.slug === "delete") ? (
                                  <button onClick={() => removeAddress(item.id)}>
                                    <img src={waste} alt="f" />
                                  </button>
                                ) : (
                                  ""
                                )}
                              </td>
                            ) : (
                              ""
                            )}
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecord />
                    )}
                  </tbody>
                }
              </table>
            </div>
            {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
          </div>
        </div>
      </TableWrapper>
    </>
  );
};

export default Brand;
