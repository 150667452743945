import React, { useEffect, useState } from "react";
import { CouponTableWrapper } from "./style/couponTable";
import waste from "../../../assets/Images/image/Waste.png";
import copy from "../../../assets/Images/icons/faLink.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import MainLayout from "../../../assets/Layout/MainLayout";
import { NavLink, useLocation, useSearchParams } from "react-router-dom";
import Pagination from "../../../assets/SharedComponents/Pagination";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { ThreeDots } from "react-loader-spinner";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { Tooltip } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";
import * as Constant from "../../../config/constant";
import StaticFilter from "../../../assets/SharedComponents/Filters/StaticFilter";
import { formatCurrency } from "../../../core/utils";


const userRoleOptionsList =  Object.keys(Constant.userRoleOptions).map((key)=> ({ name: key  , value : Constant.userRoleOptions[key] }))
const validationOptionsList =  Object.keys(Constant.validationOptions).map((key)=> ({ name: key  , value : Constant.validationOptions[key] }))
const subscriptionOptionsList =  Object.keys(Constant.subscriptionOptions).map((key)=> ({ name: key  , value : Constant.subscriptionOptions[key] }))
const creditsOptionsList =  Object.keys(Constant.couponTypeOptions).map((key)=> ({ name: key  , value : Constant.couponTypeOptions[key] }))

const PlanTable = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [coupons,_coupons] = useState([])
  
  const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
  const [totalPage, _totalPage] = useState(0);
  const [total, _total] = useState(0);
  
  const [trashed, _trashed] = useState(searchParams.get('trashed')!==null?(searchParams.get('trashed')==='false'?false:true):true);
  const [searchkeyword, _searchkeyword] = useState(query.get('keyword')!==null?query.get('keyword'):"");
  const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");
  const [profileType, _profileType] = useState(query.get('profile_type')!==null?query.get('profile_type'):"");
  const [duration, _duration] = useState(query.get('duration')!==null?query.get('duration'):"");
  const [couponsType,_couponType] = useState(query.get('coupon_type')!==null?query.get('coupon_type'):"")
  const [active, _active] = useState(query.get('active')!==null?query.get('active'):"");

  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);

  const [userAccess, _userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let couponAccess = userAccess.find(o => o.slug === "coupons")

  useEffect(() => {
    getPlans()
  }, [page,searchkeyword,duration,profileType,countValue,active,trashed, couponsType])

  const syncCoupons = () =>{
    _loaded(true);
    API.get(APP_URLS.SYNC_COUPONS)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        toast.success("Coupon & promo codes data synchronized.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        getPlans()
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    })
    .catch(function (error) {
      const resp = error.response;
      let error_message = "";
      if (resp.data.data !== undefined && resp.data.data !== null) {
        if(resp.data.data.message){
          error_message = resp.data.data.message
        }else{
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        }
      } else if (resp.data.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp.data?.error !== undefined) {
        error_message = resp.data.error;
      } else {
        error_message = resp.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const getPlans = () =>{
    _loaded(true);
    API.get(APP_URLS.LIST_COUPONS+`?keyword=${searchkeyword}&page=${page}&per_page=${countValue}&filter[coupon_type]=${couponsType}&filter[profile_type]=${profileType}&filter[duration]=${duration}&filter[active]=${active}&with_trashed=${trashed===true?1:0}`)
    .then((res) => {
      const resp = res.data;
      if (resp.success === true) {
        _coupons(resp.data.data);
        _totalPage(resp.data.last_page);
        _total(resp.data.total);
        _loaded(false);
      } else {
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        _loaded(false);
      }
    })
    .catch(function (error) {
      _loaded(false);
      const resp = error.response;
      let error_message = "";
      if (resp.data.data !== undefined && resp.data.data !== null) {
        if(resp.data.data.message){
          error_message = resp.data.data.message
        }else{
          {
            Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
          }
        }
      } else if (resp.data.data?.error !== undefined) {
        error_message = resp.data.data.error;
      } else if (resp.data?.error !== undefined) {
        error_message = resp.data.error;
      } else {
        error_message = resp.data.message;
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
  }

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_COUPON + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  toast.success("Coupon has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                  getPlans()
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined && resp.data.data !== null) {
                  if(resp.data.data.message){
                    error_message = resp.data.data.message
                  }else{
                    {
                      Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                    }
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword)){
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true);
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _profileType('');
    _duration('');
    _active('');
    _trashed(true)
    _couponType('');
    if (searchkeyword !== '' ||countValue !== '' || profileType !== '' || duration !== ''|| trashed!==true) {
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  return (
    <>
      <CouponTableWrapper>
        <div className="auctionMainContainer">
          <div className="headerContainer mb-3 justify-content-center">
            <h1>COUPON</h1>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="actionContainer mb-2 ">
            {couponAccess.permissions.find((o) => o.slug === "add")?
              <>
                <NavLink className="btn" to="/create-coupon" state={{ couponType: Constant.couponTypeName.SUBSCRIPTIONS }}>
                  + Add Subscription Coupon
                </NavLink>
                <NavLink className="btn" to="/create-coupon" state={{ couponType: Constant.couponTypeName.CREDITS }}>
                  + Add Credit Coupon
                </NavLink>
              </>
              :''}
              <button className="btn" onClick={()=>syncCoupons()}>Sync Coupons</button>
            </div>
            <div className="d-flex justify-content-end mb-2  gap-2 mb-2 flex-wrap align-items-center">
              <StaticFilter placeholder='Profile Type' data={userRoleOptionsList}  handleChange={(e) => {
                _profileType(e.target.value)
                _totalPage(0)
                setPage(1);
              }} value={profileType}/>   
              <StaticFilter placeholder='Status' data={validationOptionsList}  handleChange={(e) => {
                _active(e.target.value)
                _totalPage(0)
                setPage(1);
              }} value={active}/>   
              <StaticFilter placeholder='Coupon Type' data={creditsOptionsList}  handleChange={(e) => {
                _couponType(e.target.value)
                _totalPage(0)
                setPage(1);
              }} value={couponsType}/> 
              <StaticFilter placeholder='Duration' data={subscriptionOptionsList}  handleChange={(e) => {
                _duration(e.target.value)
                _totalPage(0)
                setPage(1);
              }} value={duration}/>   
              <SearchKey
                onSearchClick={(search) => handleSearchChange(_searchkeyword,search)}
                id="searchData"
                val={searchkeyword}
                filters={filters}
                _filters={_filters}
                placeholder="Search by Title"
              />
              <RecordCount
                onSearchClick={(search) => handleSearchChange(_countValue, search)}
                id="pageCount"
                val={countValue}
                filters={filters}
                _filters={_filters} />
              <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
              </Tooltip>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
            <div className="mt-1">
            <input type="checkbox" checked={!trashed} onChange={()=>{
              _totalPage(0)
              setPage(1);
              _trashed(!trashed)
              }}/>
            <span className="ms-2">Hide Deleted Data</span>
          </div>
          </div>
          <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th className="w-100">ID</th>
                  <th className="clipboard">STRIPE ID</th>
                  <th>                            
                    <div className="status-name">
                      <span>Name</span>
                      <span>Status</span>
                    </div>
                  </th>
                  <th className="clipboard">Applies To</th>
                  <th>Amount %</th>
                  <th>Amount €</th>
                  <th>Limited Redeem</th>
                  <th>Redemptions</th>
                  <th>Type</th>
                  <th>Duration</th>
                  <th>Coupon Type</th>
                  <th>Created At</th>
                  {couponAccess.permissions.find((o) => o.slug === "delete")?
                    <th>Action</th>
                  :''}
                </tr>
              </thead>
              {loaded ? 
                <div className='d-flex justify-content-center three-dot'>              
                      <ThreeDots 
                        height="150" 
                        width="150" 
                        radius="9"
                        color="#637df4" 
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                  </div>
                :<tbody>
                  {coupons.length>0?
                  coupons.map((item,index)=>{
                    return <tr className={(item.deleted_at ? "deletedRow" : "") + " table-row"} key={index}>
                      <td className="w-100">{item.id}</td>
                      <td className="clipboard">
                        <div className="my-2 d-flex justify-content-center">
                          <span className="copyText">{item.stripe_coupon_id}</span>
                          <CopyToClipboard text={item.stripe_coupon_id} onCopy={() => toast("Text Copied")}>
                            <img className="ms-2" src={copy} alt=""/>
                          </CopyToClipboard>
                        </div>
                      </td>
                      <td>
                        {item.deleted_at===null&&couponAccess.permissions.find((o) => o.slug === "update")?
                          <NavLink to={`/edit-coupon/${item.id}?`+(profileType!==""?"profile_type="+profileType+"&":"")+(duration!==""?"duration="+duration+"&":"")+(searchkeyword!==""?"keyword="+searchkeyword+"&":"")+(trashed!==true? "trashed=" + trashed + "&": "")+(countValue!==""?"per_page="+countValue+"&":"")+(page!==1?"page="+page:"")} 
                            state={{ couponType: item.coupon_type == 1 ? Constant.couponTypeName.SUBSCRIPTIONS : item.coupon_type == 2 && Constant.couponTypeName.CREDITS }}>
                            <div className="status-name">
                            {item.name}  
                            <span className={`mt-1 status ${item.valid===0?"status-end":"status-active"}`} title={item.valid===1?"Valid":"Invalid"}></span>
                            </div>
                          </NavLink>
                          :
                          <div className="status-name">
                          <b className="disabled">{item.name}</b>
                          <span className={`mt-1 status ${item.valid===0?"status-end":"status-active"}`} title={item.valid===1?"Valid":"Invalid"}></span>
                          </div>
                        }
                      </td>
                      <td className="clipboard">
                        {item.applies_to!==null&&item.applies_to.length!==0?
                          <div className="my-2 d-flex justify-content-center">
                            <span className="copyText">{item.applies_to.products[0]}</span>
                            <CopyToClipboard text={item.applies_to.products[0]} onCopy={() => toast("Text Copied")}>
                              <img className="ms-2" src={copy} alt=""/>
                            </CopyToClipboard>
                          </div>
                        :"-"}
                      </td>
                      <td>{item.percent_off!==null?item.percent_off+" %":"-"}</td>
                      <td>{item.amount_off!==null?formatCurrency(item.amount_off):"-"}</td>
                      <td>{item.max_redemptions!==null?item.max_redemptions:"-"}</td>
                      <td>{item.times_redeemed!==null?item.times_redeemed:"-"}</td>
                      <td>{item.profile_type==1?"Admin":item.profile_type==3?"Nomad":"-"}</td>
                      <td>{item.duration.charAt(0).toUpperCase()+item.duration.slice(1)}</td>
                      <td>{item.coupon_type == 1 ? "Subscription" : "Credit" }</td>
                      <td>{moment(item.created_at).tz("Europe/Amsterdam").format("DD-MM-YYYY HH:mm")}</td>
                      {couponAccess.permissions.find((o) => o.slug === "delete")?
                        <td className="tableHead-9">
                          {item.deleted_at?
                            "":
                            <img src={waste} alt="" role="button" onClick={() => removeType(item.id)} />
                          } 
                        </td>
                      :""}
                    </tr>
                  })
                  :
                  <NoRecord />
                  }
                </tbody>
            }
            </table>
          </div>
          {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
        </div>
      </CouponTableWrapper>
    </>
  );
};

export default PlanTable;
