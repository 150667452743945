import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import MainLayout from "../../../assets/Layout/MainLayout";
import { RoleListWrapper } from "./style/role";
import search from "../../../assets/Images/icons/faSearchLite.svg";
import edit from "../../../assets/Images/image/edit.png";
import waste from "../../../assets/Images/image/Waste.png";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { toast } from "react-toastify";
import NoRecord from "../../../assets/SharedComponents/Table/NoRecord";
import Pagination from "../../../assets/SharedComponents/Pagination";
import SearchKey from "../../../assets/SharedComponents/Search/SearchKey";
import { confirmAlert } from "react-confirm-alert";
import { getName } from "../../../core/utils/getName/getName";
import { Tooltip } from "@mui/material";
import { FaFilterIcon } from "../../../assets/Images/icon";
import { ThreeDots } from "react-loader-spinner";
import RecordCount from "../../../assets/SharedComponents/RecordCount/RecordCount";

const AdminRoles = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [userAccess,_userAccess] = useState(JSON.parse(localStorage.getItem('permissions')))

  let roleAccess = userAccess.find(o=>o.slug ==="role_management")

  const [data, _data] = useState([]);

  const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);

  const [searchkeyword, _searchkeyword] = useState(query.get('role_name')!==null?query.get('role_name'):"");
  const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");
  const [dataDeleted, _dataDeleted] = useState(false);

  const [filters, _filters] = useState(false);
  const [ loaded, _loaded] = useState(false);

  let navigate = useNavigate();

  useEffect(()=>{
    getAdminRoles()
    _dataDeleted(false)
  },[searchkeyword,page,dataDeleted,countValue])

  const getAdminRoles = () => {
    _loaded(true)
    API.get(APP_URLS.LIST_ADMIN_ROLES  +`?page=${page}&per_page=${countValue}&filter[role_name]=${searchkeyword}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data)
          _total(resp.data.total);
          _totalPage(resp.data.last_page)
          _loaded(false)
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false)
        }
      })
      .catch(function (error) {
        _loaded(false)
        const resp = error.response;
        let error_message = "";
        if (resp.data.data !== undefined&&resp.data.data !== null) {
          {
            Object.keys(resp.data?.data).map(
              (error, index) => (error_message = resp.data.data[error][0])
              );
            }
          } else if (resp.data.data?.error !== undefined) {
            error_message = resp.data.data.error;
          } else if (resp.data?.error !== undefined) {
            error_message = resp.data.error;
          } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const removeType = (index) => {
    confirmAlert({
      title: "Confirm to remove",
      message: `Are you sure you want to remove?`,
      closeOnEscape: true,
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            API.delete(APP_URLS.DELETE_ADMIN_ROLE + `/${index}`)
              .then((res) => {
                const resp = res.data;
                if (resp.success === true) {
                  _dataDeleted(true);
                  toast.success("Admin Role has been deleted successfully.", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                } else {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }
              })
              .catch(function (error) {
                const resp = error.response;
                let error_message = "";
                if (resp.data.data !== undefined&&resp.data.data !== null) {
                  {
                    Object.keys(resp.data.data).map((error, index) => (error_message = resp.data.data[error][0]));
                  }
                } else if (resp.data.data?.error !== undefined) {
                  error_message = resp.data.data.error;
                } else if (resp.data?.error !== undefined) {
                  error_message = resp.data.error;
                } else {
                  error_message = resp.data.message;
                }
                toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              });
          },
        },
        {
          label: "No",
        },
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-custom-class-name",
    });
  };

  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword)){
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    if(countValue !== '' ||searchkeyword!==''){
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  return (
    <RoleListWrapper>
      <div className="auctionMainContainer">
        <div className="row mt-4">
          <div className="col-md-4 offset-md-4">
            <h1 className="text-center">ADMIN ROLES MANAGEMENT</h1>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between add-info">
          <div className="d-flex justify-content-end mb-2 data-button">
            {roleAccess.permissions.find((o) => o.slug === "add") ? (
              <div className="actionContainer">
                <NavLink className="btn" to="/create-admin-role">
                  + Add
                </NavLink>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="justify-content-end mb-2 d-flex gap-2 mb-2 flex-wrap align-items-center">
            <SearchKey
              onSearchClick={(search) => handleSearchChange(_searchkeyword,search)}
              id="searchData"
              filters={filters}
              val = {searchkeyword}
              _filters={_filters}
              placeholder="Search by Title"
            />
            <RecordCount
              onSearchClick={(search) => handleSearchChange(_countValue, search)}
              val = {countValue}
              id="pageCount"
              filters={filters}
              _filters={_filters} />
            <Tooltip title="Clear Filter" placement="bottom">
                <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
            </Tooltip>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-between info-wrap">
            <div className="justify-content-end mt-1 me-2">
                <span className='text-start'>Total Records : <b>{total}</b></span>
            </div>
        </div>
            <div className="tableContainer">
            <table className="auctionTable">
              <thead className="tableHead">
                <tr className="table-row">
                  <th>ID</th>
                  <th>Role Name</th>
                  <th className="description">Description</th>
                  <th>Number of Users</th>
                  <th>Created By</th>
                  <th>Updated By</th>
                  {roleAccess.permissions.find((o) => o.slug === "update") || roleAccess.permissions.find((o) => o.slug === "delete") ? <th>Action</th> : ""}
                </tr>
              </thead>
          {
            loaded ? 
            <div className='d-flex justify-content-center three-dot'>              
            <ThreeDots 
              height="150" 
              width="150" 
              radius="9"
              color="#637df4" 
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          :
              <tbody>
                {data.length > 0 ? (
                  data.map((item, index) => {
                    let createdBy = getName(item.create_activity_log, item.update_activity_log);
                    let upadtedBy = getName(item.update_activity_log, item.create_activity_log);
                    return (
                      <tr className="table-row" key={index}>
                        <td>{item.id}</td>
                        <td>{item.role_name ? (item.role_name.charAt(0).toUpperCase() + item.role_name.slice(1)).replace("_", " ") : "-"}</td>
                        <td className="description">{item.description ? item.description.charAt(0).toUpperCase() + item.description.slice(1) : "-"}</td>
                        <td>{item.users_count}</td>
                        <td>{createdBy ? createdBy.charAt(0).toUpperCase() + createdBy.slice(1) : "-"}</td>
                        <td>{upadtedBy ? upadtedBy.charAt(0).toUpperCase() + upadtedBy.slice(1) : "-"}</td>
                        {roleAccess.permissions.find((o) => o.slug === "update") || roleAccess.permissions.find((o) => o.slug === "delete") ? (
                          <td className="action">
                            {roleAccess.permissions.find((o) => o.slug === "update") && item.role_name !== "super_admin" ? (
                              <button 
                                className="me-2"
                                onClick={() => navigate(`/edit-admin-role/${item.id}?`+(searchkeyword!==""?"role_name="+searchkeyword+"&":"")+(countValue!==""?"per_page="+countValue+"&":"")+(page!==1?"page="+page:""))}>
                                <img src={edit} alt="f" />
                              </button>
                            ) : (
                              ""
                            )}
                            {roleAccess.permissions.find((o) => o.slug === "delete")  && item.role_name !== "super_admin"? (
                              <button onClick={() => removeType(item.id)}>
                                <img src={waste} alt="f" />
                              </button>
                            ) : (
                              ""
                            )}
                          </td>
                        ) : (
                          ""
                        )}
                      </tr>
                    );
                  })
                ) : (
                  <NoRecord />
                )}
              </tbody>
          }
            </table>
          </div>
            <div>
              {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage} page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
            </div>
      </div>
    </RoleListWrapper>
  );
};

export default AdminRoles;
