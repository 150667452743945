import React, { useEffect, useMemo, useState } from 'react'
import { NavLink, useLocation, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import API from '../../../../../api/axios'
import { APP_URLS } from '../../../../../api/url'
import moment from 'moment'
import * as Constant from "../../../../../config/constant";
import profilePic from "../../../../../assets/Images/icons/profile-pic.png"
import Pagination from '../../../../../assets/SharedComponents/Pagination'
import search from "../../../../../assets/Images/icons/faSearchLite.svg";
import NoRecord from '../../../../../assets/SharedComponents/Table/NoRecord'
import { ThreeDots } from 'react-loader-spinner'
import SearchKey from '../../../../../assets/SharedComponents/Search/SearchKey'
import { FaDownArrow, FaUpArrow, FaFilterIcon } from "../../../../../assets/Images/icon";
import { Autocomplete, Checkbox, TextField, Tooltip } from "@mui/material";
import RecordCount from "../../../../../assets/SharedComponents/RecordCount/RecordCount";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StaticFilter from '../../../../../assets/SharedComponents/Filters/StaticFilter'
import Sorting from '../../../../../assets/SharedComponents/Sorting/Sorting'
import { generateQueryString } from '../../../../../core/utils'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const AccountCreationDatesList =  Object.keys(Constant.AccountCreationDates).map((key)=> ({ name: key  , value : Constant.AccountCreationDates[key] }))  
const verifiedProfileStatusList =  Object.keys(Constant.verifiedProfileStatus).map((key)=> ({ name: key  , value : Constant.verifiedProfileStatus[key] }))  
const ProfileCompletionStatusList =  Object.keys(Constant.ProfileCompletionStatus).map((key)=> ({ name: key  , value : Constant.ProfileCompletionStatus[key] }))  
const verificationStatusList =  Object.keys(Constant.verificationStatus).map((key)=> ({ name: key  , value : Constant.verificationStatus[key] }))  

const Backoffice = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [data,_data] = useState([])

  const [searchkeyword, _searchkeyword] = useState(query.get('keyword')!==null?query.get('keyword'):"");
  const [phoneSearch , _phoneSearch] = useState(''); 
  const [countValue, _countValue] = useState(query.get('per_page')!==null?query.get('per_page'):"");
  const [active, _active] = useState(query.get('status')!==null?query.get('status'):"");
  const [profileStatus, _profileStatus] = useState(query.get('profile_verification_status')!==null?query.get('profile_verification_status'):"");
  const [sort, _sort] = useState(query.get('sort')!==null?query.get('sort'):"first_name");
  const [profile, _profile] = useState(query.get('profile')!==null?query.get('profile'):"");
  const [accountDate, _accountDate] = useState(query.get('accountDate')!==null?query.get('accountDate'):"");
  const [accountDateFrom, _accountDateFrom] = useState(query.get('accountDateFrom')!==null?query.get('accountDateFrom'):"");
  const [accountDateTo, _accountDateTo] = useState(query.get('accountDateTo')!==null?query.get('accountDateTo'):"");
  const [trashed, _trashed] = useState(searchParams.get('trashed')!==null?(searchParams.get('trashed')==='false'?false:true):true);

  const [countryList,_countryList] = useState([])
  const [selectedCountry,_selectedCountry] = useState([])
  const [selectedfilter,_selectedfilter] = useState([])

  const [countries, _countries] = useState(JSON.parse(localStorage.getItem('countries')));

  const [filters, _filters] = useState(false);
  const [loaded, _loaded]= useState(false);

  const [page, setPage] = useState(query.get('page')!==null?parseInt(query.get('page')):1);
  const [totalPage, _totalPage] = useState(1);
  const [total, _total] = useState(0);


  useEffect(() => {
      getbackOfficeUsers() //fetching listing for nomad
  }, [page,searchkeyword,phoneSearch,active,sort,countValue,profileStatus,accountDate,profile,selectedfilter,trashed])

  useEffect(() => {
    if(accountDate === Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM && accountDateFrom !== '' && accountDateTo !== ''){
      getbackOfficeUsers()
    }
}, [accountDate,accountDateFrom,accountDateTo])

  useEffect(()=>{
    let values = []
    countries.forEach(element => {
      values.push({
        title: element.fullname,
        value: element.id
      })
    });
    _countryList(values)
  },[countries])

  useEffect(()=>{
    if(query.get('selectedCountry')!==null&&countryList.length>0){
      let arr = [] 
      let selectedvalues = query.get('selectedCountry').split(',')
      selectedvalues.forEach((element,index)=>{
        if(index!==selectedvalues.length-1){
          arr.push(countryList.find(o=>o.value==element))
        }
      })
      _selectedCountry(arr)
      _selectedfilter(arr)
    }
  },[countryList])

  //Manage parameters related to nomad.
  function getApiParams(str) {
    let urlParams = {
        type: Constant.USER_TYPE.BACK_OFFICE
    };

    if (searchkeyword) urlParams['keyword'] = searchkeyword;
    if (countValue) urlParams['per_page'] = countValue;
    if (sort) urlParams['sort'] = sort;
    if (page > 1) urlParams['page'] = page;
    if (phoneSearch) urlParams['filter[phone]'] = phoneSearch;
    if (active) urlParams['filter[active]'] = active;
    if (profileStatus) urlParams['filter[nomad_verification_status]'] = profileStatus;
    if (profile) urlParams['filter[user_profile]'] = profile;
    if (accountDate && accountDate !== Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM) {
        urlParams['filter[created_at]'] = accountDate;
    }
    if (accountDate === Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM && accountDateFrom && accountDateTo) {
        urlParams['filter[created_at]'] = accountDate;
        urlParams['create_account_from_date'] = accountDateFrom;
        urlParams['create_account_to_date'] = accountDateTo;
    }
    if (str) urlParams['filter[country_id]'] = str;
    if (trashed) urlParams['with_trashed'] = trashed ? 1 : 0;

    return new URLSearchParams(urlParams).toString();
}

  //fetching listing for nomad
  const getbackOfficeUsers = () =>{
    let str = ''
    if(selectedfilter.length>0){
      selectedfilter.forEach(element=>{
        str += element.value+','
      })
    }
    const queryParams = getApiParams(str);
    _loaded(true)
    API.get(`${APP_URLS.LIST_USERS}?${queryParams}`)
    .then(res => {
      const resp = res.data;
      if(resp.success===true){
        _data(resp.data.data)
        _totalPage(resp.data.last_page)
        _total(resp.data.total);
        _loaded(false);
      }
      else{
        toast.error(resp.message, {
          position: toast.POSITION.TOP_RIGHT
        })
        _loaded(false);
      }
    })
    .catch(function (error) { 
      _loaded(false);
      const resp = error.response;
      let error_message = '';
      if (resp.data.errors !== undefined) {
        {
          Object.keys(resp.data.errors).map(
            (error, index) => (error_message = resp.data.errors[error][0])
          );
        }
      } else if (resp.data.data?.error !== undefined) {
          error_message =  resp.data.data.error;
      } else if(resp.data?.error!== undefined){
          error_message =  resp.data.error
      } else{
          error_message =  resp.data.message
      }
      toast.error(error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
    });
  }
  //managing search onChange
  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if((func===_countValue&&searchValue!==countValue)||(func===_searchkeyword&&searchValue!==searchkeyword)){
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  // resetting filters
  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _active("");
    _profileStatus("");
    _profile('')
    _accountDate('')
    _accountDateFrom('')
    _accountDateTo('')
    _selectedCountry([])
    _selectedfilter([])
    _trashed(true);
    _sort('first_name')
    if (searchkeyword !== "" || sort!=='first_name'||countValue !== '' || active !== ""||profileStatus !== ""||profile!==''||accountDate!==''||accountDateFrom!==''||accountDateTo!==''||selectedfilter.length>0||trashed!==true) {
      _totalPage(0)
      setPage(1);
    }
    for(var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
     }
  };

  const setcountryString =() =>{
    let str = ''
    if(selectedfilter.length>0){
      selectedfilter.forEach(element=>{
        str += element.value+','
      })
    }
    return str
  }

  const handleAccountDate = (e) => {
    setPage(1);
    _totalPage(0)
    _accountDate(e.target.value);
    _accountDateFrom('')
    _accountDateTo('')
  }

  const setsorting = (val) =>{
    _sort(val);
    if (sort !== val) {
      setPage(1);
      _totalPage(0)
    }
  }

  //managing params url for detail member page
  function createParamsObj() {
    let params = {
        tab: Constant.USER_TYPE_TEXT.BACK_OFFICE
    };

    if (active !== "") params['status'] = active;
    if (profileStatus !== "") params['profile_verification_status'] = profileStatus;
    if (searchkeyword !== "") params['keyword'] = searchkeyword;
    if (countValue !== "") params['per_page'] = countValue;
    if (profile !== "") params['profile'] = profile;
    if (accountDate !== "") params['accountDate'] = accountDate;
    if (accountDateFrom !== "") params['accountDateFrom'] = accountDateFrom;
    if (accountDateTo !== "") params['accountDateTo'] = accountDateTo;
    if (selectedCountry.length > 0) params['selectedCountry'] = setcountryString();
    if (sort !== "first_name") params['sort'] = sort;
    if (trashed !== true) params['trashed'] = trashed;
    if (page !== 1) params['page'] = page;

    return params;
}
    
  //Manage parameters and url for detail member.
  function getDetailMemberUrl(id) {
    const paramsObj = createParamsObj();
    const queryString = generateQueryString(paramsObj);
    return `/detail-member/${id}?${queryString}`;
  };

  return (
    <>
      <div className="d-flex justify-content-end mt-2">
        <div className="d-flex gap-2 mb-2 select-info justify-content-end">
         <SearchKey
            onSearchClick={(search) => handleSearchChange(_phoneSearch,search)}
            id="searchData"
            val = {phoneSearch}
            filters={filters}
            _filters={_filters}
            placeholder="Search by Phone no."
          />
        <StaticFilter placeholder='Account Creation Date' data={AccountCreationDatesList}  handleChange={handleAccountDate} value={accountDate}/>  
          {accountDate===Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM?
          <>
            <input type='date' name='accountDateFrom' className='filterBox' value={accountDateFrom} onChange={(e)=>_accountDateFrom(e.target.value)}/>
            <input type='date' name='accountDateTo' className='filterBox' value={accountDateTo} onChange={(e)=>_accountDateTo(e.target.value)}/>
          </>:""}
        {countryList.length>0&&
          <Autocomplete
              multiple
              id="tags-standard"
              className='filterBox'
              options={countryList}
              disableCloseOnSelect
              disableClearable
              value={selectedCountry}
              freeSolo
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => {
                _selectedCountry(newValue)
              }}
              onBlur={()=>{
                setPage(1);
                _totalPage(0)
                _selectedfilter(selectedCountry)
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField {...params} placeholder={selectedCountry.length>0?`${selectedCountry.length} ${selectedCountry.length===1?'Country':'Countries'} Selected`:"Select Countries"} />
              )}
            />
          }
          <StaticFilter placeholder='Profile' data={ProfileCompletionStatusList}  handleChange={(e) => {
            setPage(1);
            _totalPage(0)
            _profile(e.target.value);
            }} value={profile}/>  
          <StaticFilter placeholder='Status' data={verifiedProfileStatusList}  handleChange={(e) => {
            setPage(1);
            _totalPage(0)
            _active(e.target.value);
            }} value={active}/>
          <StaticFilter placeholder='Verification Status' data={verificationStatusList}  handleChange={(e) => {
            setPage(1);
            _totalPage(0)
            _profileStatus(e.target.value);
            }} value={profileStatus}/>    
          <SearchKey 
          onSearchClick={(search) => handleSearchChange(_searchkeyword,search)} 
          id="searchData"
          filters={filters}
          val = {searchkeyword}
          _filters={_filters}
          placeholder={`Search by Name, ${Constant.userTypeName.DEALER} Name and Email`}
          />
          <RecordCount
            onSearchClick={(search) => handleSearchChange(_countValue, search)}
            id="pageCount"
            val = {countValue}
            filters={filters}
            _filters={_filters} />
          <div className='d-flex align-items-center'>
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between info-wrap">
          <div className="justify-content-end mt-1 me-2">
              <span className='text-start'>Total Records : <b>{total}</b></span>
          </div>
          <div className="mt-1">
            <input type="checkbox" checked={!trashed} onChange={()=>{
              _totalPage(0)
              setPage(1);
              _trashed(!trashed)
              }}/>
            <span className="ms-2">Hide Deleted Data</span>
          </div>
      </div>

      <div className="tableContainer">
        <table className="auctionTable">
            <thead className="tableHead">
                <tr className="table-row">
                <th className="w200 time-start d-flex flex-column ">
                  <span>{`${Constant.userTypeName.DEALER} Name`}</span>
                  <div className='d-flex flex-row justify-content-center'>
                    <span>Private Name</span>
                    <Sorting sortType='first_name' sort={sort} handleSort={setsorting} />    
                  </div>
                  <span>Stripe ID</span>
                  <span>Phone no.</span>
                </th>
                <th>
                    <span className='fieldTitle'>(Sales)</span>
                    <span className='d-block'>Number of Subscription</span>
                </th>
                <th className="time-start d-flex flex-column align-items-center">
                  <div className="d-flex">
                    <span>Start Time</span>
                    <Sorting sortType='created_at' sort={sort} handleSort={setsorting} />    
                  </div>
                  <div className="d-flex">
                    <span>End Time</span>
                    <Sorting sortType='deleted_at' sort={sort} handleSort={setsorting} />    
                  </div>
                </th>
                <th>Waiting Payment</th>
                <th>Nomad Status</th>
                <th>Number of Prospects</th>
                <th>Number of Prospects Add</th>
              </tr>
            </thead>
            {loaded ? 
              <div className='d-flex justify-content-center three-dot'>              
              <ThreeDots 
                height="150" 
                width="150" 
                radius="9"
                color="#637df4" 
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
            :
            <tbody>
            {data.length > 0 ? (
              data.map((item, index) => {
                let name = "";
                if (item.first_name || item.last_name) {
                  name = `${item.first_name !== null ? item.first_name : ""} ${item.last_name !== null ? item.last_name : ""}`;
                } else {
                  name = item.pseudo;
                }
                const detailMemberUrl = getDetailMemberUrl(item.id)
                return (
                  <tr  className={
                    (item.deleted_at ? "deletedRow" : "") + " table-row"
                  }
                  key={item.id}>
                    <td className="w240 d-flex align-items-center flex-column">
                      <div className="d-flex align-items-center ms-4">
                        <div className="profile-flag">
                          <div className="profileImage">
                            <img
                              src={
                                item.profile_picture_url
                                  ? item.profile_picture_url
                                  : profilePic
                              }
                            />
                          </div>
                          {item.limited_address!==null ? <img className="countryflag" src={item.limited_address.flag_thumbnail} title={item.limited_address.country}/> : ""}
                        </div> 
                          <div className="userDetails d-flex flex-column align-items-start">
                            <NavLink
                              to={detailMemberUrl}
                              className="backOfficelink"
                            >
                              <span
                                className="name-text"
                                title={`Address : ${
                                  item.nomad_data
                                    ? item.nomad_data.address
                                    : "-"
                                } \n VAT : ${
                                  item.nomad_data ? item.nomad_data.vat : "-"
                                }`}
                              >
                                {item.nomad_data !== null
                                  ? item.nomad_data.name
                                      .replace("_", " ")
                                      .charAt(0)
                                      .toUpperCase() +
                                    item.nomad_data.name
                                      .replace("_", " ")
                                      .slice(1)
                                  : "-"}
                              </span>
                              <span
                                className="name-text d-block"
                                title={`Address : ${
                                  item.address_principal &&
                                  item.address_principal.formatted_address
                                    ? item.address_principal.formatted_address
                                    : "-"
                                } \n Email : ${item.email} \n Phone : ${
                                  item.phone
                                }`}
                              >
                                {name !== null
                                  ? name
                                      .replace("_", " ")
                                      .charAt(0)
                                      .toUpperCase() +
                                    name.replace("_", " ").slice(1)
                                  : "-"}
                              </span>
                            </NavLink>
                            {item.stripe_connect ? (
                              <span
                                className={
                                  (item.stripe_connect &&
                                  item.stripe_connect.deleted_at
                                    ? "end-status"
                                    : "active-status") + " stripeID "
                                }
                                title={item.stripe_connect.stripe_user_id}
                              >
                                {item.stripe_connect.stripe_user_id}
                              </span>
                            ) : (
                              <span className="stripeID">-</span>
                            )}
                            <span>{item?.phone ||'-'}</span>
                          </div> 
                      </div>
                    </td>
                    <td>
                      {item.nomad_sales_count ? item.nomad_sales_count : "-"}
                    </td>
                    <td>
                      <span className="d-block">
                        {moment(item.created_at)
                          .tz("Europe/Amsterdam")
                          .format("DD-MM-YYYY HH:mm")}
                      </span>
                      <span className="d-block">
                        {item.deleted_at !== null
                          ? moment(item.deleted_at)
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YYYY HH:mm")
                          : "-"}
                      </span>
                    </td>
                    <td>-</td>
                    <td>
                      <span
                        className={
                          item.nomad === 2 ? "active-status" : "progress-status"
                        }
                      >
                        {Constant.NOMAD_USER_VERIFICATION_STATUS[item.nomad]}
                      </span>
                    </td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                );
              })
            ) : (
              <NoRecord />
            )}
          </tbody>
          }
        </table>
      </div>
      <div>
        {totalPage > 1 ? <Pagination totalPages={totalPage} key={totalPage}  page={page} onPageClick={(page) => setPage(page + 1)} /> : ""}
      </div>
    </>
  )
  
}

export default Backoffice