import styled from "styled-components";

export const CreditHistoryWrapper = styled.div`
.text-decor {
  text-decoration:none;
}
.time-start {
  min-width: 125px;
}
.time-start svg {
  width: 10px;
  height: 15px;
  cursor: pointer;
}
.extract-report{
font-weight: 600;
cursor: pointer;
text-transform: none;
color: black; 
border: 1px solid rgba(0,0,0,.2);
height: 32px;
&:hover{
    outline:none;
}
}
`;

export const CreditHistoryDetailsWrapper = styled.div`
.headerContainer{
    justify-content:center;
  }
  .heading h2 {
    text-align: center;
    font-weight: 600;
    font-size: 40px;
    line-height: 20px;
    padding-bottom: 10px;
  }
  .heading h5 {
    text-align: center;
    color: #979797;
    font-size: 17px;
    line-height: 20px;
    font-weight: 600;
  }
  h5 img{
    margin-left:10px;
    cursor:pointer;
  }
  .cancel-btn{
    align-self: center;
    background-color: #fff;
    border: none;
    height: 35px;
    border-radius: 5px;
    padding: 0 20px;
    color: #022;
    font-weight: bold;
    border:1px solid lightgrey;
  }
    .btn{
        align-self: center;
        background-color: #5d78ff;
        border: none;
        height: 35px;
        border-radius: 5px;
        padding: 0 20px;
        color: white;
        font-weight: bold;
    }
    .hr-line {
        width: 100%;
        border-top: 1px solid ##93939a;
        margin-top: 10px;
        margin-bottom: 5px;
      }
      .details-name {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:space-between;
        margin-top: 20px;
        max-width: 90%;
      }
      .details-name h5 {
        text-align: left;
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        width: 195px;
        color: #979797;
      }
      .details-name h6 {
        text-align: left;
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
        // width: 75px;
      }
      .neg-credit{
        background-color: #E6E6E6;
      }
      @media(max-width: 767px){
        .details-name {
          max-width: 100%;
        }
      }
.offline{
  background:red;
}
.online{
  background:green;
}
  .profile-flag{
    position:relative;
    display:inline-block;
  }
  .countryflag{
    position: relative;
    left: -30px;
    bottom: -30px;
  }
  .profileImage {
    height: 40px;
    width: 40px;
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    margin-top:10px;
  }
  .profileImage img {
    margin: 0 auto;
    display: flex;
    width: 38px;
    height: 38px;
    object-fit: cover;
  }
  .flagIcon{
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: -12px;
    right: 6px;
  }
  .table-color{
    tr {
    background-color: #ffffff !important;
  }
  .neg-credit{
      background-color: #E6E6E6 !important;
    }
  }
  .locationSvg{
    margin-top:3px;
  }
`;