import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NavLink, useLocation, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import API from '../../../../../api/axios'
import { APP_URLS } from '../../../../../api/url'
import Pagination from '../../../../../assets/SharedComponents/Pagination'
import NoRecord from '../../../../../assets/SharedComponents/Table/NoRecord'
import * as Constant from "../../../../../config/constant";
import profilePic from "../../../../../assets/Images/icons/profile-pic.png"
import moment from 'moment'
import SearchKey from '../../../../../assets/SharedComponents/Search/SearchKey'
import { FaDownArrow, FaUpArrow, FaFilterIcon } from "../../../../../assets/Images/icon";
import { Autocomplete, Checkbox, TextField, Tooltip } from '@mui/material'
import { ThreeDots } from 'react-loader-spinner'
import RecordCount from "../../../../../assets/SharedComponents/RecordCount/RecordCount";

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StaticFilter from '../../../../../assets/SharedComponents/Filters/StaticFilter'
import Sorting from '../../../../../assets/SharedComponents/Sorting/Sorting'
import UserFilter from '../../../../../assets/SharedComponents/Filters/UserFilter'
import { generateQueryString } from '../../../../../core/utils'

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const AccountCreationDatesList = Object.keys(Constant.AccountCreationDates).map((key) => ({ name: key, value: Constant.AccountCreationDates[key] }))
const verifiedProfileStatusList = Object.keys(Constant.verifiedProfileStatus).map((key) => ({ name: key, value: Constant.verifiedProfileStatus[key] }))
const ProfileCompletionStatusList = Object.keys(Constant.ProfileCompletionStatus).map((key) => ({ name: key, value: Constant.ProfileCompletionStatus[key] }))
const verificationStatusList = Object.keys(Constant.verificationStatus).map((key) => ({ name: key, value: Constant.verificationStatus[key] }))

const ForumTab = () => {

  const search = useLocation().search;
  const query = new URLSearchParams(search);

  const [searchParams, setSearchParams] = useSearchParams();

  const [data, _data] = useState([])

  const [searchkeyword, _searchkeyword] = useState(query.get('keyword') !== null ? query.get('keyword') : "");
  const [phoneSearch, _phoneSearch] = useState('');
  const [countValue, _countValue] = useState(query.get('per_page') !== null ? query.get('per_page') : "");
  const [active, _active] = useState(query.get('status') !== null ? query.get('status') : "");
  const [profileStatus, _profileStatus] = useState(query.get('profile_verification_status') !== null ? query.get('profile_verification_status') : "");
  const [sort, _sort] = useState(query.get('sort') !== null ? query.get('sort') : "first_name");
  const [profile, _profile] = useState(query.get('profile') !== null ? query.get('profile') : "");
  const [accountDate, _accountDate] = useState(query.get('accountDate') !== null ? query.get('accountDate') : "");
  const [accountDateFrom, _accountDateFrom] = useState(query.get('accountDateFrom') !== null ? query.get('accountDateFrom') : "");
  const [accountDateTo, _accountDateTo] = useState(query.get('accountDateTo') !== null ? query.get('accountDateTo') : "");
  const [trashed, _trashed] = useState(searchParams.get('trashed') !== null ? (searchParams.get('trashed') === 'false' ? false : true) : true);
  const [userID, _userID] = useState('');
  const [countryList, _countryList] = useState([])
  const [selectedCountry, _selectedCountry] = useState([])
  const [selectedfilter, _selectedfilter] = useState([])

  const [filters, _filters] = useState(false);
  const [loaded, _loaded] = useState(false);

  const [countries, _countries] = useState(() => JSON.parse(localStorage.getItem('countries')) || []);

  const [page, setPage] = useState(query.get('page') !== null ? parseInt(query.get('page')) : 1);
  const [total, _total] = useState(0);
  const [totalPage, _totalPage] = useState(0);


  useEffect(() => {
    getForumUsers() //fetching forum users
  }, [page, searchkeyword, phoneSearch, active, sort, countValue, profileStatus, profile, accountDate, selectedfilter, trashed, userID])

  useEffect(() => {
    if (accountDate === Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM && accountDateFrom !== '' && accountDateTo !== '') {
      getForumUsers()
    }
  }, [accountDate, accountDateFrom, accountDateTo])

  useEffect(() => {
    let values = []
    countries.forEach(element => {
      values.push({
        title: element.fullname,
        value: element.id
      })
    });
    _countryList(values)
  }, [countries])

  useEffect(() => {
    if (query.get('selectedCountry') !== null && countryList.length > 0) {
      let arr = []
      let selectedvalues = query.get('selectedCountry').split(',')
      selectedvalues.forEach((element, index) => {
        if (index !== selectedvalues.length - 1) {
          arr.push(countryList.find(o => o.value == element))
        }
      })
      _selectedCountry(arr)
      _selectedfilter(arr)
    }
  }, [countryList])

  //managing params url for forum api
  function getApiParams(str) {
    let urlParams = {
      type: Constant.USER_TYPE.FORUM
    };

    if (searchkeyword) urlParams['keyword'] = searchkeyword;
    if (countValue) urlParams['per_page'] = countValue;
    if (sort) urlParams['sort'] = sort;
    if (page > 1) urlParams['page'] = page;
    if (phoneSearch) urlParams['filter[phone]'] = phoneSearch;
    if (active) urlParams['filter[active]'] = active;
    if (profileStatus) urlParams['filter[profile_verification_status]'] = profileStatus;
    if (profile) urlParams['filter[user_profile]'] = profile;
    if (accountDate && accountDate !== Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM) {
      urlParams['filter[created_at]'] = accountDate;
    }
    if (accountDate === Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM && accountDateFrom && accountDateTo) {
      urlParams['filter[created_at]'] = accountDate;
      urlParams['create_account_from_date'] = accountDateFrom;
      urlParams['create_account_to_date'] = accountDateTo;
    }
    if (str) urlParams['filter[country_id]'] = str;
    if (trashed) urlParams['with_trashed'] = trashed ? 1 : 0;
    if (userID) urlParams['filter[user_id]'] = userID;

    return new URLSearchParams(urlParams).toString();
  }

  //fetching forum users
  const getForumUsers = () => {
    let str = ''
    if (selectedfilter.length > 0) {
      selectedfilter.forEach(element => {
        str += element.value + ','
      })
    }
    const apiUrl = getApiParams(str)
    _loaded(true)
    API.get(`${APP_URLS.LIST_USERS}?${apiUrl}`)
      .then((res) => {
        const resp = res.data;
        if (resp.success === true) {
          _data(resp.data.data);
          _totalPage(resp.data.last_page);
          _total(resp.data.total);
          _loaded(false);
        } else {
          toast.error(resp.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          _loaded(false);
        }
      })
      .catch(function (error) {
        _loaded(false);
        const resp = error.response;
        let error_message = "";
        if (resp.data.errors !== undefined) {
          {
            Object.keys(resp.data.errors).map(
              (error, index) => (error_message = resp.data.errors[error][0])
            );
          }
        } else if (resp.data.data?.error !== undefined) {
          error_message = resp.data.data.error;
        } else if (resp.data?.error !== undefined) {
          error_message = resp.data.error;
        } else {
          error_message = resp.data.message;
        }
        toast.error(error_message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  //managing onChnage for search
  const handleSearchChange = (func, searchValue) => {
    func(searchValue);
    if ((func === _countValue && searchValue !== countValue) || (func === _searchkeyword && searchValue !== searchkeyword)) {
      if (searchValue !== "") {
        _totalPage(0)
        setPage(1);
      }
    }
  };

  //reset filters
  const clearFilter = () => {
    _filters(true)
    handleSearchChange(_countValue, '');
    handleSearchChange(_searchkeyword, '');
    _active("");
    _profileStatus("");
    _profile('')
    _userID('')
    _accountDate('')
    _accountDateFrom('')
    _accountDateTo('')
    _selectedCountry([])
    _selectedfilter([])
    _trashed(true);
    _sort('first_name')
    if (searchkeyword !== "" || countValue !== '' || active !== "" || profileStatus !== "" || profile !== '' || accountDate !== '' || accountDateFrom !== '' || accountDateTo !== '' || selectedfilter.length > 0 || trashed !== true || sort !== "first_name") {
      _totalPage(0)
      setPage(1);
    }
    for (var value of query.keys()) {
      const param = searchParams.get(value);
      if (param) {
        searchParams.delete(value);
        setSearchParams(searchParams);
      }
    }
  };

  const setcountryString = () => {
    let str = ''
    if (selectedfilter.length > 0) {
      selectedfilter.forEach(element => {
        str += element.value + ','
      })
    }
    return str
  }

  const setSort = (val) => {
    _sort(val)
    if (sort !== val) {
      setPage(1);
      _totalPage(0)
    }
  }

  const handleAccountDate = (e) => {
    setPage(1);
    _totalPage(0)
    _accountDate(e.target.value);
    _accountDateFrom('')
    _accountDateTo('')
  }

  //updating userID state using setUserID(_userID)
  const setValue = (setUserID, id) => {
    setUserID(id);
    _totalPage(0)
    setPage(1);
  }

  //managing params url for detail member page
  const createParamsObj = () => {
    return {
      tab: Constant.USER_TYPE_TEXT.FORUM,
      status: active,
      profile_verification_status: profileStatus,
      keyword: searchkeyword,
      per_page: countValue,
      sort: sort,
      profile: profile,
      accountDate: accountDate,
      accountDateFrom: accountDateFrom,
      accountDateTo: accountDateTo,
      selectedCountry: selectedCountry.length > 0 && setcountryString(),
      trashed: trashed !== true ? trashed : undefined,
      page: page !== 1 ? page : undefined
    };
  };

  //Manage parameters and url for detail member.
  function getDetailMemberUrl(itemId) {
    const paramsObj = createParamsObj();
    const queryString = generateQueryString(paramsObj);
    return `/detail-member/${itemId}?${queryString}`;
  };

  return (
    <>
      <div className="d-flex justify-content-end mt-2">
        <div className="d-flex gap-2 mb-2 select-info justify-content-end">
          <UserFilter _filters={_filters} filters={filters} setUser={(val) => setValue(_userID, val)} user={userID} searchByPseudoName={true} />
          <SearchKey
            onSearchClick={(search) => handleSearchChange(_phoneSearch, search)}
            id="searchData"
            val={phoneSearch}
            filters={filters}
            _filters={_filters}
            placeholder="Search by Phone no."
          />
          <StaticFilter placeholder='Account Creation Date' data={AccountCreationDatesList} handleChange={handleAccountDate} value={accountDate} />
          {accountDate === Constant.AccountCreationDatesType.CUSTOM_TO_AND_FROM ?
            <>
              <input type='date' name='accountDateFrom' className='filterBox' value={accountDateFrom} onChange={(e) => _accountDateFrom(e.target.value)} />
              <input type='date' name='accountDateTo' className='filterBox' value={accountDateTo} onChange={(e) => _accountDateTo(e.target.value)} />
            </> : ""}
          {countryList.length > 0 &&
            <Autocomplete
              multiple
              id="tags-standard"
              className='filterBox'
              options={countryList}
              disableCloseOnSelect
              disableClearable
              value={selectedCountry}
              freeSolo
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => {
                _selectedCountry(newValue)
              }}
              onBlur={() => {
                setPage(1);
                _totalPage(0)
                _selectedfilter(selectedCountry)
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.title}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => (
                <TextField {...params} placeholder={selectedCountry.length > 0 ? `${selectedCountry.length} ${selectedCountry.length === 1 ? 'Country' : 'Countries'} Selected` : "Select Countries"} />
              )}
            />
          }
          <StaticFilter placeholder='Profile' data={ProfileCompletionStatusList} handleChange={(e) => {
            setPage(1);
            _totalPage(0)
            _profile(e.target.value);
          }} value={profile} />
          <StaticFilter placeholder='Status' data={verifiedProfileStatusList} handleChange={(e) => {
            setPage(1);
            _totalPage(0)
            _active(e.target.value);
          }} value={active} />
          <StaticFilter placeholder='Verification Status' data={verificationStatusList} handleChange={(e) => {
            setPage(1);
            _totalPage(0)
            _profileStatus(e.target.value);
          }} value={profileStatus} />
          <SearchKey
            onSearchClick={(search) => handleSearchChange(_searchkeyword, search)}
            id="searchData"
            val={searchkeyword}
            filters={filters}
            _filters={_filters}
            placeholder="Search by Name and Email"
          />
          <RecordCount
            onSearchClick={(search) => handleSearchChange(_countValue, search)}
            id="pageCount"
            val={countValue}
            filters={filters}
            _filters={_filters} />
          <div className='d-flex align-items-center'>
            <Tooltip title="Clear Filter" placement="bottom">
              <FaFilterIcon className="filter-icon" role="button" onClick={clearFilter} />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between info-wrap">
        <div className="justify-content-end mt-1 me-2">
          <span className='text-start'>Total Records : <b>{total}</b></span>
        </div>
        <div className="mt-1">
          <input type="checkbox" checked={!trashed} onChange={() => {
            _totalPage(0)
            setPage(1);
            _trashed(!trashed)
          }} />
          <span className="ms-2">Hide Deleted Data</span>
        </div>
      </div>
      <div className="tableContainer">
        <table className="auctionTable">
          <thead className="tableHead">
            <tr className="table-row">
              <th className="d-flex flex-column align-items-center">
                <div className="d-flex align-items-center">
                  <span>Name</span>
                  <Sorting sortType='first_name' sort={sort} handleSort={setSort} />
                </div>
                <div className="d-flex align-items-center">
                  <span>Nickname</span>
                </div>
              </th>
              <th className="d-flex">
                <div className="d-flex flex-column text-start">
                  Email
                  <br /> Phone no.
                </div>
              </th>
              <th className="d-flex flex-column align-items-center">
                <div className="d-flex align-items-center">
                  <span>Start Time</span>
                  <Sorting sortType='created_at' sort={sort} handleSort={setSort} />
                </div>
                <div className="d-flex align-items-center">
                  <span>End Time</span>
                  <Sorting sortType='total_ads' sort={sort} handleSort={setSort} />
                </div>
              </th>
              <th>
                Total question asked<br />
                Deleted questions
              </th>
              <th className=''>Total answers<br /> Deleted answers</th>

              <th className="d-flex flex-column align-items-center">
                <div className="d-flex align-items-center">
                  <span>Total questions flagged <br />Deleted flaggs</span>
                  {/* <Sorting sortType='ads_active' sort={sort} handleSort={setSort} /> */}
                </div>
              </th>
            </tr>
          </thead>
          {loaded ?
            <div className='d-flex justify-content-center three-dot'>
              <ThreeDots
                height="150"
                width="150"
                radius="9"
                color="#637df4"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
              />
            </div>
            :
            <tbody>
              {data?.length > 0 ? (
                data.map((forumUser) => {
                  let name = "";
                  if (forumUser.first_name || forumUser.last_name) {
                    name = `${forumUser.first_name !== null ? forumUser.first_name : ""} ${forumUser.last_name !== null ? forumUser.last_name : ""}`;
                  } else {
                    name = forumUser.pseudo;
                  }
                  const detailMemberUrl = getDetailMemberUrl(forumUser.id)
                  return (
                    <tr className={
                      (forumUser.deleted_at ? "deletedRow" : "") + " table-row"
                    }
                      key={forumUser.id}>
                      <td className="d-flex align-items-center">
                        <div className="profile-flag">
                          <div className="profileImage">
                            <img
                              src={
                                forumUser.profile_picture_url
                                  ? forumUser.profile_picture_url
                                  : profilePic
                              }
                            />
                          </div>
                          {forumUser.limited_address !== null ? <img className="countryflag" src={forumUser.limited_address.flag_thumbnail} title={forumUser.limited_address.country} /> : ""}
                        </div>
                        <div className="userDetails">
                          <NavLink
                            to={detailMemberUrl}
                            className="backOfficelink"
                          >
                            <span
                              className="name-text"
                              title={
                                name !== null
                                  ? name
                                    .replace("_", " ")
                                    .charAt(0)
                                    .toUpperCase() +
                                  name.replace("_", " ").slice(1)
                                  : "-"
                              }
                            >
                              {name !== null
                                ? name
                                  .replace("_", " ")
                                  .charAt(0)
                                  .toUpperCase() +
                                name.replace("_", " ").slice(1)
                                : "-"}
                            </span>
                          </NavLink>
                          <span
                            className="name-text"
                            title={forumUser.pseudo || ""}
                          >
                            {forumUser.pseudo || "-"}
                          </span>

                        </div>
                      </td>
                      <td> <span
                        className="name-text"
                        title={forumUser?.email || ""}
                      >
                        {forumUser?.email || "-"}
                      </span>
                        <span
                          className="name-text"
                          title={forumUser.phone || ""}
                        >
                          {forumUser.phone || "-"}
                        </span>
                      </td>
                      <td>
                        <span className="d-block">
                          {moment(forumUser.created_at)
                            .tz("Europe/Amsterdam")
                            .format("DD-MM-YYYY HH:mm")}
                        </span>
                        <span className="d-block">
                          {forumUser.deleted_at !== null
                            ? moment(forumUser.deleted_at)
                              .tz("Europe/Amsterdam")
                              .format("DD-MM-YYYY HH:mm")
                            : "-"}
                        </span>
                      </td>
                      <td className="">{forumUser.question_count.count}<br />{forumUser.question_deleted_count.count}</td>
                      <td className="d-flex flex-column align-items-center">{forumUser.answer_count.count}<br />{forumUser.answer_deleted_count.count}</td>
                      <td className="d-flex flex-column align-items-center">{forumUser.flag_count.count}<br />{forumUser.flag_deleted_count.count}</td>
                    </tr>
                  );
                })
              ) : (
                <NoRecord />
              )}
            </tbody>
          }
        </table>
      </div>
      <div>
        {totalPage > 1 ? (
          <Pagination
            totalPages={totalPage}
            onPageClick={(page) => {
              setPage(page + 1);
            }}
            page={page}
            key={totalPage}
          />
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ForumTab